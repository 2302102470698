<lib-form-field-container
    [control]="formControl"
    [isLoading]="isLoading"
    [isReadOnly]="isReadOnly"
    [isViewOnly]="isViewOnly"
    [isOptional]="isOptional"
    [isDisabled]="isDisabled"
    [prefixIcon]="prefixIcon"
    [suffixIcon]="suffixIcon"
    [kind]="kindOverride"
    [errors]="errors">

    <ng-content label select="[label]"></ng-content>
    <ng-content hintMessage select="[hintMessage]"></ng-content>
    <ng-content prefixText select="[prefixText]"></ng-content>
    <ng-content suffixText select="[suffixText]"></ng-content>
    <ng-content menu select="[menu]"></ng-content>
    <ng-container readOnly>
        <div class="wrapper-content">
            <ng-content select="[readOnly]"></ng-content>
        </div>
        <div class="default-content" *ngIf="isReadOnly">
            <ng-container *ngLet="(formControl.value | method: GetCurrentItem) as currentItem">
                <ng-container *ngIf="currentItem">
                    <ng-container *ngTemplateOutlet="currentItem.content"></ng-container>
                </ng-container>
                <ng-container *ngIf="!currentItem" i18n="@@CommonNotSpecifiedLabel">
                    Not specified
                </ng-container>
            </ng-container>
        </div>
    </ng-container>
        <mat-select *ngIf="initialized && !isReadOnly" matOptionHeight
            panelClass="v3"
            disableOptionCentering
            [formControl]="viewControl"
            [placeholder]="placeholderText"
            (selectionChange)="ChangeEvent($event)"
            [attr.data-sb-qa]="qaTag"
            #selector>
            <mat-select-trigger>
                <div [innerHTML]="selectedItemHTML | trustHtml"></div>
            </mat-select-trigger>
            <mat-option *ngIf="isOptional" [value]="''">
                <div class="mat-select-placeholder" >
                    {{ placeholderText }}
                </div>
            </mat-option>
            <mat-option *ngIf="showCustomAddOption" [disabled]="true">
                <lib-button [kind]="ButtonKind.Link" [size]="ButtonSize.Medium" [prefixIcon]="Icon.Common.Add" (clicked)="AddOptionClicked(); selector.close()">
                    <ng-container label>
                        <ng-content select="[addButtonText]"></ng-content>
                    </ng-container>
                </lib-button>
            </mat-option>
            <ng-container *ngFor="let groupItems of sortedItemListByGroup | keyvalue: unsorted">
                <ng-container *ngIf="!groupItems.key">
                    <mat-option *ngFor="let item of groupItems.value" [value]="identifierOf(item.value)">
                        <div class="d-flex flex-column" [ngClass]="{'py-1' : item.hintMessage}">
                            <span class="scss-select-item-content" [ngClass]="{'paypal-fontmatch': kindOverride === FormContainerKind.PayPalStyleMatch}"
                                [matSelectTruncate]="select">
                                <ng-container *ngTemplateOutlet="item.content"></ng-container>
                            </span>
                            <lib-hint>
                                <ng-container>{{item.hintMessage}}</ng-container>
                            </lib-hint>
                        </div>
                    </mat-option>
                </ng-container>
                <mat-optgroup *ngIf="groupItems.key" class="select-text" [label]="groupItems.key.name">
                    <mat-option *ngFor="let item of groupItems.value" [value]="identifierOf(item.value)">
                        <div class="d-flex flex-column" [ngClass]="{'py-1' : item.hintMessage}">
                            <span class="scss-select-item-content" [matSelectTruncate]="select">
                                <ng-container *ngTemplateOutlet="item.content"></ng-container>
                            </span>
                            <lib-hint>
                                <ng-container>{{item.hintMessage}}</ng-container>
                            </lib-hint>
                        </div>
                    </mat-option>
                </mat-optgroup>
            </ng-container>
        </mat-select>
</lib-form-field-container>


