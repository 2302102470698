import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseModule } from '@library/base';
import { LayoutModule } from '@library/layout';
import { DialogModule } from '@library/dialog';
import { FormFieldModule } from '@library/form-field';
import { ButtonModule } from '@library/button';
import { ReactiveFormsModule } from '@angular/forms';
import { ApiBaseModule } from '@library/api';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    BaseModule,
    ApiBaseModule,
    LayoutModule,
    DialogModule,
    FormFieldModule,
    ReactiveFormsModule,
    ButtonModule
  ],
  exports: [
  ]
})
export class ElevioModule { }
