<span *ngIf="onLinkTextChange && useSchoolName">
    <h5 class="base-0 p-3 text-wrap user-select-none fade-in-text navigation-text">
        <ng-content></ng-content>
    </h5>
</span>

<div *ngIf="!useSchoolName || !onLinkTextChange" class="d-flex justify-content-start align-items-center sidebar-logo" >
    <span *ngIf="onLinkTextChange && !useSchoolName">
        <img navigation-logo-full class="navigation-logo-full" src="assets/app-logo.svg"/>
    </span>

    <span *ngIf="!onLinkTextChange">
        <img navigation-logo-icon class="navigation-logo-icon" src="assets/app-logo-icon.svg"/>
    </span>
</div>

