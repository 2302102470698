export enum ButtonKind {
    Primary = "btn btn-primary",
    Secondary = "btn btn-outline-secondary",
    Tertiary = "btn btn-tertiary",
    Danger="btn btn-danger",
    Sucess ="btn btn-success",
    Link = "btn btn-link",
    DangerSecondary = "btn btn-outline-danger",
    Circle = "btn btn-circle",
    TertiaryLink = "btn btn-tertiary-link"
}

export enum ButtonSize {
    Small = "btn-sm",
    Medium = "", // Medium is the default button size in bootstrap
    Large = "btn-lg",
    FullWidth = 'btn-full-width'
}

export enum MenuButtonMode {
    Default,
    TextOnly,
    Custom,
    IconOnly
}

export enum MenuDivider {
    Above,
    Below,
    None,  // default
    Both   // not implemented yet
}


