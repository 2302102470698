<div class="v3" >
    <div [ngClass]="{'d-flex flex-row justify-content-between align-items-end': menuButton?.innerHTML,'form-field-menu-button-view-only': isViewOnly}" 
        (click)="containerClicked.emit($event)">
        <lib-label *ngIf="showLabel"
            [isOptional]="isOptional"
            [isReadOnly]="isReadOnly">
            <ng-content select="[label]"></ng-content>
            <ng-content hintMessage select="[hintMessage]"></ng-content>
            <ng-content customAction select="[customAction]"></ng-content>
        </lib-label>
        <div class="d-flex justify-content-end align-items-center" style="max-height: 20px;">
            <div #menuButton class="text-nowrap d-flex justify-content-end align-items-center" style="max-height: 20px;">
                <ng-content select="[menu]"></ng-content>
            </div>
        </div>
    </div>
    
    <div *ngIf="isLoading"
        [ngClass]="{'form-field-loading': kind === FormContainerKind.Default && !isReadOnly,
                    'form-field-loading-small' : kind === FormContainerKind.None || isReadOnly,
                    'form-field-loading-expandable' : kind === FormContainerKind.Expandable && !isReadOnly,
                    'form-field-loading-text-area': (kind === FormContainerKind.TextArea || kind === FormContainerKind.Froala) && !isReadOnly}">
    </div>
    
    <!-- !control.value includes if its 0, which is why all 3 null checks are specified -->
    <lib-read-only *ngIf="!isLoading && isReadOnly"
        [defaultReadOnlyMessage]="(control.value === '' || control.value === undefined || control.value === null) ? notSpecifiedString : control.value"
        [textKind]="(control.value === '' || control.value === undefined || control.value === null) ? TextKind.Default : textKind">
        <ng-content select="[readOnly]"></ng-content>
    </lib-read-only>
    
    <div  matAutocompleteOrigin #origin="matAutocompleteOrigin">
        <div  #containerBox *ngIf="!isLoading && !isReadOnly" tabindex="-1" (focusin)="Focus()" (focusout)="FocusOut()" (click)="fieldClicked.emit($event)"
            [ngClass]="{'form-field': kind === FormContainerKind.Default && !isDisabled && !hasError,
                        'form-field-error': kind === FormContainerKind.Default && !isDisabled && hasError,
                        'form-field-disabled': kind === FormContainerKind.Default && isDisabled && !isViewOnly,
                        'form-field-view-only': kind === FormContainerKind.Default && isDisabled && isViewOnly,
                        'form-field-none-view-only': kind === FormContainerKind.None  && isViewOnly,
                        'form-field-expandable': kind === FormContainerKind.Expandable && !isDisabled && !hasError,
                        'form-field-expandable-error': kind === FormContainerKind.Expandable && !isDisabled && hasError,
                        'form-field-expandable-disabled': kind === FormContainerKind.Expandable && isDisabled && !isViewOnly,
                        'form-field-expandable-view-only': kind === FormContainerKind.Expandable && isDisabled && isViewOnly,
                        'form-field-text-area': kind === FormContainerKind.TextArea && !isDisabled && !hasError,
                        'form-field-text-area-error': kind === FormContainerKind.TextArea && !isDisabled && hasError,
                        'form-field-text-area-disabled': kind === FormContainerKind.TextArea && isDisabled && !isViewOnly,
                        'form-field-text-area-view-only': kind === FormContainerKind.TextArea && isDisabled && isViewOnly,
                        'form-field-froala': kind === FormContainerKind.Froala && !isDisabled && !hasError,
                        'form-field-froala-error': kind === FormContainerKind.Froala && !isDisabled && hasError,
                        'form-field-froala-disabled': kind === FormContainerKind.Froala && isDisabled && !isViewOnly,
                        'form-field-froala-view-only': kind === FormContainerKind.Froala && isDisabled && isViewOnly,
                        'form-field-paypal': kind === FormContainerKind.PayPal && !isDisabled && !hasError,
                        'form-field-paypal-error': kind === FormContainerKind.PayPal && !isDisabled && hasError,
                        'form-field-paypal-disabled': kind === FormContainerKind.PayPal && isDisabled && !isViewOnly,
                        'form-field-paypal-view-only': kind === FormContainerKind.PayPal && isDisabled && isViewOnly,
                        'form-field-paypal-stylematch': kind === FormContainerKind.PayPalStyleMatch && !isDisabled && !hasError,
                        'form-field-paypal-stylematch-error': kind === FormContainerKind.PayPalStyleMatch && !isDisabled && hasError,
                        'form-field-paypal-stylematch-disabled': kind === FormContainerKind.PayPalStyleMatch && isDisabled && !isViewOnly,
                        'form-field-paypal-stylematch-view-only': kind === FormContainerKind.PayPalStyleMatch && isDisabled && isViewOnly}">
        
            <ng-container *ngIf="kind === FormContainerKind.Default">
                <lib-icon *ngIf="prefixIcon"
                    [icon]="prefixIcon"
                    [size]="IconSize.ExtraSmall">
                </lib-icon>
                <caption class="text-nowrap" #content [hidden]="!content.hasChildNodes()">
                    <ng-content select="[prefixText]"></ng-content>
                </caption>
            </ng-container>
        
            <ng-content></ng-content>
        
            <ng-container *ngIf="kind === FormContainerKind.Default">
                <caption class="text-nowrap" #content [hidden]="!content.hasChildNodes()">
                    <ng-content select="[suffixText]"></ng-content>
                </caption>
                <lib-icon *ngIf="suffixIcon"
                    [ngClass]="{'cursor-pointer': suffixIconClickable}"
                    [icon]="suffixIcon"
                    [size]="IconSize.ExtraSmall"
                    (click)="suffixIconClicked.emit()">
                </lib-icon>
            </ng-container>
        </div>
    </div>

    <div *ngIf="isDisabled && kind === FormContainerKind.PayPal" class="form-field-paypal-stylematch-disabled">
        ••••••••••••••
    </div>
    
    <lib-error *ngIf="hasError"
        [control]="control"
        [errors]="errors">
    </lib-error>

    <ng-content bottomMenu select="[bottomMenu]"></ng-content>
    

</div>


