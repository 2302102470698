import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiBaseRoutes } from '@library/api';
import { BaseViewComponent, DialogEvents, Environment, FormBase, Optional, PaymentContext, PaymentSourceMode, SimpleDialogData, boolean } from '@library/base';
import { ErrorCode, PaymentProcessorDisplayItem, PaymentProcessorType, PaymentSourceInputItem, PaymentSourceIntent, PaymentSourceRegistrationNextAction, PaymentSourceType, PaymentSurchargeDisplayItem, ProcessPaymentSourceResultItem, StripePaymentSourceInputItem, SurchargeType } from '@library/data-models';
import { DialogService } from '@library/dialog';
import { Stripe, StripeElements, StripeError, loadStripe } from '@stripe/stripe-js';

class StripeAddPaymentSourceForm extends FormBase {
    AutoPay = Optional(boolean);
    SavePaymentSource = Optional(boolean);
}

@Component({
    selector: 'lib-payment-sources-add-stripe',
    templateUrl: './payment-sources-add-stripe.component.html',
    styleUrls: ['./payment-sources-add-stripe.component.scss']
})
export class PaymentSourcesAddStripeComponent extends BaseViewComponent implements OnInit {

    @Input() mode: PaymentSourceMode = PaymentSourceMode.Add;
    @Input() context!: PaymentContext;
    @Input() paymentSurchargeCreditCard!: PaymentSurchargeDisplayItem;
    @Input() paymentSurchargeACH!: PaymentSurchargeDisplayItem;
    @Input() schoolName!: string;
    @Input() latePaymentFee: number | null = null;
    @Input() schoolID: string | null = null;
    @Input() parentID!: string;
    @Input() familyID!: string;
    @Input() paymentProcessor!: PaymentProcessorDisplayItem;
    
    @Input() set stripeNextStepClientSecret(clientSecret: string | null) {
        if (clientSecret !== null) {
            this._stripe?.handleNextAction({clientSecret: clientSecret}).then(value => {
                if(value.error){
                    this.GetStripeError(value.error)
                } else if (value.paymentIntent) {
                    this.stripeNextStepComplete.emit(value.paymentIntent.id);
                } else if (value.setupIntent) {
                    this.stripeNextStepComplete.emit(value.setupIntent.id);
                } else {
                    throw Error('Stripe did not return an expected result');
                }
            });
        }
    }

    private _forceAutoPay!: boolean;
    @Input()
    set forceAutoPay(value: boolean) {
        this._forceAutoPay = value;
        if(value) {
            const autoPayForStripe = this.addStripePaymentMethodForm.controls.AutoPay;
            autoPayForStripe.setValue(true);
            autoPayForStripe.disable();
        }
    }

    private _currencyCode!: string;
    @Input()
    set currencyCode(value: string) {
        this._currencyCode = value;
    }

    private _totalAmountToPay: number | null = null;
    @Input()
    set totalAmountToPay(value: number | null) {
        this._totalAmountToPay = value;
        this.UpdateStripeOptionsAmount();
    }

    private _onlineConvenienceFee: number | null = null;
    @Input()
    set onlineConvenienceFee(value: number | null) {
        this._onlineConvenienceFee = value;
        this.UpdateStripeOptionsAmount();
    }

    @Input() set paymentProcessorErrorMessage(error: string | null) {
        if (error !== null) {
            this.ShowErrorDialog(error);
        }
    }

    @Output() paymentSourceProcessed: EventEmitter<ProcessPaymentSourceResultItem | null> = new EventEmitter();
    @Output() paymentSourceCreated: EventEmitter<string> = new EventEmitter();
    @Output() paymentSourceTypeChanged: EventEmitter<PaymentSourceType> = new EventEmitter();
    @Output() signupFormPaymentSourceReady: EventEmitter<PaymentSourceInputItem | null> = new EventEmitter();
    @Output() stripeReady: EventEmitter<void> = new EventEmitter();
    @Output() stripeNextStepComplete: EventEmitter<string> = new EventEmitter();

    readonly addStripePaymentMethodForm = StripeAddPaymentSourceForm.Create(false);

    private _paymentSourceType: PaymentSourceType = PaymentSourceType.CreditCard;
    private _loadingStripe: boolean = true; 
    private _savePaymentMethodForFuture: boolean = false;
    private _stripeElements!: StripeElements;
    private _stripe: Stripe | null = null;
    private _stripeOptions!: any;

    constructor(private _Environment: Environment,
        private _DialogService: DialogService) { 
        super();
    }

    override ngOnInit(): void {
        super.ngOnInit();

        if (this.paymentProcessor.Type != PaymentProcessorType.Stripe) {
            throw Error('Wrong payment processor type for payment-sources-add-stripe.component.ts')
        }

        if (!this._stripe) {
            loadStripe(this._Environment!.Payment!.Stripe!.PublicKey!, {
                stripeAccount: this.paymentProcessor.AccountID!
            }).then(stripe => {
                if (!stripe) {
                throw Error('The payment processor could not be loaded');
                }
                this._stripe = stripe;
                this.RenderStripeElements();
                this.SetDefaultStateForSavePaymentMethodForFuture();         
            });
        } else {
            this.RenderStripeElements();
            this.SetDefaultStateForSavePaymentMethodForFuture();         
        }
    }

    SavePaymentMethodForFuture(savePaymentMethod: boolean): void {
        this._savePaymentMethodForFuture = savePaymentMethod;
        if(!savePaymentMethod && !this._forceAutoPay) {
            this.addStripePaymentMethodForm.controls.AutoPay.setValue(false);
        }
        this._stripeOptions.setupFutureUsage = savePaymentMethod ? 'off_session' : null;
        this._stripeElements.update(this._stripeOptions);
    }

    VaultStripe() {
        this._stripeElements!.submit().then(value => {
            if(value.error){
                this.GetStripeError(value.error);
            } else {
                this._stripe!.createPaymentMethod({
                    elements: this._stripeElements
                }).then(result => {
                    if(result.error){
                        this.GetStripeError(result.error)
                    } else {
                        const inputItem = new StripePaymentSourceInputItem({
                            ParentID: this.parentID,
                            FamilyID: this.familyID,
                            PaymentProcessorID: this.paymentProcessor.ID,
                            AutoPay: this.addStripePaymentMethodForm.controls.AutoPay.value,
                            OrderIndex: 0,
                            PaymentMethodID: result.paymentMethod.id!,
                        });
                        switch(this.mode){
                            case PaymentSourceMode.Add:
                                inputItem.Intent = PaymentSourceIntent.Vault;
                                break;
                            case PaymentSourceMode.Payment:
                            case PaymentSourceMode.AnonymousPayment:
                                inputItem.Intent = (this.addStripePaymentMethodForm.controls.SavePaymentSource.value) ? PaymentSourceIntent.PayAndVault : PaymentSourceIntent.Pay;       
                                inputItem.Surcharge = this._onlineConvenienceFee ?? 0;
                                inputItem.Total = this._totalAmountToPay;
                                break;
                        }
                        if(inputItem.Intent === PaymentSourceIntent.Pay){
                            inputItem.AutoPay = null;
                            inputItem.OrderIndex = null;
                        }
                        this.ConfirmStripeIntent(inputItem);
                    }
                });
            }});
    }

    private SetDefaultStateForSavePaymentMethodForFuture(): void {
        this._savePaymentMethodForFuture = this.mode !== PaymentSourceMode.AnonymousPayment ? true : false;
        this.addStripePaymentMethodForm.controls.SavePaymentSource.setValue(this._savePaymentMethodForFuture);
        this.SavePaymentMethodForFuture(this._savePaymentMethodForFuture);
    }

    private TotalAmountForStripe() {
        const totalBeforeMultiplier = this._totalAmountToPay ? this._totalAmountToPay : this.paymentProcessor.MinimumCharge * 10; // Add a fudge factor to allow for Stripe currency conversion
        return Math.round(totalBeforeMultiplier * this.paymentProcessor.CurrencyMultiplier);
    }

    private UpdateStripeOptionsAmount(): void {
        if (this._stripeOptions && this.mode !== PaymentSourceMode.Add) {
            this._stripeOptions.amount = this.TotalAmountForStripe();
            this._stripeElements.update(this._stripeOptions);
        }
    }

    private RenderStripeElements() {
        this._stripeOptions = {
            mode: (this.mode === PaymentSourceMode.Add) ? 'setup' : 'payment',
            currency: this._currencyCode.toLowerCase(),
            setupFutureUsage: 'off_session',
            paymentMethodCreation: 'manual',
            paymentMethodConfiguration: (this.context === PaymentContext.TeacherPortal) ? this._Environment!.Payment!.Stripe!.TeacherPaymentMethodConfigurationKey : this._Environment!.Payment!.Stripe!.DefaultPaymentMethodConfigurationKey,

        } as any;
    
        if (this.mode !== PaymentSourceMode.Add || this.context === PaymentContext.SignupWidget) {
            this._stripeOptions.paymentMethodOptions = { us_bank_account: { verification_method: 'instant' }};
        }

        if (this.mode !== PaymentSourceMode.Add) {
            this._stripeOptions.amount = this.TotalAmountForStripe();
        }

        this._stripeElements = this._stripe!.elements(this._stripeOptions);
        const setupElement = this._stripeElements.create('payment');

        setupElement.on('ready', _ =>{
            this._loadingStripe = false;
            this.stripeReady.emit();
        });

        setupElement.on('change', result => {
            switch(result.value.type){
                case 'us_bank_account':
                case 'bacs_debit':
                    this._paymentSourceType = PaymentSourceType.BankAccount;
                    break;
                case 'link':
                    this._paymentSourceType = PaymentSourceType.StripeLink;
                    break;                
                default:
                    this._paymentSourceType = PaymentSourceType.CreditCard;
            }
            this.paymentSourceTypeChanged.emit(this._paymentSourceType);
        });

        setupElement.on('loaderror', result => {
            if(result.error){
                throw Error('Stripe could not load');
            }
        });
        setupElement.mount('#payment-element');
    }

    private ConfirmStripeIntent(inputItem: StripePaymentSourceInputItem): void {
        if (this.context == PaymentContext.SignupWidget) {
            this.signupFormPaymentSourceReady.emit(inputItem);
        } else {
            ApiBaseRoutes.PaymentSources.Add.Call({
                Parameter: this.mode === PaymentSourceMode.AnonymousPayment ? this.schoolID! : '',
                Body: inputItem
            }).subscribe({
                next: result => {
                    switch (result.NextAction) {
                        case PaymentSourceRegistrationNextAction.None:
                            this.paymentSourceProcessed.emit(result);
                            break;
                        case PaymentSourceRegistrationNextAction.UseStripeSdk:
                            this._stripe?.handleNextAction({clientSecret: result.StripeClientSecret!}).then(value => {
                                if(value.error){
                                    this.GetStripeError(value.error)
                                } else if (value.paymentIntent) {
                                    inputItem.IntentID = value.paymentIntent.id;
                                } else if (value.setupIntent) {
                                    inputItem.IntentID = value.setupIntent.id;
                                }
                                if(!value.error){
                                    this.ConfirmStripeIntent(inputItem);
                                }
                            })
                            break;
                    }
                },
                error: (error: typeof ApiBaseRoutes.PaymentSources.Add.Error) => {
                    if (error.Response.NewErrorCode === ErrorCode.OnlinePaymentError) {
                        this.ShowErrorDialog(error.Response.ErrorMessage!);
                    } else {
                        throw error;
                    }
                }
            });
        }
    }

   private ShowErrorDialog(errorMessage: string) {
        this._DialogService.CreateInfoDialog(new SimpleDialogData({
            Title: $localize`:@@CommonAddPaymentSourcePaymentProcessorError:Payment Processor Error`,
            Text: errorMessage
        }), false).events.subscribe(event => {
            if(event === DialogEvents.PrimaryAction) {
                this.addStripePaymentMethodForm.enable();
                if (this.context == PaymentContext.SignupWidget) {
                    this.signupFormPaymentSourceReady.emit(null);
                } else {
                    this.paymentSourceProcessed.emit(null);
                }
            }
        }); 
    }

    private GetStripeError(result: StripeError): void {
        switch(result.type){
            case 'api_connection_error':
            case 'api_error':
            case 'authentication_error':
            case 'card_error':
            case 'idempotency_error':
            case 'invalid_request_error':
            case 'rate_limit_error':
                this._DialogService.CreateInfoDialog(new SimpleDialogData({
                    Title: 'Stripe Processing Error',
                    Text: result.message,
                }), false).events.subscribe(event => {
                    if (event === DialogEvents.PrimaryAction) {
                        this.addStripePaymentMethodForm.enable();
                        this.paymentSourceProcessed.emit(null);
                    }
                });
                break;
            case 'validation_error':
                this.addStripePaymentMethodForm.enable();
                this.paymentSourceProcessed.emit(null);
                break;
        }
    }
 
    get loadingStripe(): boolean {
        return this._loadingStripe;
    }

    get paymentSourceType(): PaymentSourceType {
        return this._paymentSourceType;
    }

    get PaymentSourceType(): typeof PaymentSourceType {
        return PaymentSourceType;
    }

    get SurchargeType(): typeof SurchargeType {
        return SurchargeType;
    }

    get PaymentSourceMode(): typeof PaymentSourceMode {
        return PaymentSourceMode;
    }

    get PaymentContext(): typeof PaymentContext {
        return PaymentContext;
    }
}
