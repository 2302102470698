import { Type } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { DialogBaseViewComponent } from "../../views/dialog-base-view.component";
import { Enum, FieldOf } from "../base.definitions";
import { AbstractControlExtraFields, FormGroupExtraFields } from "./form-extension.definitions";

export enum FormContainerKind {
    Default,
    None, 
    Expandable,
    TextArea,
    PayPal,
    PayPalStyleMatch,
    Froala,
    Checkbox,
    Radio,
}

export enum TextKind {
    Default,
    CodeBlock
}

export enum Orientation {
    Horizontal,
    Vertical
}

export enum RangeLabelValue {
    Start = 'start',
    End = 'end'
}

export enum FormContainerSize {
    Small,
    Medium,
    Large
}

export type FormType<T> = FormGroup<{ [K in keyof T] : T[K] extends FormProperty<infer U> ? FormControl<U>
                                              : T[K] extends (FormProperty<infer U>)[] ? FormArray<FormControl<U>>
                                              : T[K] extends {} ? FormType<T[K]>
                                              : never }>;

export class FormBase {
    private static _currentForm: FormGroup<any> | null = null;
    private static _ignoreCreatedForms: boolean = false;

    static Create<T extends FormBase, U extends DialogBaseViewComponent>(this: Type<T>, setCurrentForm?: boolean, saveMethod?: () => void, unsavedChangesDialog?: Type<U>) {
        const form = FormBase.ConvertGroup(new this());

        // Below is ideal for later. For now, forms must explicitly opt in to form protection
        // setCurrentForm, if passed in, overrides ignoreCreatedForms flag
        //if (setCurrentForm || (setCurrentForm === undefined && !FormBase._ignoreCreatedForms)) {
        if (setCurrentForm) {
            FormBase._currentForm = form;
            const formWithExtraFields = FormBase._currentForm as FormGroupExtraFields;

            if (saveMethod) {
                formWithExtraFields.saveMethod = saveMethod;
                formWithExtraFields.saveSubject = new Subject();
            }

            formWithExtraFields.unsavedChangesDialog = unsavedChangesDialog;
        }

        form.SetDefaultValueToCurrent();

        return form;
    }

    private static ConvertGroup<T extends object>(group: T): FormType<T> {
        const object = {} as any;

        (Object.keys(group) as (FieldOf<T>)[]).forEach(key => {
            let value = group[key];

            if (value instanceof Array) {
                object[key] = this.ConvertArray(value, key);
            } else if (value instanceof FormProperty) {
                object[key] = this.ConvertItem(value);
            } else if (value && typeof value == 'object') {
                object[key] = this.ConvertGroup(value);
            } else {
                throw Error(`Incorrect form definition for field ${key}`);
            }
        });

        return new FormGroup(object);
    }

    private static ConvertArray<T>(array: FormProperty<T>[], key: string): FormArray<FormControl<T>> {
        if (array.length != 1) {
            throw Error(`Incorrect form array definition for field ${key} - form arrays are created empty, `
                      + 'but must be defined using a single prototype item (e.g. [Optional(boolean)])');
        }

        const formArray = new FormArray<FormControl<T>>([]) as FormArray<FormControl<T>> & AbstractControlExtraFields;
        formArray._isRequired = array[0].Required;
        formArray._defaultValue = array[0].Default;

        return formArray;
    }

    private static ConvertItem<T>(item: FormProperty<T>): FormControl<T> {
        let formControl: FormControl<T>;

        if (item.Required) {
            const validator = typeof item.Default === 'boolean' ? Validators.requiredTrue : Validators.required;
            formControl = new FormControl<T>(item.Default, {nonNullable: true, validators: validator});
            formControl.isRequired = true;
        } else {
            formControl = new FormControl<T>(item.Default, {nonNullable: true});
            formControl.isRequired = false;
        }

        return formControl;
    }

    static get currentForm(): FormGroup<any> | null {
        return FormBase._currentForm;
    }

    static set currentForm(value: FormGroup<any> | null) {
        FormBase._currentForm = value;
    }

    static set ignoreCreatedForms(value: boolean) {
        FormBase._ignoreCreatedForms = value;
    }
}

export class FormProperty<T> {
    Default!: T;
    Required!: boolean

    constructor(initializer: FormProperty<T>) {
        Object.assign(this, initializer);
    }
}

function FormItem<T>(x: Type<T>, y: Type<T>): FormProperty<[T, T] | null>;   // Generalize? For now, only used for date range
function FormItem(x: 'boolean'): FormProperty<boolean>;
function FormItem(x: 'number'): FormProperty<number | null>;
function FormItem(x: 'string'): FormProperty<string>;
function FormItem(x: ['boolean']): FormProperty<boolean[]>;
function FormItem(x: ['number']): FormProperty<number[]>;
function FormItem(x: ['string']): FormProperty<string[]>;
function FormItem<T>(x: Type<T>): FormProperty<T | null>;
function FormItem<T>(x: Enum<T>): FormProperty<T[keyof T]>;
function FormItem<T>(x: [Type<T>]): FormProperty<T[]>;
function FormItem<T>(x: [Enum<T>]): FormProperty<T[keyof T][]>;
function FormItem(this: { required: boolean }, x: any, y?: any): FormProperty<any> {
    if (y || typeof x === 'function' || x === 'number') {
        return new FormProperty<any>({ Default: null, Required: this.required });
    } else if (Array.isArray(x)) {
        return new FormProperty<any[]>({ Default: [], Required: this.required });
    } else if (typeof x === 'object') {
        return new FormProperty<any>({Default: Object.values(x)[0], Required: this.required });
    } else if (x === 'boolean') {
        return new FormProperty<boolean>({ Default: false, Required: this.required });
    } else if (x === 'string') {
        return new FormProperty<string>({ Default: '', Required: this.required });
    }

    throw Error('Unknown form definition');
}

export const Optional = FormItem.bind({ required: false });
export const Required = FormItem.bind({ required: true });