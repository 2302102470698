import { Pipe, PipeTransform } from "@angular/core";
import { Icon, IconItem } from "@library/base";
import { PaymentSourceDisplayItem, PaymentSourceType } from "@library/data-models";

@Pipe({name: 'paymentSourceIcon'})
export class PaymentSourceIconPipe implements PipeTransform {

    transform(paymentSource: PaymentSourceDisplayItem): { Icon: IconItem } {
        if (paymentSource.Type === PaymentSourceType.BankAccount) {
            return { Icon: Icon.Payment.Savings };
        } else if (paymentSource.Type === PaymentSourceType.CreditCard) {
            return { Icon: Icon.Payment.CreditCard };
        } else if (paymentSource.Type === PaymentSourceType.StripeLink) {           
            return { Icon: Icon.Payment.CCStripe };
        } else if (paymentSource.Type === PaymentSourceType.PayPal) {
            return { Icon: Icon.Payment.PayPal };
        } else if (paymentSource.Type === PaymentSourceType.Venmo) {
            return { Icon: Icon.Payment.Venmo };
        }

        throw Error("Unknown payment source type.");
    }
}
