<lib-form-field-container
    [control]="formControl"
    [isLoading]="isLoading"
    [isReadOnly]="isReadOnly"
    [isViewOnly]="isViewOnly"
    [isOptional]="isOptional"
    [isDisabled]="isDisabled"
    [prefixIcon]="prefixIcon"
    [suffixIcon]="suffixIcon"
    [errors]="errors">

    <ng-content label select="[label]"></ng-content>
    <ng-content hintMessage select="[hintMessage]"></ng-content>
    <ng-content prefixText select="[prefixText]"></ng-content>
    <ng-content suffixText select="[suffixText]"></ng-content>
    <ng-container readOnly>
        <div #providedReadOnly class="wrapper-content">
            <ng-content select="[readOnly]"></ng-content>
        </div>
        <div class="default-content">
            <ng-container>{{ formControl.value ? (formControl.value | formatCurrency) : notSpecifiedString }}</ng-container>
        </div>
    </ng-container>

    <ng-container>
        <p class="typography-body-medium" *ngIf="!currencySymbolRightPlacement">{{ currencySymbol }}</p>
        <input matInput
            [formControl]="viewControl"
            [placeholder]="placeholderText"
            autocomplete="nope"
            inputmode="decimal"
            (blur)="OnBlur()"
            (focus)="OnFocus()"
            (change)="$event.stopPropagation()"
            [attr.data-sb-qa]="qaTag" />
        <p class="typography-body-medium" *ngIf="currencySymbolRightPlacement">{{ currencySymbol }}</p>
    </ng-container>
</lib-form-field-container>
