<div cdkScrollable class="d-flex flex-column" [ngClass]="{'website-preview': mode !== WebsiteMode.Live, 'website-live': mode === WebsiteMode.Live, 'scroll-y': !isMobileOrTablet, 'scroll-y-hidden': isMobileOrTablet }"> <!--css classes for cursor pointer -->
        <ng-container *ngIf="!isMobileOrTablet">
            <div class="scss-website-page-header px-4 py-3 d-grid">
                <!-- Desktop -->
                <ng-container>
                    <ng-container *ngIf="websiteData.Template === WebsiteTemplate.Standard || websiteData.Template === WebsiteTemplate.Bold">
                        <div class="d-flex align-items-center justify-content-between">
                            <ng-container *ngTemplateOutlet="websiteTitleLogo"></ng-container>
                            <div class="d-flex" [ngClass]="{'gap-5': socialLinksInHeader && (websiteData | method: HasSocialLinks)}">
                                <div class="d-flex align-items-center flex-wrap scss-custom-header-gap justify-content-start ps-5"><ng-container *ngTemplateOutlet="desktopMenu"></ng-container></div>
                                <ng-container *ngIf="socialLinksInHeader"><ng-container *ngTemplateOutlet="socialMediaIcons"></ng-container></ng-container>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="websiteData.Template === WebsiteTemplate.Minimalistic">
                        <div class="scss-custom-grid-equal-width">
                            <div class="d-flex align-items-center flex-wrap scss-custom-header-gap"><ng-container *ngTemplateOutlet="desktopMenu"></ng-container></div>
                            <div class="scss-header-title-logo-justify-self">
                            <ng-container *ngTemplateOutlet="websiteTitleLogo"></ng-container>
                            </div>
                            <div *ngIf="socialLinksInHeader" class="scss-custom-navigation-grid-item"><ng-container *ngTemplateOutlet="socialMediaIcons"></ng-container></div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
            <ng-container *ngTemplateOutlet="mainContent"></ng-container>
            <ng-container *ngTemplateOutlet="footerMenu"></ng-container>
        </ng-container>

        <ng-container *ngIf="isMobileOrTablet">
            <lib-website-navigation-sidebar [websiteData]="websiteData" [ID]="ID" [mode]="mode" (navigationPageClicked)="NavigationPageClicked($event)">
                <ng-container *ngTemplateOutlet="websiteTitleLogo" websiteTitleLogoSelect></ng-container>
                <ng-container *ngTemplateOutlet="mainContent" mainContentSelect></ng-container>
                <ng-container *ngTemplateOutlet="socialMediaIcons" socialMediaSelect></ng-container>
                <ng-container *ngTemplateOutlet="footerMenu" footerMenuSelect></ng-container>
            </lib-website-navigation-sidebar>
        </ng-container>

<ng-template #socialMediaIcons>
    <div class="d-flex align-items-center gap-2" [ngClass]="{'justify-content-center': isMobile, 'justify-content-end': !isMobile}" >
        <lib-icon *ngIf="websiteData.FacebookUrl" class="cursor-pointer"
            [icon]="Icon.Brand.FacebookCircle"
            [size]="IconSize.Medium"
            (click)="GoToSocialMediaSite(websiteData.FacebookUrl)">
        </lib-icon>
        <lib-icon *ngIf="websiteData.InstagramUrl" class="cursor-pointer"
            [icon]="Icon.Brand.InstagramOutlined"
            [size]="IconSize.Medium"
            (click)="GoToSocialMediaSite(websiteData.InstagramUrl)">
        </lib-icon>
        <lib-icon *ngIf="websiteData.LinkedInUrl" class="cursor-pointer"
            [icon]="Icon.Brand.Linkedin"
            [size]="IconSize.Medium"
            (click)="GoToSocialMediaSite(websiteData.LinkedInUrl)">
        </lib-icon>
        <lib-icon *ngIf="websiteData.TikTokUrl" class="cursor-pointer"
            [icon]="Icon.Brand.TikTok"
            [size]="IconSize.Medium"
            (click)="GoToSocialMediaSite(websiteData.TikTokUrl)">
        </lib-icon>
        <lib-icon *ngIf="websiteData.TwitterUrl" class="cursor-pointer"
            [icon]="Icon.Brand.XCorp"
            [size]="IconSize.Medium"
            (click)="GoToSocialMediaSite(websiteData.TwitterUrl)">
        </lib-icon>
        <lib-icon *ngIf="websiteData.YouTubeUrl" class="cursor-pointer"
            [icon]="Icon.Brand.Youtube"
            [size]="IconSize.Medium"
            (click)="GoToSocialMediaSite(websiteData.YouTubeUrl)">
        </lib-icon>
    </div>
</ng-template>

<ng-template #desktopMenu>
    <ng-container *ngFor="let page of websiteData.WebsitePages" class="pe-5">
        <ng-container *ngIf="page.InMenu">
            <div class="subNav ">
                <h6 (click)="NavigationPageClicked(page.ID)" class="cursor-pointer scss-nav-text-truncate" [ngClass]="{'text-decoration-underline': (page.ID === ID || (ID | method: IsPageParentOfViewableID: page)) }"><a class="scss-custom-no-link-styling" [href]="page.Slug" onclick="return false;">{{page.Title}}</a></h6>

                <div class="subNavContent rounded-bottom scss-sub-nav-shadow" *ngIf="page.ChildPages.length > 0">
                    <ng-container *ngFor="let childPage of page.ChildPages">

                        <p (click)="NavigationPageClicked(childPage.ID)" class="cursor-pointer scss-sub-nav-text-wrap" [ngClass]="{'text-decoration-underline': childPage.ID === ID}"><a class="scss-custom-no-link-styling" [href]="childPage.Slug" onclick="return false;">{{childPage.Title}}</a></p>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </ng-container>

</ng-template>

<ng-template #websiteTitleLogo>
    <ng-container *ngTemplateOutlet="websiteTitleLogoTemplate; context: {$implicit: true}"></ng-container>   
</ng-template>


<ng-template #mainContent>
    <div class="flex-grow-1" cdkScrollable  [@listAnimation]="animate" (@listAnimation.done)="AnimationEnd()" >
        <div class="example-list h-100" cdkDropList
            id="pages"
            #boundary
            #pages="cdkDropList"
            [cdkDropListAutoScrollDisabled]="false"
            [cdkDropListAutoScrollStep]="15"
            (cdkDropListDropped)="Dropped($event)">
            <ng-container *ngIf="draw">
                <div class="example-box" *ngFor="let block of blocks; trackBy:TrackByID" cdkDrag  [cdkDragDisabled]="true">
                    <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                    <ng-container [ngSwitch]="block.WebsiteBlockType">
                        <ng-container *ngSwitchCase="WebsiteBlockType.SignupForm">
                            <ng-container *ngLet="(block | method: WebsiteBlockDisplayItem.AsSignupForm) as signupWidgetBlock">
                                <lib-signup-form-design-block
                                    [data]="signupWidgetBlock"
                                    [websiteMode]="mode"
                                    [kind]="signupWidgetBlock.SignupFormType"
                                    [disableMoveUp]="block.OrderIndex === 0"
                                    [disableMoveDown]="block.OrderIndex === blocks.length - 1"
                                    (moveUpClicked)="MoveUpClicked(block)"
                                    (moveDownClicked)="MoveDownClicked(block)"
                                    (deleteClicked)="DeleteClicked(block)"
                                    (editClicked)="EditClicked(block)"
                                    [isViewOnly]="mode !== WebsiteMode.Live">
                                    <ng-container header>
                                        <ng-container *ngTemplateOutlet="textMediaBlockHeader; context: {$implicit: signupWidgetBlock}"></ng-container>
                                    </ng-container>
                                </lib-signup-form-design-block>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="WebsiteBlockType.ContactForm">
                            <ng-container *ngLet="(block | method: WebsiteBlockDisplayItem.AsContactForm) as contactWidgetBlock">
                                <lib-contact-form-design-block
                                    [data]="contactWidgetBlock"
                                    [websiteMode]="mode"
                                    [hasHeader]="contactWidgetBlock.Header !== null"
                                    [hasBody]="contactWidgetBlock.Body !== null"
                                    [kind]="contactWidgetBlock.ContactFormType"
                                    [disableMoveUp]="block.OrderIndex === 0"
                                    [disableMoveDown]="block.OrderIndex === blocks.length - 1"
                                    (moveUpClicked)="MoveUpClicked(block)"
                                    (moveDownClicked)="MoveDownClicked(block)"
                                    (deleteClicked)="DeleteClicked(block)"
                                    (editClicked)="EditClicked(block)"
                                    (imageActionClicked)="HandleImageClicked(block, 0, $event)"
                                    [disableImageDetails]="contactWidgetBlock.Images.length > 0 && !contactWidgetBlock.Images[0].WebsiteMediaLibraryItemID"
                                    [isViewOnly]="mode !== WebsiteMode.Live">
                                    <ng-container header>
                                        <ng-container *ngTemplateOutlet="textMediaBlockHeader; context: {$implicit: contactWidgetBlock}"></ng-container>
                                    </ng-container>
                                    <ng-container body>
                                        <ng-container *ngTemplateOutlet="textMediaBlockBody; context: {$implicit: contactWidgetBlock}"></ng-container>
                                    </ng-container>
                                    <ng-container image>
                                        <ng-container *ngFor="let imageBlock of contactWidgetBlock.Images; trackBy: TrackByImage">
                                            <ng-container *ngTemplateOutlet="textMediaBlockImage; context: {$implicit: imageBlock, blockType: contactWidgetBlock | method: PrepareScalingTypeData }"></ng-container>
                                        </ng-container>
                                    </ng-container>
                                </lib-contact-form-design-block>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="WebsiteBlockType.LoginForm">
                            <ng-container *ngLet="(block | method: WebsiteBlockDisplayItem.AsLoginForm) as loginWidgetBlock">
                                <lib-login-form-design-block
                                    [data]="loginWidgetBlock"
                                    [websiteMode]="mode"
                                    [hasHeader]="loginWidgetBlock.Header !== null"
                                    [hasBody]="loginWidgetBlock.Body !== null"
                                    [kind]="loginWidgetBlock.LoginFormType"
                                    [disableMoveUp]="block.OrderIndex === 0"
                                    [disableMoveDown]="block.OrderIndex === blocks.length - 1"
                                    (moveUpClicked)="MoveUpClicked(block)"
                                    (moveDownClicked)="MoveDownClicked(block)"
                                    (deleteClicked)="DeleteClicked(block)"
                                    (imageActionClicked)="HandleImageClicked(block, 0, $event)"
                                    [disableImageDetails]="loginWidgetBlock.Images.length > 0 && !loginWidgetBlock.Images[0].WebsiteMediaLibraryItemID"
                                    [isViewOnly]="mode !== WebsiteMode.Live">
                                    <ng-container header>
                                        <ng-container *ngTemplateOutlet="textMediaBlockHeader; context: {$implicit: loginWidgetBlock}"></ng-container>
                                    </ng-container>
                                    <ng-container body>
                                        <ng-container *ngTemplateOutlet="textMediaBlockBody; context: {$implicit: loginWidgetBlock}"></ng-container>
                                    </ng-container>
                                    <ng-container image>
                                        <ng-container *ngFor="let imageBlock of loginWidgetBlock.Images; trackBy: TrackByImage">
                                            <ng-container *ngTemplateOutlet="textMediaBlockImage; context: {$implicit: imageBlock, blockType: loginWidgetBlock | method: PrepareScalingTypeData }"></ng-container>
                                        </ng-container>
                                    </ng-container>
                                </lib-login-form-design-block>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="WebsiteBlockType.Blog">
                            <lib-blog-posts-design-block
                                [schoolID]="websiteData.SchoolID!"
                                [websiteMode]="mode"
                                [disableMoveUp]="block.OrderIndex === 0"
                                [disableMoveDown]="block.OrderIndex === blocks.length - 1"
                                (moveUpClicked)="MoveUpClicked(block)"
                                (moveDownClicked)="MoveDownClicked(block)"
                                (deleteClicked)="DeleteClicked(block)"
                                (goToNewsPageClicked)="EditClicked(block)">

                            </lib-blog-posts-design-block>
                        </ng-container>
                        <ng-container *ngSwitchCase="WebsiteBlockType.Embed">
                            <ng-container *ngLet="(block | method: WebsiteBlockDisplayItem.AsEmbed) as embedBlock">
                                <ng-container *ngIf="embedBlock.ContentType == WebsiteBlockEmbedContentType.Map">
                                    <lib-map-design-block
                                        [source]="embedBlock.Source"
                                        [type]="embedBlock.EmbedType"
                                        [isFullWidth]="embedBlock.IsFullWidth"
                                        [websiteMode]="mode"
                                        [disableMoveUp]="block.OrderIndex === 0"
                                        [disableMoveDown]="block.OrderIndex === blocks.length - 1"
                                        (moveUpClicked)="MoveUpClicked(block)"
                                        (moveDownClicked)="MoveDownClicked(block)"
                                        (deleteClicked)="DeleteClicked(block)"
                                        (editClicked)="EditClicked(block)">
                                    </lib-map-design-block>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="WebsiteBlockType.TextAndMedia">
                            <ng-container *ngLet="(block | method: WebsiteBlockDisplayItem.AsTextAndMedia) as textMediaBlock">
                                <ng-container  *ngIf="
                                            textMediaBlock.TextAndMediaType === WebsiteBlockTextAndMediaType.Default ||
                                            textMediaBlock.TextAndMediaType === WebsiteBlockTextAndMediaType.AccentBackgroundDefault ||
                                            textMediaBlock.TextAndMediaType === WebsiteBlockTextAndMediaType.MediaLeft ||
                                            textMediaBlock.TextAndMediaType === WebsiteBlockTextAndMediaType.MediaRight ||
                                            textMediaBlock.TextAndMediaType === WebsiteBlockTextAndMediaType.MediaLeftClipped ||
                                            textMediaBlock.TextAndMediaType === WebsiteBlockTextAndMediaType.MediaRightClipped ||
                                            textMediaBlock.TextAndMediaType === WebsiteBlockTextAndMediaType.TwoColumn ||
                                            textMediaBlock.TextAndMediaType === WebsiteBlockTextAndMediaType.MediaLeftTwoColumn ||
                                            textMediaBlock.TextAndMediaType === WebsiteBlockTextAndMediaType.MediaRightTwoColumn ||
                                            textMediaBlock.TextAndMediaType === WebsiteBlockTextAndMediaType.MediaLeftShadow ||
                                            textMediaBlock.TextAndMediaType === WebsiteBlockTextAndMediaType.MediaRightShadow ||
                                            textMediaBlock.TextAndMediaType === WebsiteBlockTextAndMediaType.Testimonial ||
                                            textMediaBlock.TextAndMediaType === WebsiteBlockTextAndMediaType.AccentBackgroundTestimonial ||
                                            textMediaBlock.TextAndMediaType === WebsiteBlockTextAndMediaType.MediaRightTestimonial||
                                            textMediaBlock.TextAndMediaType === WebsiteBlockTextAndMediaType.MediaRightAccentBackgroundTestimonial">
                                    <lib-text-design-block
                                        [hasHeader]="textMediaBlock.Header !== null"
                                        [hasBody]="textMediaBlock.Body !== null"
                                        [kind]="textMediaBlock.TextAndMediaType"
                                        [websiteMode]="mode"
                                        [disableMoveUp]="block.OrderIndex === 0"
                                        [disableMoveDown]="block.OrderIndex === blocks.length - 1"
                                        (moveUpClicked)="MoveUpClicked(block)"
                                        (moveDownClicked)="MoveDownClicked(block)"
                                        (deleteClicked)="DeleteClicked(block)"
                                        class="scss-display-block-text-and-media"
                                        (imageActionClicked)="HandleImageClicked(block, 0, $event)"
                                        [disableImageDetails]="textMediaBlock.TextAndMediaType === WebsiteBlockTextAndMediaType.MediaLeft || 
                                            textMediaBlock.TextAndMediaType === WebsiteBlockTextAndMediaType.MediaRight || 
                                            textMediaBlock.TextAndMediaType === WebsiteBlockTextAndMediaType.MediaLeftClipped ||
                                            textMediaBlock.TextAndMediaType === WebsiteBlockTextAndMediaType.MediaRightClipped ||
                                            textMediaBlock.TextAndMediaType === WebsiteBlockTextAndMediaType.MediaLeftShadow ||
                                            textMediaBlock.TextAndMediaType === WebsiteBlockTextAndMediaType.MediaRightShadow ||
                                            textMediaBlock.TextAndMediaType === WebsiteBlockTextAndMediaType.Testimonial || 
                                            textMediaBlock.TextAndMediaType === WebsiteBlockTextAndMediaType.AccentBackgroundTestimonial || 
                                            textMediaBlock.TextAndMediaType === WebsiteBlockTextAndMediaType.MediaRightTestimonial||
                                            textMediaBlock.TextAndMediaType === WebsiteBlockTextAndMediaType.MediaRightAccentBackgroundTestimonial ? 
                                            !(block | method: WebsiteBlockDisplayItem.AsTextAndMedia).Images[0].WebsiteMediaLibraryItemID : false">
                                        <ng-container header>
                                            <ng-container *ngTemplateOutlet="textMediaBlockHeader; context: {$implicit: textMediaBlock}"></ng-container>
                                        </ng-container>
                                        <ng-container body>
                                            <ng-container *ngTemplateOutlet="textMediaBlockBody; context: {$implicit: textMediaBlock}"></ng-container>
                                        </ng-container>
                                        <ng-container image>
                                            <ng-container *ngFor="let imageBlock of textMediaBlock.Images; trackBy: TrackByImage">
                                                <ng-container *ngTemplateOutlet="textMediaBlockImage; context: {$implicit: imageBlock, blockType: textMediaBlock | method: PrepareScalingTypeData }"></ng-container>
                                            </ng-container>
                                        </ng-container>
                                    </lib-text-design-block>
                                </ng-container>
                                <ng-container *ngIf="textMediaBlock.TextAndMediaType === WebsiteBlockTextAndMediaType.HTML">
                                    <lib-html-design-block
                                        [websiteMode]="mode"
                                        [disableMoveUp]="block.OrderIndex === 0"
                                        [disableMoveDown]="block.OrderIndex === blocks.length - 1"
                                        (moveUpClicked)="MoveUpClicked(block)"
                                        (moveDownClicked)="MoveDownClicked(block)"
                                        (deleteClicked)="DeleteClicked(block)"
                                        (editClicked)="EditClicked(block)">
                                            <ng-container *ngTemplateOutlet="htmlBlock; context: {$implicit: block}"></ng-container>
                                    </lib-html-design-block>
                                </ng-container>
                                <ng-container
                                        *ngIf="textMediaBlock.TextAndMediaType === WebsiteBlockTextAndMediaType.HeroBackgroundImage ||
                                            textMediaBlock.TextAndMediaType === WebsiteBlockTextAndMediaType.HeroNoImage ||
                                            textMediaBlock.TextAndMediaType === WebsiteBlockTextAndMediaType.HeroSquareSideImage ||
                                            textMediaBlock.TextAndMediaType === WebsiteBlockTextAndMediaType.HeroClippedSideImage ||
                                            textMediaBlock.TextAndMediaType === WebsiteBlockTextAndMediaType.MediaLeftHeroSquareImage ||
                                            textMediaBlock.TextAndMediaType === WebsiteBlockTextAndMediaType.MediaLeftHeroClippedImage">
                                    <div>
                                        <lib-hero-design-block
                                                [websiteMode]="mode"
                                                [type]="textMediaBlock.TextAndMediaType"
                                                [disableMoveUp]="block.OrderIndex === 0"
                                                [disableMoveDown]="block.OrderIndex === blocks.length - 1"
                                                [hasHeader]="textMediaBlock.Header !== null"
                                                [hasBody]="textMediaBlock.Body !== null"
                                                (moveUpClicked)="MoveUpClicked(block)"
                                                (moveDownClicked)="MoveDownClicked(block)"
                                                (deleteClicked)="DeleteClicked(block)"
                                                (editClicked)="EditClicked(block)"
                                                (imageActionClicked)="HandleImageClicked(block, 0, $event)"
                                                [disableImageDetails]="textMediaBlock.TextAndMediaType === WebsiteBlockTextAndMediaType.HeroNoImage ? false : !(block | method: WebsiteBlockDisplayItem.AsTextAndMedia).Images[0].WebsiteMediaLibraryItemID">
                                            <ng-container header>
                                                <ng-container
                                                        *ngTemplateOutlet="textMediaBlockHeader; context: {$implicit: textMediaBlock}"></ng-container>
                                            </ng-container>
                                            <ng-container body>
                                                <ng-container
                                                        *ngTemplateOutlet="textMediaBlockBody; context: {$implicit: textMediaBlock}"></ng-container>
                                            </ng-container>
                                            <ng-container image>
                                                <ng-container *ngFor="let imageBlock of textMediaBlock.Images; trackBy: TrackByImage">
                                                    <ng-container *ngTemplateOutlet="textMediaBlockImage; context: {$implicit: imageBlock, blockType: textMediaBlock | method: PrepareScalingTypeData }"></ng-container>
                                                </ng-container>
                                            </ng-container>
                                        </lib-hero-design-block>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="textMediaBlock.TextAndMediaType === WebsiteBlockTextAndMediaType.GalleryCircleColumn ||
                                                    textMediaBlock.TextAndMediaType === WebsiteBlockTextAndMediaType.GalleryCircleCardColumn ||
                                                    textMediaBlock.TextAndMediaType === WebsiteBlockTextAndMediaType.GalleryCircleAccentColumn ||
                                                    textMediaBlock.TextAndMediaType === WebsiteBlockTextAndMediaType.GallerySquareColumn ||
                                                    textMediaBlock.TextAndMediaType === WebsiteBlockTextAndMediaType.GallerySquareCardColumn ||
                                                    textMediaBlock.TextAndMediaType === WebsiteBlockTextAndMediaType.GallerySquareAccentColumn
                                                    ">
                                    <lib-gallery-column-design-block 
                                            [type]="textMediaBlock.TextAndMediaType"
                                            [websiteMode]="mode"
                                            [disableMoveUp]="block.OrderIndex === 0"
                                            [disableMoveDown]="block.OrderIndex === blocks.length - 1"
                                            [hasHeader]="(block | method: WebsiteBlockDisplayItem.AsTextAndMedia).Header !== null"
                                            (moveUpClicked)="MoveUpClicked(block)"
                                            (moveDownClicked)="MoveDownClicked(block)"
                                            (deleteClicked)="DeleteClicked(block)"
                                            (addItemClicked)="AddItemClicked(block)"
                                            (removeItemClicked)="RemoveItemClicked(block)"
                                            (openMultiSelectMediaGallery)="OpenMultiSelectMediaDialog(block)"
                                            class="scss-display-block-gallery-column"
                                            >
                                        <ng-container header>
                                            <ng-container *ngTemplateOutlet="textMediaBlockHeader; context: {$implicit: textMediaBlock}"></ng-container>
                                        </ng-container>
                                        <lib-gallery-column-design-block-item [mode]="mode" [kind]="textMediaBlock.TextAndMediaType" *ngFor="let imageBlock of textMediaBlock.Images; let i=index; trackBy: TrackByImage"
                                        [ngClass]="{'scss-gallery-column-card':textMediaBlock.TextAndMediaType === WebsiteBlockTextAndMediaType.GalleryCircleCardColumn || textMediaBlock.TextAndMediaType === WebsiteBlockTextAndMediaType.GallerySquareCardColumn}"
                                        [disableRemoveCard]="(block | method: WebsiteBlockDisplayItem.AsTextAndMedia).Images.length <= 1"
                                        [disableImageDetails]="!(block | method: WebsiteBlockDisplayItem.AsTextAndMedia).Images[i].WebsiteMediaLibraryItemID"
                                        (imageActionClicked)="HandleImageClicked(block, i, $event)">
                                            <ng-container image>
                                                <ng-container *ngTemplateOutlet="textMediaBlockImage; context: {$implicit: imageBlock, blockType: textMediaBlock | method: PrepareScalingTypeData }"></ng-container>
                                            </ng-container>
                                            <ng-container header>
                                                <ng-container *ngTemplateOutlet="textMediaBlockImageHeader; context: {$implicit: imageBlock}"></ng-container>
                                            </ng-container>
                                            <ng-container body>
                                                <ng-container *ngTemplateOutlet="textMediaBlockImageBody; context: {$implicit: imageBlock}"></ng-container>
                                            </ng-container>
                                        </lib-gallery-column-design-block-item>
                                    </lib-gallery-column-design-block>
                                </ng-container>
                                <ng-container *ngIf="textMediaBlock.TextAndMediaType === WebsiteBlockTextAndMediaType.PhotoGalleryGrid">
                                    <lib-photo-gallery-design-block
                                            [type]="textMediaBlock.TextAndMediaType"
                                            [websiteMode]="mode"
                                            [disableMoveUp]="block.OrderIndex === 0"
                                            [disableMoveDown]="block.OrderIndex === blocks.length - 1"
                                            (moveUpClicked)="MoveUpClicked(block)"
                                            (moveDownClicked)="MoveDownClicked(block)"
                                            (deleteClicked)="DeleteClicked(block)">
                                            <lib-photo-gallery-design-block-item 
                                                [kind]="textMediaBlock.TextAndMediaType"
                                                [mode]="mode"
                                                *ngFor="let imageBlock of textMediaBlock.Images, let i=index; trackBy: TrackByImage"
                                                (imageActionClicked)="HandleImageClicked(block, i, $event)"
                                                [disableGridImageDetails]="!(block | method: WebsiteBlockDisplayItem.AsTextAndMedia).Images[i].WebsiteMediaLibraryItemID">
                                                <div image class="h-100">
                                                    <ng-container *ngTemplateOutlet="textMediaBlockImage; context: {$implicit: imageBlock, blockType: textMediaBlock | method: PrepareScalingTypeData }"></ng-container>
                                                </div>
                                            </lib-photo-gallery-design-block-item>

                                    </lib-photo-gallery-design-block>
                                </ng-container>

                                <ng-container *ngIf="textMediaBlock.TextAndMediaType === WebsiteBlockTextAndMediaType.PhotoGalleryCarousel ||
                                                textMediaBlock.TextAndMediaType === WebsiteBlockTextAndMediaType.PhotoGalleryCarouselOverlay">
                                    <lib-photo-gallery-design-block
                                            #galleryBlock
                                            [type]="textMediaBlock.TextAndMediaType"
                                            [websiteMode]="mode"
                                            [disableMoveUp]="block.OrderIndex === 0"
                                            [disableMoveDown]="block.OrderIndex === blocks.length - 1"
                                            [disableAddItem]="(block | method: WebsiteBlockDisplayItem.AsTextAndMedia).Images.length >= photoGalleryCarouselMaxItems"
                                            [disableMultiSelectImage]="DisableMultiSelectUpload((block | method: WebsiteBlockDisplayItem.AsTextAndMedia).Images)"
                                            [images]="textMediaBlock.Images"
                                            (moveUpClicked)="MoveUpClicked(block)"
                                            (moveDownClicked)="MoveDownClicked(block)"
                                            (deleteClicked)="DeleteClicked(block)"
                                            (addItemClicked)="AddItemClicked(block, (galleryBlock.middleImageIndex+1))"
                                            (removeItemClicked)="RemoveItemClicked(block, galleryBlock.middleImageIndex)"
                                            (openMultiSelectMediaGallery)="OpenMultiSelectMediaDialog(block)">

                                            <ng-container *ngFor="let imageBlock of textMediaBlock.Images, let index = index; trackBy: TrackByImage">
                                                <lib-photo-gallery-design-block-item
                                                    (imageActionClicked)="HandleImageClicked(block, index, $event)"
                                                    (removePhotoGalleryItemClicked)="galleryBlock.RemoveItemClicked(index)"
                                                    [disableRemoveImage]="(block | method: WebsiteBlockDisplayItem.AsTextAndMedia).Images.length <= 3"
                                                    [isMiddleImageOfView]="index === galleryBlock.middleImageIndex"
                                                    [disableImageDetails]="!(block | method: WebsiteBlockDisplayItem.AsTextAndMedia).Images[index].WebsiteMediaLibraryItemID"
                                                    [ngClass]="{
                                                    'carousel-item-left': index === (galleryBlock.middleImageIndex | method: GetCarouselPrevIndex: textMediaBlock.Images.length),
                                                    'carousel-item-center': index === galleryBlock.middleImageIndex,
                                                    'carousel-item-right': index === (galleryBlock.middleImageIndex | method: GetCarouselNextIndex: textMediaBlock.Images.length),
                                                    'carousel-item-hidden' : ((index !== (galleryBlock.middleImageIndex | method: GetCarouselPrevIndex: textMediaBlock.Images.length))
                                                        && (index !== (galleryBlock.middleImageIndex | method: GetCarouselNextIndex: textMediaBlock.Images.length))
                                                        && index !== galleryBlock.middleImageIndex)}"
                                                    [kind]="textMediaBlock.TextAndMediaType"
                                                    [mode]="mode">
                                                    <div image class="h-100 w-100" [ngClass]="{'opacity-50': textMediaBlock.TextAndMediaType === WebsiteBlockTextAndMediaType.PhotoGalleryCarouselOverlay
                                                    && ((index === (galleryBlock.middleImageIndex | method: GetCarouselPrevIndex: textMediaBlock.Images.length)) || (index === (galleryBlock.middleImageIndex | method: GetCarouselNextIndex: textMediaBlock.Images.length)))}">
                                                        <ng-container *ngTemplateOutlet="textMediaBlockImage; context: {$implicit: imageBlock, blockType: textMediaBlock | method: PrepareScalingTypeData }"></ng-container>
                                                    </div>
                                                </lib-photo-gallery-design-block-item>
                                            </ng-container>
                                    </lib-photo-gallery-design-block>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </div>
            </ng-container>
        </div>

    </div>
</ng-template>

<ng-template #footerMenu>
    <div *ngIf="showFooter" class="scss-website-page-footer d-flex align-items-center justify-content-center flex-column flex-sm-row px-sm-5 py-sm-0">
        <div class="col d-flex"> <ng-container *ngTemplateOutlet="websiteTitleLogoTemplate; context: {$implicit: false}"></ng-container></div>
        <div class="order-sm-2 order-3 col-auto text-center"> <ng-content select="[copyright]"></ng-content></div>
        <div *ngIf="socialLinksInFooter" class="order-sm-3 order-2 pt-sm-0 pt-2 col"> <ng-container *ngTemplateOutlet="socialMediaIcons"></ng-container> </div>
    </div>
</ng-template>

<ng-template #websiteTitleLogoTemplate let-isHeader>
    <div class="cursor-pointer" (click)="GoToHomePage()">
        <h4 *ngIf="!websiteData.HeaderLogoMediaLibraryItemID">{{websiteData.Title}}</h4>
        <img *ngIf="websiteData.HeaderLogoMediaLibraryItemID && headerLogoSource"
            class="data-hj-suppress cursor-pointer"
            [src]="headerLogoSource | trustUrl"
            i18n-alt="@@CommonLogoLabel"
            alt="Logo"
            i18n-title="@@CommonLogoLabel"
            title="Logo"
            [height]="isHeader ? 48 : 68"/>
    </div>
</ng-template>
</div>

<lib-website-svgs></lib-website-svgs>

