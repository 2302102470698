<lib-card *ngIf="hasCardContainer; else content">
    <ng-container *ngTemplateOutlet="content"></ng-container>
</lib-card>


<ng-template #content>
    <div [attr.data-sb-qa]="qaTag">
        <div class="mb-3">
            <ng-content select="[header]"></ng-content>
            <ng-content select="[description]"></ng-content>
        </div>

        <ng-content></ng-content>

        <lib-layout-row [orientation]="orientation">
            <ng-container rowRight>
                <lib-button
                    [kind]="ButtonKind.Secondary"
                    (clicked)="DiscardClicked()"
                    [disabled]="form?.disabled || !form?.isDirty"
                    [qaTag]="'discard-button'">
                    <ng-container label><ng-content select="[secondaryButtonText]"></ng-content></ng-container>
                </lib-button>

                <lib-button
                    [kind]="ButtonKind.Primary"
                    (clicked)="SaveClicked()"
                    [loading]="form?.isLoading || form?.disabled ?? false"
                    [disabled]="form?.disabled || !form?.isDirty"
                    [qaTag]="'save-button'">
                    <ng-container label><ng-content select="[primaryButtonText]"></ng-content></ng-container>
                </lib-button>
            </ng-container>
            <ng-container rowLeft>
                <ng-content select="[buttonRowLeft]"></ng-content>
            </ng-container>
        </lib-layout-row>
    </div>
</ng-template>
