<lib-dialog-layout>
    <ng-container title>
        <h3 i18n="@@CommonTwoFactorAuthenticationTitle">2-Factor Authentication (2FA)</h3>
    </ng-container>

    <ng-container content>
        <!-- <hr> -->
        <p class="typography-title-small" i18n="@@CommonStep1">Step 1</p>

        <p *ngIf="!isMobile" class="typography-body-medium pb-3" i18n="@@CommonMFAQRCodeInstruction ">Scan the QR code in your authenticator app (e.g. Google Authenticator, Authy etc.):</p>
        <div *ngIf="!isMobile" class="d-flex justify-content-center align-items-center">
            <div *ngIf="isLoading" class="scss-multi-factor-auth-qrcode-placeholder form-field-loading"></div>
            <div *ngIf="!isLoading">
                <img [src]="mfaSetup.QrCode" height="200px" width="200px">
            </div>
        </div> 

        <p *ngIf="isMobile" class="typography-body-medium py-3" i18n="@@CommonMFAAddButtonInstructionMobile">Click the button below to add your account to the authenticator app automatically.</p>
        <p *ngIf="isTablet" class="typography-body-medium py-3" i18n="@@CommonMFAAddButtonInstructionTablet">Or click the button below to add your account to the authenticator app automatically.</p>
        <div *ngIf="isMobileOrTablet" class="d-flex justify-content-center align-items-center">
            <lib-button *ngIf="true || isMobileOrTablet"
                [kind]="ButtonKind.Primary"
                [size]="ButtonSize.Medium"
                [disabled]="isLoading"
                [loading]="isLoading"
                [prefixIcon]="Icon.Common.OpenInNew"
                [prefixIconSize]="IconSize.ExtraSmall"
                (clicked)="AddToAuthenticatorApp()">
                <ng-container label i18n="@@CommonMFASetupAddToAuthenticatorButton">Add to Authenticator App</ng-container>
            </lib-button>
        </div> 
        
        <form [formGroup]="mfaCodeForm">
            <p class="typography-body-medium pt-3" i18n="@@CommonMFASecretKeyInstruction">Alternatively, manually enter the Secret Key in your authenticator app:</p>
            <div class="single-column-row pb-3 mt-2">
                <div *ngIf="!showSecretCode">
                    <lib-button
                        [kind]="ButtonKind.Link"
                        [size]="ButtonSize.Medium"
                        [disabled]="mfaCodeForm.disabled"
                        (clicked)="showSecretCode = true;">
                        <ng-container label i18n="@@CommonMFASecretKeyShowSKey">Show Secret Key</ng-container>
                    </lib-button>
                </div>
                <div *ngIf="showSecretCode" class="w-100 py-2 typography-title-small text-center background-base-50 scss-multi-factor-auth-secret-code-background">
                    {{secretCode}}
                </div>
            </div>

            <hr>
            <p class="typography-title-small" i18n="@@CommonStep2">Step 2</p>
            <p class="typography-body-medium pb-3" i18n="@@CommonMFASetupCode1Instruction">Enter the code shown in your authenticator app:</p>
            <div class="three-column-row pb-3">
                <lib-input-text [formControl]="mfaCodeForm.controls.Code1"
                    [maxCharacterLength]="6"
                    [isOptional]="false"
                    inputFilter="[0-9]"
                    placeholderText="Code 1"
                    [errorMessages]="mfaErrors">
                </lib-input-text>
            </div>

            <p class="typography-body-medium pb-3" i18n="@@CommonMFASetupCode2Instruction">Wait until a new code is generated, and enter it below:</p>
            <div class="three-column-row pb-3">
                <lib-input-text [formControl]="mfaCodeForm.controls.Code2"
                    [maxCharacterLength]="6"
                    [isOptional]="false"
                    inputFilter="[0-9]"
                    placeholderText="Code 2"
                    [errorMessages]="mfaErrors">
                </lib-input-text>
            </div>

            <lib-error *ngIf="!enablingMFA"
                [control]="mfaErrorForm.controls.AlreadySetup"
                [errors]="alreadySetupErrors">
            </lib-error>

            <lib-error *ngIf="!enablingMFA"
                [control]="mfaErrorForm.controls.IncorrectCode"
                [errors]="incorrectCodeErrors">
            </lib-error>   
        </form>
    </ng-container>

    <ng-container actions>
        <lib-button
            [kind]="ButtonKind.Secondary"
            [size]="ButtonSize.Medium"
            (clicked)="DismissAction()">
            <ng-container label i18n="@@CommonCancel">Cancel</ng-container>
        </lib-button>
        <lib-button
            [kind]="ButtonKind.Primary"
            [size]="ButtonSize.Medium"
            [loading]="mfaCodeForm.disabled && enablingMFA"
            [disabled]="mfaCodeForm.disabled"
            (clicked)="PrimaryAction()">
            <ng-container label i18n="@@CommonMFASetupVerifyAndContinueButtonText">Verify & Continue</ng-container>
        </lib-button>
    </ng-container>                                                                                             
</lib-dialog-layout>