import { animate, group, query, style, transition, trigger } from '@angular/animations';
import { LocationStrategy } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ApiBaseRoutes } from '@library/api';
import { BaseViewComponent, DialogEvents, PaymentContext, PaymentMethodType, PaymentPageSchoolInfo, PaymentReturnStatus, PaymentSourceMode, SimpleDialogData } from '@library/base';
import { CalculateSurchargeInputItem, ErrorCode, InvoiceStatus, MakePaymentInputItem, PayPalCommercePlatformPaymentSourceInputItem, PayPalProExpressCheckoutCompleteInputItem, PayPalProExpressCheckoutCompletedTransactionDisplayItem, PayPalProExpressCheckoutGetUrlInputItem, PayPalStandardCompleteInputItem, PayPalStandardCompletedTransactionDisplayItem, PayPalStandardGetUrlInputItem, PaymentCompleteDisplayItem, PaymentProcessorDisplayItem, PaymentProcessorType, PaymentSourceDisplayItem, PaymentSourceInputItem, PaymentSourceIntent, PaymentSourceSearchOptions, PaymentSourceType, PaymentSurchargeDisplayItem, ProcessPaymentSourceResultItem, StripePaymentSourceSetupUpdateItem, SurchargeResultItem, SurchargeType, InvoiceDetailsDisplayItem } from '@library/data-models';
import { DialogService } from '@library/dialog';

const left = [
    query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
    group([
        query(':enter', [style({ transform: 'translateX(-100%)' }), animate('.5s ease-out', style({ transform: 'translateX(0%)' }))], {
            optional: true,
        }),
        query(':leave', [style({ transform: 'translateX(0%)' }), animate('.5s ease-out', style({ transform: 'translateX(100%)' }))], {
            optional: true,
        }),
        ]),
    ];

  const right = [
    query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
    group([
        query(':enter', [style({ transform: 'translateX(100%)' }), animate('.5s ease-out', style({ transform: 'translateX(0%)' }))], {
            optional: true,
        }),
        query(':leave', [style({ transform: 'translateX(0%)' }), animate('.5s ease-out', style({ transform: 'translateX(-100%)' }))], {
            optional: true,
        }),
        ]),
    ];

@Component({
    selector: 'lib-payment-page',
    templateUrl: './payment-page.component.html',
    styleUrls: ['./payment-page.component.scss'],
    animations: [
        trigger('slideInOut', [
            transition(':increment', right),
            transition(':decrement', left),
        ]),
    ],
})
export class PaymentPageComponent extends BaseViewComponent implements OnInit { 

    @Input() schoolInfo!: PaymentPageSchoolInfo;
    @Input() parentID!: string;
    @Input() familyID!: string;
    @Input() invoiceDetails: InvoiceDetailsDisplayItem | null = null;
    @Input() minimumAmount: number = 0.50;
    @Input() forceAutoPay!: boolean;
    @Input() context!: PaymentContext;
    @Input() loadingPaymentDetails: boolean = true;
    @Input() currencyCode!: string;
    @Input() allowPartialPayment: boolean = false;
    @Input() hasZeroBalance: boolean = false;
    @Input() paymentProcessor!: PaymentProcessorDisplayItem;
    @Input() paymentSurchargeCreditCard!: PaymentSurchargeDisplayItem;
    @Input() paymentSurchargeACH!: PaymentSurchargeDisplayItem;
    @Input() invoiceStatus: InvoiceStatus = InvoiceStatus.Open;
    @Input() stripeNextStepClientSecret: string | null = null;
    @Input() paymentProcessorErrorMessage: string | null = null;

    private _mode!: PaymentSourceMode;
    @Input()
    set mode(value: PaymentSourceMode) {
        this._mode = value;
        if(this.paymentProcessor && (this.paymentProcessor.Type === PaymentProcessorType.PayPalPro || this.paymentProcessor.Type === PaymentProcessorType.PayPalStandard)){
            this.ApplyOnlineConvenienceFee(PaymentSourceType.CreditCard);
        }
    }

    private _amountToPay: number | null = null;
    @Input()
    set amountToPay(value: number | null) {
        this._amountToPay = value;
        this._surchargeData = [];
        if(this.context === PaymentContext.StudentPortal){
            this.ApplyOnlineConvenienceFee((this.paymentProcessor.Type === PaymentProcessorType.Stripe)
                                           ? (this._newPaymentOpened || !this._hasAtleastOnePaymentSource)
                                               ? this._stripePaymentSourceType
                                               : this._paymentSource.Type
                                           : PaymentSourceType.CreditCard);
        }
    }

    @Input() set signupFormSubmitClicked(value: boolean) {
        if(value){
            this.PayUsingNewPaymentMethod();
        } else {
            this._payNowClickedForNewPaymentMethod = false;
        }
    }

    @Output() doneClicked: EventEmitter<void> = new EventEmitter();
    @Output() paymentSuccessful: EventEmitter<void> = new EventEmitter();
    @Output() fetchPaymentSourcesFailed: EventEmitter<void> = new EventEmitter();
    @Output() signupFormPaymentSourceReady: EventEmitter<PaymentSourceInputItem | null> = new EventEmitter();
    @Output() stripeNextStepComplete: EventEmitter<string> = new EventEmitter();

    private _paymentSource!: PaymentSourceDisplayItem;
    private _payNowClickedForNewPaymentMethod: boolean = false;
    private _payNowClickedForExistingPaymentMethod: boolean = false;
    private _payNowClickedForPayPal: boolean = false;
    private _onlineConvenienceFee: number | null = null;
    private _totalAmountToPay: number | null = null;
    private _schoolChargesOnlineCreditCardFee: boolean = false;
    private _schoolChargesOnlineBankFee: boolean = false;
    private _currentStep: number  = 0;
    private _hasAtleastOnePaymentSource: boolean = false;
    private _loadingPaymentReturnStatus: boolean = true;
    private _animationDone: boolean = false;
    private _transactionDetailsForPayPalExpressCheckout: PayPalProExpressCheckoutCompletedTransactionDisplayItem | null = null;
    private _transactionDetailsForPayPalStandard: PayPalStandardCompletedTransactionDisplayItem | null = null;
    private _loadingPaymentSources: boolean = true;
    private _stripeReady: boolean = false;
    private _paymentSources: PaymentSourceDisplayItem[] = [];
    private _newPaymentOpened: boolean = false;
    private _paymentCompleteDisplayItem!: PaymentCompleteDisplayItem;
    private _processPaymentSourceResultItem!: ProcessPaymentSourceResultItem;
    private _surchargeData: Array<SurchargeResultItem> = [];
    private _currentPaymentSourceType: PaymentSourceType = PaymentSourceType.CreditCard;
    private _stripePaymentSourceType: PaymentSourceType = PaymentSourceType.CreditCard;
    private _stripeFormInit: boolean = false;
    private _existingPaymentMethodOpened: boolean = false;

    constructor(private _DialogService: DialogService,
        private _LocationStrategy: LocationStrategy) {
        super();
        this.CheckForPayPalReturn();
        history.pushState(null, document.title, window.location.href);
        this._LocationStrategy.onPopState(() => {
            if(this.context === PaymentContext.PaymentPortal){
                history.go(1);
            }
        }); 
    }

    override ngOnInit(): void {
        super.ngOnInit();

        if (this.paymentProcessor?.Type === PaymentProcessorType.PayPalPro || this.paymentProcessor?.Type === PaymentProcessorType.PayPalStandard
            || this.paymentProcessor?.Type === PaymentProcessorType.PayPalCommercePlatform ){
            this.ApplyOnlineConvenienceFee(PaymentSourceType.CreditCard);
        }

        if(this.mode === PaymentSourceMode.Payment && this.context !== PaymentContext.SignupWidget) {
            this.FetchPaymentSources();
        } else {
            this._loadingPaymentSources = false;
        }
    }

    AnimationDone(): void {
        if(this._currentStep > 0) {
            this._animationDone = true;
        }
    }

    PaymentSourceSelected(paymentSource: PaymentSourceDisplayItem): void {
        this._paymentSource = paymentSource;
        this.ApplyOnlineConvenienceFee(paymentSource.Type);
    }

    PaymentSourceTypeChanged(type: PaymentSourceType): void {
        if(this._newPaymentOpened || !this.hasAtleastOnePaymentSource) {
            this._stripePaymentSourceType = type;
            this.ApplyOnlineConvenienceFee(type);
        }
    }

    ExistingPaymentMethodOpened(opened: boolean): void {
        this._existingPaymentMethodOpened = opened;
        this._newPaymentOpened = !opened;
        if(opened){
            this.ApplyOnlineConvenienceFee(this._paymentSource.Type);
        }
    }

    NewPaymentMethodOpened(opened: boolean): void {
        if(opened && this.paymentProcessor.Type === PaymentProcessorType.Stripe) {
            this.ApplyOnlineConvenienceFee(this._stripePaymentSourceType);
        } else if (opened && this.paymentProcessor.Type === PaymentProcessorType.PayPalCommercePlatform) {
            this.ApplyOnlineConvenienceFee(PaymentSourceType.CreditCard);
        }
        this._newPaymentOpened = opened;
    }

    SubmissionResult(result: ProcessPaymentSourceResultItem | null){
        if (result !== null) {
            this._processPaymentSourceResultItem = result;
            this.NextStep();
        } else {
            this._payNowClickedForNewPaymentMethod = false;
            if(this.context === PaymentContext.SignupWidget) {
                this.SignupFormPaymentSourceReady(null);
            }
        }
    }

    SignupFormPaymentSourceReady(result: PaymentSourceInputItem | null) {
        this.signupFormPaymentSourceReady.emit(result);
        if(result === null) {
            this._payNowClickedForNewPaymentMethod = false;
        }
    }

    StripeNextStepComplete(intentID: string): void {
        this.stripeNextStepComplete.emit(intentID);
    }

    PayUsingExistingPaymentMethod(): void {
        this._payNowClickedForExistingPaymentMethod = true;
        ApiBaseRoutes.Payment.MakePayment.Call({
            Body: new MakePaymentInputItem({
                PaymentSourceID: this._paymentSource.ID,
                Amount: this._amountToPay! + (this.latePaymentFee ?? 0),
                InvoiceDetailsID: this.invoiceDetails?.ID ?? null
            })
        }).subscribe( {
            next: (data) => {
                this._paymentCompleteDisplayItem = data;
                this._payNowClickedForExistingPaymentMethod = false;
                this.NextStep();
            },
            error: (data) => {
                this._DialogService.CreateInfoDialog(new SimpleDialogData({
                    Title: $localize`:@@CommonAddPaymentSourcePaymentProcessorError:Payment Processor Error`,
                    Text: data.Response.ErrorMessage
                }), false).events.subscribe(data => {
                    if (data === DialogEvents.PrimaryAction) {
                        this._payNowClickedForExistingPaymentMethod = false;
                    }
                })
            }
        });
    }

    PayUsingPayPal(): void {
        this._payNowClickedForPayPal = true;
        if(this.paymentProcessor.Type === PaymentProcessorType.PayPalPro) {
            ApiBaseRoutes.Payment.PayPalExpressCheckoutGetURL.Call({
                Parameter: (this.mode === PaymentSourceMode.AnonymousPayment) ? this.schoolInfo!.ID : "",
                Body: new PayPalProExpressCheckoutGetUrlInputItem({
                    Amount: this._amountToPay! + (this.latePaymentFee ?? 0),
                    FamilyID: this.familyID,
                    ParentID: this.parentID,
                    PaymentProcessorID: this.paymentProcessor.ID,
                    ReturnUrl: window.location.href
                })
            }).subscribe( {
                next: (data) => { window.open(data, '_self'); }
            })
        } else if (this.paymentProcessor.Type === PaymentProcessorType.PayPalStandard) {
            ApiBaseRoutes.Payment.PayPalStandardGetURL.Call({
                Parameter: (this.mode === PaymentSourceMode.AnonymousPayment) ? this.schoolInfo!.ID : "",
                Body: new PayPalStandardGetUrlInputItem({
                    Amount: this._amountToPay! + (this.latePaymentFee ?? 0),
                    FamilyID: this.familyID,
                    ParentID: this.parentID,
                    PaymentProcessorID: this.paymentProcessor.ID,
                    ReturnUrl: window.location.href
                })
            }).subscribe( {
                next: (data) => { window.open(data, '_self'); }
            })
        }
    }

    PayUsingNewPaymentMethod(value: boolean = true): void {
        this._payNowClickedForNewPaymentMethod = value;
    }

    OnNextAnimationFrame(step: number) : number {
        return requestAnimationFrame(_ => {
            return step;
        })
    }

    DoneClicked(): void {
        this.doneClicked.emit();
    }

    SetStripeReady(): void {
        this._stripeReady = true;
    }

    private FetchPaymentSources(): void {
        const paymentSourcesSearchOptions = new PaymentSourceSearchOptions();
        paymentSourcesSearchOptions.ParentIDs = this.parentID ? [this.parentID] : [];
        paymentSourcesSearchOptions.Verified = true;
        ApiBaseRoutes.PaymentSources.Search.Call({
            Body: paymentSourcesSearchOptions
        }).subscribe(
            {
                next: (data) => {
                    this._paymentSources = data.ItemSubset;
                    this._hasAtleastOnePaymentSource = data.ItemSubset.length > 0 && this.paymentProcessor.StoredPaymentMethodsSupported;
                    this._loadingPaymentSources = false;
                },
                error: (error: typeof ApiBaseRoutes.PaymentSources.Search.Error) => {
                    this._loadingPaymentSources = false;
                    if(error.Status === 401) {
                        this.fetchPaymentSourcesFailed.emit();
                    } else {
                        throw(error);
                    }
                }
            }
        );
    }

    private CalculateOnlineConvenienceFee(): void {
        if (this.amountToPay && !this._surchargeData.length) {
            ApiBaseRoutes.PaymentSources.CalculateSurcharge.Call({
                Parameter: (this.mode === PaymentSourceMode.AnonymousPayment || this.context === PaymentContext.SignupWidget) ? this.schoolInfo.ID! : '',
                Body: new CalculateSurchargeInputItem({
                    Subtotal: this.amountToPay + (this.latePaymentFee ?? 0)
                })
            }).subscribe({
                next: (result) => {
                    this._surchargeData = result;
                    this.SetOnlineConvenienceFee();
                },
                error: (error: typeof ApiBaseRoutes.PaymentSources.CalculateSurcharge.Error) => {
                    if(error.Status === 401) {
                        // do nothing
                    } else {
                        throw(error);
                    }
                }
            })
        } else if (this.amountToPay && this._surchargeData.length) {
            this.SetOnlineConvenienceFee();
        } else {
            this._onlineConvenienceFee = null;
            this._totalAmountToPay = null;
        }
    }

    private SetOnlineConvenienceFee(): void {
        this._surchargeData.forEach(data => {
            if(data.PaymentSourceType === this._currentPaymentSourceType) {
                this._onlineConvenienceFee = data.Surcharge;
                this._totalAmountToPay = data.Total;
            }
        })
    }

    private NextStep(): void {
        this._currentStep++;
        //! "1" is the confirmation page, if we need to add a step, this will have to be changed 
        if (this._currentStep === 1) {
            this.paymentSuccessful.emit();
        }
    }

    private CheckIfAnimationDone(): void {
        if(this._animationDone){
            this._animationDone = false;
            this._payNowClickedForNewPaymentMethod = false;
        } else {
            requestAnimationFrame(_ => {
                this.CheckIfAnimationDone();
            })
        }
    }

    private ApplyOnlineConvenienceFee(type: PaymentSourceType): void {
        this._currentPaymentSourceType = type;
        switch(type) {
            case PaymentSourceType.BankAccount:
                if(this.ChargesOnlineConvenienceFeeForBankAccounts()) {
                    this._schoolChargesOnlineBankFee = true;
                    this.CalculateOnlineConvenienceFee();
                } else {
                    this.ResetConvenienceFees();
                }
                break;
            case PaymentSourceType.CreditCard:
            case PaymentSourceType.StripeLink:
                if(this.ChargesOnlineConvenienceFeeForCreditCards()) {
                    this._schoolChargesOnlineCreditCardFee = true;
                    this.CalculateOnlineConvenienceFee();
                } else {
                    this.ResetConvenienceFees()
                }
                break;
        }
    }

    private ResetConvenienceFees(): void {
        this._schoolChargesOnlineCreditCardFee = false;
        this._schoolChargesOnlineBankFee = false;
        this._onlineConvenienceFee = null;
        this._totalAmountToPay = (this._amountToPay ?? 0) + (this.latePaymentFee ?? 0); 
    }

    private ChargesOnlineConvenienceFeeForBankAccounts(): boolean {
        return this.paymentSurchargeACH! && this.paymentSurchargeACH.Type !== SurchargeType.None
    }

    private ChargesOnlineConvenienceFeeForCreditCards(): boolean {
        return this.paymentSurchargeCreditCard! && this.paymentSurchargeCreditCard.Type !== SurchargeType.None
    }

    private CheckForPayPalReturn(): void {
         //Check to see if we are coming back from PayPal
        const queryParams = new URL(window.location.href).searchParams;
        const status = queryParams.get('SB_Status') as PaymentReturnStatus;
        const sptd = queryParams.get('SPTD');
        const token = queryParams.get('token');
        const payerID = queryParams.get('PayerID');

        if(status) {
            let path = window.location.pathname;

            if(this.context === PaymentContext.PaymentPortal){
                queryParams.delete('SB_Status');
                queryParams.delete('SPTD');
                queryParams.delete('token');
                queryParams.delete('PayerID');

                path = `${path}?${queryParams}`;
            }

            history.replaceState(null, document.title, path);

            switch(status) {
                case PaymentReturnStatus.Cancelled:
                    this._loadingPaymentReturnStatus = false;
                    break;
                case PaymentReturnStatus.PayPalStandardCompleted:
                    ApiBaseRoutes.Payment.PayPalStandardComplete.Call({
                        Body: new PayPalStandardCompleteInputItem({
                            SerializedTransactionDetails: sptd
                        })
                    }).subscribe(data => {
                        this._transactionDetailsForPayPalStandard = data;
                        this._loadingPaymentReturnStatus = false;
                        this.NextStep();
                        this.CheckIfAnimationDone();
                    });
                    break;
                case PaymentReturnStatus.ExpressCheckoutCompleted:
                    ApiBaseRoutes.Payment.PayPalExpressCheckoutComplete.Call({
                        Body: new PayPalProExpressCheckoutCompleteInputItem({
                            Token: token,
                            PayerID: payerID,
                            SerializedTransactionDetails: sptd
                        })
                    }).subscribe(data => {
                        this._transactionDetailsForPayPalExpressCheckout = data;
                        this._loadingPaymentReturnStatus = false;
                        this.NextStep();
                        this.CheckIfAnimationDone();
                    });
                    break;
                case PaymentReturnStatus.PayPalCommercePlatformCompleted:
                    const intentAutoPayCode = queryParams.get('I');
                    if (!token || !sptd || !intentAutoPayCode) {
                        console.error('Missing data; cannot complete order (PayPal Commerce Platform)');
                    } else {
                        this.ProcessPayPalCommercePlatformOrderCapture(token, intentAutoPayCode, sptd);
                    }
                    break;
            }

        } else {
            this._loadingPaymentReturnStatus = false;
        }
    }

    private ProcessPayPalCommercePlatformOrderCapture(orderID: string, intentAutoPayCode: string, transactionDetails: string) {
        const inputItem = new PayPalCommercePlatformPaymentSourceInputItem({
            SerializedTransactionDetails: transactionDetails,
            OrderID: orderID,
            IntentAutoPayCode: intentAutoPayCode
        });

        ApiBaseRoutes.PaymentSources.Add.Call({
            Parameter: this.mode === PaymentSourceMode.AnonymousPayment ? this.schoolInfo.ID : '',
            Body: inputItem
        }).subscribe({
            next: result => {
                this._processPaymentSourceResultItem = result;
                this._totalAmountToPay = result.AmountPaid;
                this._loadingPaymentReturnStatus = false;

                this.NextStep();
                this.CheckIfAnimationDone();
            },
            error: (error: typeof ApiBaseRoutes.PaymentSources.Add.Error) => {
                if (error.Response.NewErrorCode === ErrorCode.OnlinePaymentError) {
                    this._DialogService.CreateInfoDialog(new SimpleDialogData({
                        Title: $localize`:@@CommonAddPaymentSourcePaymentProcessorError:Payment Processor Error`,
                        Text: error.Response.ErrorMessage!,
                    }), false).events.subscribe(event => {
                        if (event === DialogEvents.PrimaryAction) {
                            this._loadingPaymentReturnStatus = false;
                        }
                    });
                } else {
                    throw error;
                }
            }
        });
    }

    get payNowClicked(): boolean {
        return this._payNowClickedForExistingPaymentMethod || this._payNowClickedForNewPaymentMethod || this._payNowClickedForPayPal;
    }

    get payNowClickedForNewPaymentMethod(): boolean {
        return this._payNowClickedForNewPaymentMethod;
    }

    get PaymentMethodType(): typeof PaymentMethodType {
        return PaymentMethodType;
    }

    get PaymentSourceMode(): typeof PaymentSourceMode {
        return PaymentSourceMode;
    }

    get mode(): PaymentSourceMode {
        return this._mode;
    }

    get schoolChargesOnlineConvenienceFee(): boolean {
        return ((this._schoolChargesOnlineBankFee && this.paymentProcessor.Type === PaymentProcessorType.Stripe) || this._schoolChargesOnlineCreditCardFee);
    }

    get onlineConvenienceFee(): number | null {
        return this._onlineConvenienceFee;
    }

    get currentStep(): number {
        return this._currentStep;
    }

    get hasAtleastOnePaymentSource(): boolean {
        return this._hasAtleastOnePaymentSource;
    }

    get SurchargeType(): typeof SurchargeType {
        return SurchargeType;
    }

    get InvoiceStatus(): typeof InvoiceStatus {
        return InvoiceStatus;
    }

    get PaymentProcessorType(): typeof PaymentProcessorType {
        return PaymentProcessorType;
    }

    get loadingPaymentReturnStatus(): boolean {
        return this._loadingPaymentReturnStatus;
    }

    get transactionDetailsForPayPalExpressCheckout(): PayPalProExpressCheckoutCompletedTransactionDisplayItem | null {
        return this._transactionDetailsForPayPalExpressCheckout;
    }

    get transactionDetailsForPayPalStandard(): PayPalStandardCompletedTransactionDisplayItem | null {
        return this._transactionDetailsForPayPalStandard;
    }


    get loadingPaymentSources(): boolean {
        return this._loadingPaymentSources;
    }

    get stripeReady(): boolean {
        return this._stripeReady;
    }

    get PaymentContext(): typeof PaymentContext {
        return PaymentContext;
    }

    get loading(): boolean {
        return this.loadingPaymentReturnStatus || this.loadingPaymentSources;
    }

    get paymentSources(): PaymentSourceDisplayItem[] {
        return this._paymentSources;
    }

    get amountToPay(): number | null {
        return this._amountToPay;
    }

    get totalAmountToPay(): number | null {
        return this._totalAmountToPay;
    }

    get schoolChargesOnlineCreditCardFee(): boolean {
        return this._schoolChargesOnlineCreditCardFee;
    }
    get schoolChargesOnlineBankFee(): boolean {
        return this._schoolChargesOnlineBankFee;
    }

    get paymentCompleteDisplayItem(): PaymentCompleteDisplayItem {
        return this._paymentCompleteDisplayItem;
    }

    get processPaymentSourceResultItem(): ProcessPaymentSourceResultItem {
        return this._processPaymentSourceResultItem;
    }

    get latePaymentFee(): number | null {
        return this.invoiceDetails?.LateFee ?? null;
    }
}
