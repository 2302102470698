export enum AvatarSize {
    Small = 'avatar-small',
    Medium = 'avatar-medium',
    Large = 'avatar-large',
    Edit = 'avatar-edit'
}

export enum AvatarProfileCrop {
    Automatic,
    Center
}

export class AvatarProfileUploadData {
    Files!: File[];
    UseFaceDetection!: boolean;
}

export enum ProfileCardMode {
  Avatar,
  Logo,
  None
}