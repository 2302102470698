import { Component, Input } from '@angular/core';
import { WebsiteMode } from '@library/base';
import { WebsiteBlockDisplayItem, WebsiteBlockType, WebsiteRenderContextDisplayItem } from '@library/data-models';

@Component({
    selector: 'lib-website-block',
    templateUrl: './website-block.component.html',
    styleUrls: ['./website-block.component.scss']
})
export class WebsiteBlockComponent {

    @Input() data!: WebsiteBlockDisplayItem;
    @Input() renderContext!: WebsiteRenderContextDisplayItem;


    get WebsiteBlockType(): typeof WebsiteBlockType {
        return WebsiteBlockType;
    }

    get WebsiteBlockDisplayItem(): typeof WebsiteBlockDisplayItem {
        return WebsiteBlockDisplayItem;
    }

    get WebsiteMode(): typeof WebsiteMode {
        return WebsiteMode;
    }
}
