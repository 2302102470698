<lib-form-field-container
    [control]="formControl"
    [isLoading]="isLoading"
    [isReadOnly]="isReadOnly"
    [isViewOnly]="isViewOnly"
    [isOptional]="isOptional"
    [isDisabled]="isDisabled"
    [prefixIcon]="prefixIcon"
    [suffixIcon]="Icon.Common.CaretDown"
    [errors]="errors"
    [matMenuTriggerFor]="colorPaletteMenu"
    #colorPaletteTriggerMenu = "matMenuTrigger">
        <ng-content label select="[label]"></ng-content>
        <ng-content hintMessage select="[hintMessage]"></ng-content>
        <ng-content prefixText select="[prefixText]"></ng-content>
        <ng-content suffixText select="[suffixText]"></ng-content>
        <ng-content readOnly select="[readOnly]"></ng-content>
        <div class="d-flex align-items-center gap-1 w-100">
            <div [style.background]="formControl.value" class="color-picker-preview">
        
            </div>
            <p>{{formControl.value}}</p>
        </div>    
</lib-form-field-container>

<mat-menu #colorPaletteMenu="matMenu">
    <div (click)="$event.stopPropagation()">
        <compact-picker
            [control]="compactControl"
            (colorChange)="ColorChangeEvent($event)"
            (mousedown)="MouseDown()"
            (touchstart)="TouchStart()">
        </compact-picker>
    </div>
    <div *ngIf="defaultValue !== null" style="padding-left:12px; padding-right:12px;" class="w-100">
        <lib-button
            [kind]="ButtonKind.Secondary"
            [size]="ButtonSize.FullWidth"
            (clicked)="ResetToDefaultColor()">
            <ng-container label i18n="@@CommonColorPickerDefaultLabel">Reset to Default</ng-container>
        </lib-button>
    </div>
</mat-menu>

<!-- hidden logo  -->
<img #logo style="display: none;" [src]="base64Image" (load)="GetDominantColors()">