<div class="container-fluid m-0">
    <div *ngIf="hasBackButton" class="single-column-row">
        <lib-button
            [kind]="ButtonKind.Link"
            [prefixIconSize]="IconSize.ExtraSmall"
            [prefixIcon]="Icon.Common.ChevronLeft"
            (clicked)="BackButtonClicked()">
            <ng-container label>
                <ng-content select="[backButtonLabel]"></ng-content>
            </ng-container>
        </lib-button>
    </div>

    <div *ngIf="hasBackButton" class="pb-2">
        <ng-container title>
            <h2 class="typography-semibold"><ng-content select="[titleLabel]"></ng-content></h2>
        </ng-container>
    </div>
    <div class="row p-0 gx-4">
        <div class="col-12 col-xl-3">
            <ng-content select="[detailSummary]"></ng-content>
        </div>
        <div class="col-12 col-xl-9">
            <ng-content select="[detailContent]"></ng-content>
        </div>
    </div>

</div>
