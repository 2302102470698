import { QueryList, Type } from '@angular/core';
import { NavigationEnd, RouterOutlet } from '@angular/router';
import { Base } from '@library/base';
import { SideBarItemComponent } from '../sidebar/sidebar-item/sidebar-item.component';

export abstract class ApplicationLayoutContainerService extends Base {

    protected _currentHeaderTitle: string = "";
    protected _currentComponentType: Type<any> | null = null;

    constructor() {
        super();
    }

    HandleNavigationEnd(event: NavigationEnd, sidebarItemList: QueryList<SideBarItemComponent>) {
        const url = event.url.slice(1);
        const urlRedirect = event.urlAfterRedirects.slice(1);
        const sidebarItems = sidebarItemList.toArray();

        if (url.includes('my-preferences')) {
            this._currentHeaderTitle = $localize`:@@CommonMyPreferences:My Preferences`;
            sidebarItems.forEach(element => element.linkActive = false);  
        } else if (url.includes('diagnostics')) {
            this._currentHeaderTitle = $localize`:@@CommonDiagnosticsTitle:Diagnostics`; 
            sidebarItems.forEach(element => element.linkActive = false);  
        } else {
            sidebarItems.forEach(navigationElement => {
                if(url.includes(navigationElement.routerLink) || urlRedirect.includes(navigationElement.routerLink))  {
                    this._currentHeaderTitle = navigationElement.navigationTitle;
                    navigationElement.linkActive = true;
                } else {
                    navigationElement.linkActive = false;
                }
            });
        }
    }

    abstract GetRouteAnimation(componentType: Type<any>): string;

    get currentHeaderTitle(): string {
        return this._currentHeaderTitle;
    }

    set currentComponentType(value: Type<any> | null) {
        this._currentComponentType = value;
    }
}
