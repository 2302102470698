import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormFieldInputBaseComponent } from '../form-field-input-base.component';
import { TextKind } from '@library/base';

@Component({
    selector: 'lib-input-text-area',
    templateUrl: './form-field-text-area.component.html',
    styleUrls: ['./form-field-text-area.component.scss']
})
export class FormFieldTextAreaComponent extends FormFieldInputBaseComponent<string | null> implements OnInit {
    @ViewChild('textareaRef', {static: true}) textareaRef!: ElementRef<HTMLElement>;

    @Input() textKind: TextKind = TextKind.Default;
    @Input() heightFitContent: boolean = false;
    
    private _currHeight: number = 84;
    private _rows: number = 4; 
    @Input()
    set rows(value: number) {
        this._rows = value;
        this._currHeight = value * 21; // 21px is the line height

        // since font-size is fixed to $font-size-base * 0.875 !important; in _form-field.scss
        // no need to set the line height a dynamic input
    }

    constructor() {
        super();

        this.viewControl.valueChanges.subscribe(value => {
            this.SetFormControlValue(value?.trim());
    
            if(this.heightFitContent) {
                this.ResetHeight();
            }
        })
    }
    
    ngAfterViewInit() {
        // to read textarea default height in _form-field.scss
        const root = document.documentElement;
        const defaultHeight = getComputedStyle(root).getPropertyValue('--textarea-height');

        this.textareaRef.nativeElement.style.setProperty("height", Math.max(this._currHeight, parseInt(defaultHeight, 10)) + "px");
        this.textareaRef.nativeElement.style.setProperty("min-height", Math.max(this._currHeight, parseInt(defaultHeight, 10)) + "px", "important");

        if(this.heightFitContent) {
            this.textareaRef.nativeElement.parentElement?.style.setProperty("max-height", "unset", "important");

            setTimeout(() => { // need to wait for the element ready then read its scroll height
                this.ResetHeight();
            });
        }
    }
    
    private ResetHeight(): void {
        // set the height to nothing first so that we can get the scroll height, then we can set the height according to the scroll height
        this.textareaRef.nativeElement.style.setProperty("height", this.textareaRef.nativeElement.offsetHeight + "px");
        this.textareaRef.nativeElement.style.setProperty("min-height", this.textareaRef.nativeElement.offsetHeight + "px", "important");

        this.textareaRef.nativeElement.style.setProperty("height", Math.max(this._currHeight, this.textareaRef.nativeElement.scrollHeight) + "px");
        this.textareaRef.nativeElement.style.setProperty("min-height", Math.max(this._currHeight, parseInt(this.textareaRef.nativeElement.style.height, 10)) + "px", "important");
    }

    get rows(): number {
        return this._rows;
    }
}
