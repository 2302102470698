import { AfterViewInit, Component, ContentChildren, EventEmitter, inject, Input, OnInit, Output, QueryList, Renderer2, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { BaseViewComponent } from '@library/base';
import { MultiStepViewStepComponent } from './step/multi-step-view-step.component';


@Component({
    selector: 'lib-stepper',
    templateUrl: './multi-step-view.component.html',
    styleUrls: ['./multi-step-view.component.scss'],
})
export class MultiStepViewComponent extends BaseViewComponent implements OnInit,AfterViewInit{

    @ViewChild(MatStepper, {static: false}) stepper!: MatStepper;
    @ContentChildren(MultiStepViewStepComponent) steps!: QueryList<MultiStepViewStepComponent>;

    @Input() showTotalCount: boolean = true;
    @Input() showHeader: boolean = true;
    @Input() hasCardContainer: boolean = true;
    @Input() showSteps: boolean = false;

    private _currentStep: number = 0;
    @Input()
    public set currentStep(value: number) {
        setTimeout(() => {
            if(this.stepper){
                this.stepper.selected!.completed = value > this.stepper.selectedIndex;
                this.stepper.selectedIndex = value;
            }        
            this._currentStep = value;
        }, 250);
    }
    @Output() initStep: EventEmitter<number> = new EventEmitter();

    private _Renderer: Renderer2 = inject(Renderer2);

    ngAfterViewInit(): void {
        if(!this.showHeader){
            this._Renderer.addClass(this.stepper._stepHeader.first._elementRef.nativeElement.parentElement, 'd-none')
        }
        this.initStep.emit(this.stepper.selectedIndex);
    }

    get currentStep(): number {
        return this._currentStep;
    }
}
