import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BaseViewComponent } from '@library/base';
import { map, Observable, timer } from 'rxjs';

@Component({
    selector: 'lib-image',
    templateUrl: './image.component.html',
    styleUrls: ['./image.component.scss'],
})
export class ImageComponent extends BaseViewComponent{
    @ViewChild('imageRef') imageRef!: ElementRef<HTMLImageElement>;

    @Input() src: string = "";
    @Input() alt: string = "";

    // not needed unless you want to set specific width and height
    @Input() width: number = 0;
    @Input() height: number = 0;
    private _offsetXPercent: number = 50;
    @Input() 
    set offsetXPercent(value: number) {
        if (value !== undefined && value !== null) {
            this._offsetXPercent = value;
            this.UpdateTransform();
        }
    }
    private _offsetYPercent: number = 50;
    @Input() 
    set offsetYPercent(value: number) {
        if (value !== undefined && value !== null) {
            this._offsetYPercent = value;
            this.UpdateTransform();
        }
    }
    // Image scale field is added just in case we need it in the future. The object-fit: cover will automatically scale the image.
    private _imageScale: number = 1;
    @Input() 
    set imageScale(value: number) {
        if (value !== undefined && value !== null) {
            this._imageScale = value;
        }
    }

    @Input() viewOnly: boolean = false;
    @Input() showCursorPointer: boolean = false;

    @Output() saveTransform = new EventEmitter<any>();

    private _isLoading: boolean = true;
    private _isDragging: boolean = false;
    private _startX: number = 0;
    private _startY: number = 0;
    private _hasImage: boolean = false;

    private _realWidth: number = 0;
    private _realHeight: number = 0;
    private _widthDragRoom: number = 0;
    private _heightDragRoom: number = 0;

    private _MouseUp = this.MouseUp.bind(this);
    private _MouseMove = this.MouseMove.bind(this);
    private _TouchMove = this.TouchMove.bind(this);
    private _TouchCancel = this.TouchCancel.bind(this);
    private _touchDelay: Observable<0> | null = null;

    ngAfterViewInit() {
        requestAnimationFrame(() => {
            if (this.imageRef && this.imageRef.nativeElement) {
                const imageRadius = window.getComputedStyle(this.imageRef.nativeElement).borderRadius;
                this.imageRef.nativeElement!.parentElement!.style.borderRadius = imageRadius;
            }
        })
    }

    MouseDown(event: MouseEvent): void {
        if (this._hasImage && !this.viewOnly && !this._isDragging) {
            this.StartDrag(event.clientX, event.clientY);
        }
    }

    TouchStart(event: TouchEvent): void {
        if (event.touches[0] && !this.viewOnly && this._hasImage && !this._isDragging && !this._touchDelay) {
            document.addEventListener('touchmove', this._TouchCancel);

            this._touchDelay = timer(150);
            this._touchDelay.subscribe(_ => {
                document.removeEventListener('touchmove', this._TouchCancel);

                if (this._touchDelay) {
                    this.StartDrag(event.touches[0].clientX, event.touches[0].clientY);
                    this._touchDelay = null;
                }
            });
        }
    }

    private TouchCancel(): void {
        this._touchDelay = null;
    }

    private StartDrag(x: number, y: number): void {
        this._isDragging = true;
        this._startX = x;
        this._startY = y;

        document.addEventListener('mousemove', this._MouseMove);
        document.addEventListener('touchmove', this._TouchMove, { passive: false });

        document.addEventListener('mouseup', this._MouseUp);
        document.addEventListener('touchend', this._MouseUp);
    }

    private MouseMove(event: MouseEvent): void {
        this.Drag(event.clientX, event.clientY);
    }

    private TouchMove(event: TouchEvent): void {
        if (event.touches[0]) {
            this.Drag(event.touches[0].clientX, event.touches[0].clientY);
            event.preventDefault();
        }
    }

    private Drag(x: number, y: number): void {
        if (this._widthDragRoom > 0) {
            this._offsetXPercent += 100 * (this._startX - x) / this._widthDragRoom;
            this._offsetXPercent = Math.max(0, Math.min(100, this._offsetXPercent));
        } else {
            this._offsetXPercent = 50;
        }
        if (this._heightDragRoom > 0) {
            this._offsetYPercent += 100 * (this._startY - y) / this._heightDragRoom;
            this._offsetYPercent = Math.max(0, Math.min(100, this._offsetYPercent));
        } else {
            this._offsetYPercent = 50;
        }

        this._startX = x;
        this._startY = y;
        this.UpdateTransform();
    }

    private MouseUp(): void {
        this._isDragging = false;
        this.saveTransform.emit({
            OffsetXPercent: this._offsetXPercent, 
            OffsetYPercent: this._offsetYPercent,
            ImageScale: this._imageScale
        });

        document.removeEventListener('mouseup', this._MouseUp);        
        document.removeEventListener('touchend', this._MouseUp);
        document.removeEventListener('mousemove', this._MouseMove);
        document.removeEventListener('touchmove', this._TouchMove);
    }

    IncreaseScale(): void {
        this._imageScale += 0.1;
        this.UpdateTransform();
    }

    DecreaseScale(): void {
        this._imageScale -= 0.1;
        this.UpdateTransform();
    }

    UpdateTransform(): void {
        if (this.imageRef && this.imageRef.nativeElement) {
            this.imageRef.nativeElement!.style.objectPosition = `${this._offsetXPercent}% ${this._offsetYPercent}%`;
        }   
    }

    ImageLoaded(): void {
        this._isLoading = false;
        this._hasImage = !(this.src === 'assets/images/Website Block Text And Media - Placeholder - Square.svg' ||
                            this.src === 'assets/images/Website Block Text And Media - Placeholder - Square - Side Bar.svg' ||
                            this.src === 'assets/Website Block Text And Media - Placeholder - Square.svg'); // in case the old src was cached.

        if (this._hasImage) {

            if (!this.viewOnly) {
                const imageElement = this.imageRef.nativeElement!;
                imageElement.style.cursor = 'move';

                const xRatio = imageElement.clientWidth / imageElement.naturalWidth;
                const yRatio = imageElement.clientHeight / imageElement.naturalHeight;

                if (xRatio < yRatio) {
                    this._realWidth = yRatio * imageElement.naturalWidth;
                    this._realHeight = imageElement.clientHeight // = yRatio * imageElement.naturalHeight;
                } else {
                    this._realWidth = imageElement.clientWidth // = xRatio * imageElement.naturalWidth;
                    this._realHeight = xRatio * imageElement.naturalHeight;
                }

                this._widthDragRoom = this._realWidth - imageElement.clientWidth;
                this._heightDragRoom = this._realHeight - imageElement.clientHeight;
            }

            this.UpdateTransform();
        }   
    }

    ImageCouldNotLoad(): void {
        this.src = "assets/images/Website Block Text And Media - Placeholder - Square.svg";
        this._hasImage = false;
    }

    get isLoading(): boolean {
        return this._isLoading;
    }

    get hasImage(): boolean {
        return this._hasImage;
    }
}
