import { Component, Input, OnInit } from '@angular/core';
import { ButtonSize, IconSize, Icon, IconItem } from '@library/base';
import { ButtonBaseComponent } from '../button-base.component';

@Component({
    selector: 'lib-icon-button',
    templateUrl: './icon-button.component.html',
    styleUrls: ['./icon-button.component.scss']
})
export class IconButtonComponent extends ButtonBaseComponent implements OnInit {
    @Input() isRound: boolean = false;
    @Input() icon: IconItem = Icon.Common.Star;

    private _buttonStyleClass: string = "";
    private _iconSize: IconSize = IconSize.Medium;

    override ngOnInit(): void {
        super.ngOnInit();
        this.SetButtonClass(); 
    }

    protected override SetButtonClass(): void {
        this._buttonStyleClass = this.kind.concat(' ').concat('icon-button');

        if (this.disabled) {
            this._buttonStyleClass = this._buttonStyleClass.concat(' ').concat('disabled');
        }

        if (this.isRound) {
            this._buttonStyleClass = this._buttonStyleClass.concat(' ').concat('icon-button-round');
        }

        this.SetSize();
    }

    private SetSize(): void {
        this._buttonStyleClass = this._buttonStyleClass.concat(' ').concat(this.size);
        this._buttonStyleClass = this._buttonStyleClass.concat(' ');
        switch(this.size) {
            case ButtonSize.Small:
                this._buttonStyleClass = this._buttonStyleClass.concat('icon-button-small');
                this._iconSize = IconSize.ExtraSmall;
                break;
            case ButtonSize.Medium:
                this._buttonStyleClass = this._buttonStyleClass.concat('icon-button-medium');
                this._iconSize = IconSize.Small;
                break;
            case ButtonSize.Large:
                this._buttonStyleClass = this._buttonStyleClass.concat('icon-button-large');
                this._iconSize = IconSize.Medium;
                break;
            default:
                this._buttonStyleClass = this._buttonStyleClass.concat('icon-button-medium');
        }
    }

    get buttonStyleClass(): string {
        return this._buttonStyleClass;
    }

    get iconSize(): IconSize {
        return this._iconSize;
    }
}
