
<div class="d-flex"
(mouseenter)="MouseEnterGeneralImage()"
(mouseleave)="MouseLeaveGeneralImage()">

<div *ngIf="showImageActionMenu" [ngClass]="{'design-block-item-action-menu': !blockHasCard, 'design-block-item-action-menu-card': blockHasCard}">
    <div class="d-flex gap-2 px-2">
        <lib-button
            [kind]="ButtonKind.Tertiary"
            [prefixIcon]="Icon.Common.ArrowBack"
            [prefixIconSize]="IconSize.ExtraSmall"
            [size]="ButtonSize.Small"
            [iconOnly]="true"
            (clicked)="ImageActionClicked(WebsiteGalleryItemAction.LeftSwap)">
        </lib-button>
        <lib-button
            [kind]="ButtonKind.Tertiary"
            [prefixIcon]="Icon.Common.ArrowForward"
            [prefixIconSize]="IconSize.ExtraSmall"
            [size]="ButtonSize.Small"
            [iconOnly]="true"
            (clicked)="ImageActionClicked(WebsiteGalleryItemAction.RightSwap)">
        </lib-button>
        <lib-button
            [kind]="ButtonKind.Tertiary"
            [prefixIcon]="Icon.Common.CloudUploadOutline"
            [prefixIconSize]="IconSize.ExtraSmall"
            [size]="ButtonSize.Small"
            [iconOnly]="true"
            (clicked)="ImageActionClicked(WebsiteGalleryItemAction.MediaGallery)">
        </lib-button>
        <lib-button *ngIf="!disableImageDetails"
            [kind]="ButtonKind.Tertiary"
            [prefixIcon]="Icon.Common.InfoOutline"
            [prefixIconSize]="IconSize.ExtraSmall"
            [size]="ButtonSize.Small"
            [iconOnly]="true"
            [disabled]="disableImageDetails"
            (clicked)="ImageActionClicked(WebsiteGalleryItemAction.MediaDetails)">
        </lib-button>
        <lib-button
            [kind]="ButtonKind.DangerSecondary"
            [prefixIcon]="Icon.Common.DeleteOutline"
            [prefixIconSize]="IconSize.ExtraSmall"
            [size]="ButtonSize.Small"
            [iconOnly]="true"
            [disabled]="disableRemoveCard"
            (clicked)="ImageActionClicked(WebsiteGalleryItemAction.Delete)">
        </lib-button>
    </div>
  </div>


<div [ngClass]="{
    'custom-gallery-circles': kind === WebsiteBlockTextAndMediaType.GalleryCircleColumn || kind === WebsiteBlockTextAndMediaType.GalleryCircleAccentColumn || kind === WebsiteBlockTextAndMediaType.GalleryCircleCardColumn,
    'background-base-0': kind === WebsiteBlockTextAndMediaType.GallerySquareCardColumn || kind === WebsiteBlockTextAndMediaType.GalleryCircleCardColumn,
    'custom-three-column-card':kind === WebsiteBlockTextAndMediaType.GallerySquareCardColumn || kind === WebsiteBlockTextAndMediaType.GalleryCircleCardColumn,
    'custom-three-column-accent': kind === WebsiteBlockTextAndMediaType.GallerySquareAccentColumn || kind === WebsiteBlockTextAndMediaType.GalleryCircleAccentColumn,
    'custom-gallery-squares': kind === WebsiteBlockTextAndMediaType.GallerySquareColumn || kind === WebsiteBlockTextAndMediaType.GallerySquareAccentColumn || kind === WebsiteBlockTextAndMediaType.GallerySquareCardColumn,  
    'gallery-column-block-item-hover-card': showImageActionMenu && blockHasCard,
    'gallery-column-block-item-hover': showImageActionMenu && !blockHasCard}">
    <div class="d-flex justify-content-center flex-column align-items-center custom-card scss-spacing-text-media-block gallery-column-block-item"
        [ngClass]="{'scss-column-block-card-text-color': kind === WebsiteBlockTextAndMediaType.GallerySquareCardColumn || kind === WebsiteBlockTextAndMediaType.GalleryCircleCardColumn}"
    >
        <div class="d-flex justify-content-center align-items-center">
            <ng-content select="[image]"></ng-content>
        </div>
        <h1 class="text-align-center"><ng-content select="[header]"></ng-content></h1>
        <p  class="text-align-center"><ng-content select="[body]"></ng-content></p>
    </div>
</div>
</div>