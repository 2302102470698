<div #container  class="scss-split-button" >
    <div class="d-flex" #splitMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="button.disabled ? null : splitMenu">
        <lib-button  #button
            [kind]="kind"
            [size]="size === ButtonSize.FullWidth ? ButtonSize.Medium : size"
            [prefixIcon]="prefixIcon"
            [prefixIconSize]="prefixIconSize"
            (clicked)="ButtonClicked($event, splitMenuTrigger)">
            <ng-content select="[label]" label></ng-content>
        </lib-button>
        <lib-icon-button
            [icon]="Icon.Common.CaretDown"
            [kind]="kind"
            [size]="size" 
            (clicked)="OpenMenuFromButton(splitMenuTrigger)">
        </lib-icon-button>
    </div>

</div>

<mat-menu #splitMenu="matMenu"
    [hasBackdrop]="hasBackdrop">
    <ng-container *ngFor="let item of menuItemComponents">
        <div class="v3" [attr.data-sb-qa]="item.qaTag" [ngStyle]="{'min-width': menuWidth}">
            <mat-divider *ngIf="item.menuDivider === MenuDivider.Above" class="pb-2 mt-2 mx-2"></mat-divider>
            <div class="ps-3 menu-item-header typography-body-medium-strong">
            <ng-container *ngTemplateOutlet="item.menuItemHeader"></ng-container>
            </div>
        
            <button *ngIf="!item.hasSubOptions && !item.urlLink" [matTooltip]="item.toolTipText" matTooltipPosition="after"
                [ngClass]="{'disable-menu-item': item.disabled,'menu-item-default': !item.hint, 'menu-item-with-hint': item.hint}"
                mat-menu-item (click)="MenuItemClicked(item, $event)">
                <ng-container *ngTemplateOutlet="menuItem; context:{$implicit:item}"></ng-container>
            </button>
            <a class="link-menu-item" *ngIf="!item.hasSubOptions && item.urlLink" [matTooltip]="item.toolTipText" matTooltipPosition="after"
                [ngClass]="{'disable-menu-item': item.disabled,'menu-item-default': !item.hint, 'menu-item-with-hint': item.hint}"
                mat-menu-item [href]="item.urlLink"   routerLinkActive="active"
                target="_blank" (click)="MenuItemClicked(item, $event)"  onclick="return false">
                <ng-container *ngTemplateOutlet="menuItem; context:{$implicit:item}"></ng-container>
            </a>
            <mat-divider *ngIf="item.menuDivider === MenuDivider.Below" class="pt-2 mt-2 mx-2"></mat-divider>
        </div>
    </ng-container>
</mat-menu>

<ng-template #menuItem let-item>
    <div *ngIf="item.label" class="d-flex align-items-center h-100 my-auto" [ngClass]="{'menu-item-under-group': item.hasGroupHeader}">
    <lib-icon *ngIf="item.prefixIcon" [icon]="item.prefixIcon" [size]="IconSize.ExtraSmall" [visible]="item.prefixIconVisible" class="pe-2"></lib-icon>
    <div >
        <p class="typography-input">{{item.label}}</p>
        <caption *ngIf="item.hint">{{item.hint}}</caption>
    </div>
    <lib-icon *ngIf="item.suffixIcon" [icon]="item.suffixIcon" [size]="IconSize.ExtraSmall" [visible]="item.suffixIconVisible" class="ps-2"></lib-icon>
    </div>
    <div *ngIf="!item.label" class="d-flex align-items-center h-100 my-auto" [ngClass]="{'menu-item-under-group': item.hasGroupHeader}">
      <ng-container *ngTemplateOutlet="item.menuItemContent"></ng-container>
    </div>
</ng-template>
