import { Component, Input, OnInit } from '@angular/core';
import { ButtonBaseComponent } from './button-base.component';

@Component({
    selector: 'lib-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
})
export class ButtonComponent extends ButtonBaseComponent implements OnInit {

    
    override ngOnInit(): void {
        super.ngOnInit();      
    }
}
