import { Directive } from "@angular/core";
import { MatSelect } from "@angular/material/select";


@Directive({
    selector: '[matOptionHeight]'
  })
  export class MatOptionHeightDirective {
      //@Input() itemHeight: any = 42;
      constructor(
        private _matSelect: MatSelect
      ) {
        (<any> this._matSelect)._getItemHeight = () => 40;
        // The value returned above must match whatever you set in css for .mat-option !
        // if you set height: 32px in css, you must return 32 here as well
        
        // The default is = this.matSelect._triggerFontSize * 3
        // this is equivalent to { line-height: 3em } in css
      }
  }