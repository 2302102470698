<lib-form-field-container
    #anchor
    [control]="formControl"
    [kind]="FormContainerKind.Expandable"
    [isLoading]="isLoading"
    [isReadOnly]="isReadOnly"
    [isViewOnly]="isViewOnly"
    [isOptional]="isOptional"
    [isDisabled]="isDisabled"
    [prefixIcon]="prefixIcon"
    [suffixIcon]="suffixIcon"
    [hideErrors]="autocomplete ? autocomplete.panelOpen : false"
    [errors]="errors"
    (fieldClicked)="Clicked($event); $event.stopPropagation()"
    (containerClicked)="Blur()"
    (mousedown)="$event.preventDefault()">

    <ng-content label select="[label]"></ng-content>
    <ng-content hintMessage select="[hintMessage]"></ng-content>
    <ng-content prefixText select="[prefixText]"></ng-content>
    <ng-content suffixText select="[suffixText]"></ng-content>
    <ng-container readOnly>
        <div class="wrapper-content" #providedReadOnly>
            <ng-content select="[readOnly]"></ng-content>
        </div>
        <div *ngIf="isReadOnly" class="default-content d-flex flex-row">
            <ng-container *ngFor="let item of selectedItems; let last = last">
                <div *ngTemplateOutlet="item.content"></div><div>{{last ? '' : ',&nbsp;'}}</div>
            </ng-container>
            <ng-container *ngIf="selectedItems.size === 0" i18n="@@CommonNotSpecifiedLabel">
                Not specified
            </ng-container>
        </div>
    </ng-container>

    <ng-content customAction select="[customAction]"></ng-content>
    <ng-content bottomMenu select="[bottomMenu]"></ng-content>

    <lib-menu-button menu *ngIf="showQuickSelect && !isReadOnly" [disabled]="isDisabled" [kind]="ButtonKind.Link">
        <ng-container label i18n="@@CommonQuickSelectLabel">
            Quick Select
        </ng-container>
        <lib-menu-item
            i18n-label="@@CommonQuickSelectClearLabel"
            label="Clear"
            [stopPropagation]="true"
            [qaTag]="qaTag+'-clear-all'"
            (clicked)="ClearAll()">
        </lib-menu-item>
        <lib-menu-item
            i18n-label="@@CommonQuickSelectSelectAllLabel"
            label="Select All"
            [stopPropagation]="true"
            [qaTag]="qaTag+'-select-all'"
            (clicked)="SelectAll()">
        </lib-menu-item>
    </lib-menu-button>


    <div *ngIf="!isLoading && !isReadOnly" 
    [ngClass]="{'scss-form-contianer-small': size === FormContainerSize.Small,
                'scss-form-container-medium': size === FormContainerSize.Medium,
                'scss-form-container-large': size === FormContainerSize.Large
    }">
        <mat-chip-grid #chipList [disabled]="isDisabled">
            <mat-chip-row *ngFor="let item of selectedItems"
                [editable]="false"
                (removed)="Remove(item)"
                (mousedown)="$event.stopPropagation()"
                (click)="ChipSelected(item, $event)">
                <span class="scss-chip-text typography-body-medium">
                    <ng-container *ngTemplateOutlet="item.content"></ng-container>
                </span>
                <lib-icon matChipRemove
                    [icon]="Icon.Common.CloseCircle"
                    [size]="IconSize.Small">
                </lib-icon>
            </mat-chip-row>

            <input #input
                [placeholder]="chipsPlaceholderText"
                [formControl]="viewControl"
                [matAutocomplete]="auto"
                [matAutocompleteConnectedTo]="anchor?.origin!"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
                [attr.data-sb-qa]="qaTag+'-input'"
                (matChipInputTokenEnd)="Add($event)"
                (change)="$event.stopPropagation()"
                (blur)="Clear()">
                
        </mat-chip-grid>

        <mat-autocomplete
            #auto="matAutocomplete"
            (optionSelected)="Selected($event)"
            (opened)="PanelOpened()">

                <ng-container *ngFor="let groupItems of filteredItemsByGroup | async | keyvalue: unsorted">
                    <ng-container *ngIf="groupItems.value.length">
                        <p class="select-group-name" *ngIf="groupItems.key !== null">{{groupItems.key.name}}</p>
                        <mat-option *ngFor="let item of groupItems.value" [value]="item" class="v3">
                            <ng-container *ngIf="autocomplete?.panelOpen ?? false">
                                <ng-container *ngTemplateOutlet="item.content"></ng-container>
                            </ng-container>
                            <lib-hint>
                                <ng-container>{{item.hintMessage}}</ng-container>
                            </lib-hint>
                        </mat-option>
                    </ng-container>
                </ng-container>
        </mat-autocomplete>
    </div>


</lib-form-field-container>
