import { Injectable } from "@angular/core";
import { EventColorEnum } from "@library/data-models";

@Injectable({
    providedIn: 'root'
})

export class StyleManager {
    private _eventColorClassMapping = {
        [EventColorEnum.Beach]: 'beach',
        [EventColorEnum.Black]: 'black',
        [EventColorEnum.Blue]: 'blue',
        [EventColorEnum.Brown]: 'brown',
        [EventColorEnum.DarkOrange]: 'darkorange',
        [EventColorEnum.Gray]: 'gray',
        [EventColorEnum.Green]: 'green',
        [EventColorEnum.GrassGreen]: 'grassgreen',
        [EventColorEnum.Lavender]: 'lavender',
        [EventColorEnum.LightGrey]: 'lightgrey',
        [EventColorEnum.LightPink]: 'lightpink',
        [EventColorEnum.Lime]: 'lime',
        [EventColorEnum.Magenta]: 'magenta',
        [EventColorEnum.Mint]: 'mint',
        [EventColorEnum.Olive]: 'olive',
        [EventColorEnum.Orange]: 'orange',
        [EventColorEnum.Pink]: 'pink',
        [EventColorEnum.Purple]: 'purple',
        [EventColorEnum.Red]: 'red',
        [EventColorEnum.Royal]: 'royal',
        [EventColorEnum.Ruby]: 'ruby',
        [EventColorEnum.Salmon]: 'salmon',
        [EventColorEnum.SeaBlue]: 'seablue',
        [EventColorEnum.Tan]: 'tan',
        [EventColorEnum.Tangerine]: 'tangerine',
        [EventColorEnum.Teal]: 'teal',
        [EventColorEnum.Yellow]: 'yellow',
        [EventColorEnum.White]: 'white'
    }

    GetColorName(color: EventColorEnum): string {
        switch(color) {
            case EventColorEnum.Beach:
            return $localize`:@@CommonColorBeach:Beach`;
            case EventColorEnum.Black:
            return $localize`:@@CommonColorBlack:Black`;
            case EventColorEnum.Blue:
            return $localize`:@@CommonColorBlue:Blue`;
            case EventColorEnum.Brown:
            return $localize`:@@CommonColorBrown:Brown`;
            case EventColorEnum.DarkOrange:
            return $localize`:@@CommonColorDarkOrange:Dark Orange`;
            case EventColorEnum.GrassGreen:
            return $localize`:@@CommonColorGrassGreen:Grass Green`;
            case EventColorEnum.Gray:
            return $localize`:@@CommonColorGray:Gray`;
            case EventColorEnum.Green:
            return $localize`:@@CommonColorGreen:Green`;
            case EventColorEnum.Lavender:
            return $localize`:@@CommonColorLavender:Lavender`;
            case EventColorEnum.LightGrey:
            return $localize`:@@CommonColorLightGrey:Light Grey`;
            case EventColorEnum.LightPink:
            return $localize`:@@CommonColorLightPink:Light Pink`;
            case EventColorEnum.Lime:
            return $localize`:@@CommonColorLime:Lime`;
            case EventColorEnum.Magenta:
            return $localize`:@@CommonColorMagenta:Magenta`;
            case EventColorEnum.Mint:
            return $localize`:@@CommonColorMint:Mint`;
            case EventColorEnum.Olive:
            return $localize`:@@CommonColorOlive:Olive`;
            case EventColorEnum.Orange:
            return $localize`:@@CommonColorOrange:Orange`;
            case EventColorEnum.Pink:
            return $localize`:@@CommonColorPink:Pink`;
            case EventColorEnum.Purple:
            return $localize`:@@CommonColorPurple:Purple`;
            case EventColorEnum.Red:
            return $localize`:@@CommonColorRed:Red`;
            case EventColorEnum.Royal:
            return $localize`:@@CommonColorRoyal:Royal`;
            case EventColorEnum.Ruby:
            return $localize`:@@CommonColorRuby:Ruby`;
            case EventColorEnum.Salmon:
            return $localize`:@@CommonColorSalmon:Salmon`;
            case EventColorEnum.SeaBlue:
            return $localize`:@@CommonColorSeaBlue:Sea Blue`;
            case EventColorEnum.Tan:
            return $localize`:@@CommonColorTan:Tan`;
            case EventColorEnum.Tangerine:
            return $localize`:@@CommonColorTangerine:Tangerine`;
            case EventColorEnum.Teal:
            return $localize`:@@CommonColorTeal:Teal`;
            case EventColorEnum.White:
            return $localize`:@@CommonColorWhite:White`;
            case EventColorEnum.Yellow:
            return $localize`:@@CommonColorYellow:Yellow`;
        }
    }

    GetColorCssClass(color: EventColorEnum): string {
        return 'color-' + this._eventColorClassMapping[color];
    }

    GetDarkColorCssClass(color: EventColorEnum): string {
        return 'color-dark-' + this._eventColorClassMapping[color];
    }
    GetBackgroundColorCssClass(color: EventColorEnum): string {
        return 'background-' + this._eventColorClassMapping[color];
    }
    GetBackgroundInverseColorCssClass(color: EventColorEnum): string {
        return 'background-inverse-color-' + this._eventColorClassMapping[color];
    }
    GetBorderColorCssClass(color: EventColorEnum): string {
        return 'border-color-' + this._eventColorClassMapping[color];
    }
    GetCSSPropertyValue(property: string): string {
        return  getComputedStyle(document.body).getPropertyValue(property).trim();
    }
    SetCSSPropertyValue(property: string, value: string): void {
       document.documentElement.style.setProperty(property, value);
    }

    GetRealBackgroundColor(elem: HTMLElement | null): string {
        const transparent = 'rgba(0, 0, 0, 0)';
        if (!elem) {
            return transparent;
        }
        var backgroundColor = getComputedStyle(elem).backgroundColor;

        if (backgroundColor === transparent) {
            return this.GetRealBackgroundColor(elem.parentElement);
        } else {
            return backgroundColor;
        }
    }
}
