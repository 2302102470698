import { HttpClient, HttpErrorResponse, HttpEvent, HttpEventType, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiError, ApiProgressSubject, ApiSubject, BaseViewComponent, Environment, IApiRequest, IRequestOptions, RequestKind } from '@library/base';
import { Observable, Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ApiRequestManager {

    protected static _disabled = false;

    constructor(
        private _HttpClient: HttpClient,
        private _Environment: Environment) {
    }

    public Request<Body, Result, TrackBy extends IRequestOptions<Body, any, any>>(request: IApiRequest<Body>, subject: ApiSubject<Result, TrackBy>, progressSubject?: ApiProgressSubject<TrackBy>,
                                            trackBy = {} as TrackBy, useRawURL: boolean = false, ignoreTransitionAnimations: boolean = false): () => void {
        if (ApiRequestManager._disabled) {
            console.warn('ApiRequestManager disabled - ignoring request', request);
            return () => {};
        }

        let httpParams = request.HttpParams ?? new HttpParams();
        //For the TP, we always use the raw URL because the TP has an interceptor that adds the Base URL, the or can be removed once the TP is using the new style routes everywhere
        let url = (useRawURL || this._Environment.Authentication?.PrimaryToken === 'TeacherPortalAuth')
                ? request.URL 
                : this._Environment.Application.BaseUrls.Api + '/' + request.URL;
        let subscription: Subscription;

        // Need to duplicate the code below because of the weird way HttpClient.get/post/... use types

        if (progressSubject) {
            const httpOptions = this.HttpEventsOptions(httpParams);

            let observable: Observable<HttpEvent<Result>>;
            switch (request.Kind) {
                case RequestKind.Get: observable = this._HttpClient.get<Result>(url, httpOptions); break;
                case RequestKind.Post: observable = this._HttpClient.post<Result>(url, request.Body, httpOptions); break;
                case RequestKind.Patch: observable = this._HttpClient.patch<Result>(url, request.Body, httpOptions); break;
                case RequestKind.Put: observable = this._HttpClient.put<Result>(url, request.Body, httpOptions); break;
                case RequestKind.Delete: observable = this._HttpClient.request<Result>('delete', url, { body: request.Body, ...httpOptions }); break;
            }

            subscription = observable.subscribe({
                next: (data: HttpEvent<Result>) => {
                    if (!ApiRequestManager._disabled) {
                        BaseViewComponent.transitionAnimationEnd.subscribe(() => {
                            if (data.type === HttpEventType.UploadProgress || data.type === HttpEventType.DownloadProgress) {
                                progressSubject.next({ Request: trackBy, Response: data });
                            }
                            if (data.type === HttpEventType.Response && data.body != null) {
                                subject.next({ Request: trackBy, Response: data.body });
                            }
                        });
                    }
                },
                error: (error: HttpErrorResponse) => {
                    if (!ApiRequestManager._disabled) {
                        subject.error(new ApiError({ Request: trackBy, Status: error.status, Message: error.statusText, Response: error.error }));
                    }
                }
            });
        } else {
            const httpOptions = this.HttpResponseOptions(httpParams);

            let observable: Observable<HttpResponse<Result>>;
            switch (request.Kind) {
                case RequestKind.Get: observable = this._HttpClient.get<Result>(url, httpOptions); break;
                case RequestKind.Post: observable = this._HttpClient.post<Result>(url, request.Body, httpOptions); break;
                case RequestKind.Patch: observable = this._HttpClient.patch<Result>(url, request.Body, httpOptions); break;
                case RequestKind.Put: observable = this._HttpClient.put<Result>(url, request.Body, httpOptions); break;
                case RequestKind.Delete: observable = this._HttpClient.request<Result>('delete', url, { body: request.Body, ...httpOptions }); break;
            }

            subscription = observable.subscribe({
                next: (data: HttpResponse<Result>) => {
                    if (!ApiRequestManager._disabled) {
                        if (ignoreTransitionAnimations) {
                            if (data.body != null) {
                                subject.next({ Request: trackBy, Response: data.body });
                            }
                        } else {
                            BaseViewComponent.transitionAnimationEnd.subscribe(() => {
                                if (data.body != null) {
                                    subject.next({ Request: trackBy, Response: data.body });
                                }
                            });
                        }
                    }
                },
                error: (error: HttpErrorResponse) => {
                    if (!ApiRequestManager._disabled) {
                        subject.error(new ApiError({ Request: trackBy, Status: error.status, Message: error.statusText, Response: error.error }));
                    }
                }
            });
        }

        return () => subscription.unsubscribe();
    }

    private HttpResponseOptions(httpParams: HttpParams) {
        return {
            observe: 'response' as 'response',
            params: httpParams,
            reportProgress: false as false,
            responseType: 'json' as 'json',
            withCredentials: false as false
        };
    }

    private HttpEventsOptions(httpParams: HttpParams) {
        return {
            observe: 'events' as 'events',
            params: httpParams,
            reportProgress: true as true,
            responseType: 'json' as 'json',
            withCredentials: false as false
        };
    }

    public static Disable() {
        this._disabled = true;
    }

    public static Enable() {
        this._disabled = false;
    }

    public static get disabled(): boolean {
        return this._disabled;
    }
}