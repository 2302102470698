<div [matTooltip]="toolTipText" matTooltipPosition="below">
    <lib-button #baseButton *ngIf="mode != MenuButtonMode.TextOnly && mode != MenuButtonMode.IconOnly"
    [size]="size"
    [kind]="kind"
    [disabled]="disabled"
    [prefixIcon]="prefixIcon"
    [prefixIconSize]="prefixIconSize"
    [suffixIcon]="suffixIcon"
    [suffixIconSize]="suffixIconSize"
    [iconOnly]="iconOnly"
    (clicked)="OpenMenu()"
    (menuClosed)="MenuClosed()"
    [menuTrigger]="optionsMenu"
    [badgeText]="badgeText"
    [ngClass]="{'hiddenMenu': disabled}"
    target="_blank"
    [qaTag]="qaTag">
    <ng-container *ngTemplateOutlet="labelTemplate" label></ng-container>
    </lib-button>
    <button *ngIf="mode == MenuButtonMode.TextOnly" class="remove-default-button-style" [matMenuTriggerFor]="optionsMenu" #optionsMenuTrigger = "matMenuTrigger" mat-button>
      <ng-container *ngTemplateOutlet="buttonContentTemplate"></ng-container>
    </button>
    <lib-icon-button #baseButton 
      *ngIf="mode == MenuButtonMode.IconOnly"
      [icon]="prefixIcon!"
      [disabled]="disabled"
      [size]="size"
      [kind]="kind"
      [menuTrigger]="optionsMenu"
      [ngClass]="{'hiddenMenu': disabled}"
      target="_blank"
      (menuClosed)="MenuClosed()"
      (clicked)="OpenMenu()">
    </lib-icon-button>
</div>

<mat-menu #optionsMenu="matMenu" yPosition="below" [overlapTrigger]="false" backdropClass="mat-menu-custom-backdrop" [class]="customClass" >
    <ng-container [ngSwitch]="mode">
      <ng-container *ngSwitchCase="MenuButtonMode.Custom">
        <ng-container *ngTemplateOutlet="menuContent"></ng-container>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <ng-container *ngFor="let item of menuItemComponents">
          <div class="v3" [attr.data-sb-qa]="item.qaTag">
            <mat-divider *ngIf="item.menuDivider === MenuDivider.Above" class="pb-2 mt-2"></mat-divider>
            <div class="ps-3 menu-item-header typography-body-medium-strong">
              <ng-container *ngTemplateOutlet="item.menuItemHeader"></ng-container>
            </div>
          
            <button *ngIf="!item.hasSubOptions && !item.urlLink" [matTooltip]="item.toolTipText" matTooltipPosition="after"
                [ngClass]="{'disable-menu-item': item.disabled,'menu-item-default': !item.hint, 'menu-item-with-hint': item.hint}"
                mat-menu-item (click)="MenuItemClicked(item, $event)">
                <ng-container *ngTemplateOutlet="menuItem; context:{$implicit:item}"></ng-container>
            </button>
            <a class="link-menu-item" *ngIf="!item.hasSubOptions && item.urlLink" [matTooltip]="item.toolTipText" matTooltipPosition="after"
                [ngClass]="{'disable-menu-item': item.disabled,'menu-item-default': !item.hint, 'menu-item-with-hint': item.hint}"
                mat-menu-item [href]="item.urlLink"   routerLinkActive="active"
                target="_blank" (click)="MenuItemClicked(item, $event)"  onclick="return false">
                <ng-container *ngTemplateOutlet="menuItem; context:{$implicit:item}"></ng-container>
            </a>
            <button *ngIf="item.hasSubOptions" [matMenuTriggerFor]="item.hasSubOptions ? optionsSubMenu : null"
              [matTooltip]="item.toolTipText" matTooltipPosition="after"
              [ngClass]="{'disable-menu-item': item.disabled, 'menu-item-default': !item.hint, 'menu-item-with-hint': item.hint}"
              mat-menu-item (menuOpened)="OpenMenuItem(item)"
              (click)="MenuItemClicked(item, $event)">
              <ng-container *ngTemplateOutlet="menuItem; context:{$implicit:item}"></ng-container>
            </button>
            <mat-divider *ngIf="item.menuDivider === MenuDivider.Below" class="pt-2 mt-2"></mat-divider>
          </div>
        </ng-container>
        </ng-container>
    </ng-container>
</mat-menu>

<mat-menu #optionsSubMenu="matMenu">
  <ng-container *ngFor="let subMenuItem of subMenuItems">
    <div class="v3">
      <ng-container *ngTemplateOutlet="subMenuItem.menuItemHeader"></ng-container>
      <mat-divider *ngIf="subMenuItem.menuDivider === MenuDivider.Above"
        class="pb-2 mt-2"></mat-divider>
      <button [matTooltip]="subMenuItem.toolTipText" matTooltipPosition="after"
        [ngClass]="{'disable-menu-item': subMenuItem.disabled,'menu-item-default': !subMenuItem.hint, 'menu-item-with-hint': subMenuItem.hint}"
        mat-menu-item
        (click)="MenuItemClicked(subMenuItem, $event)">
        <ng-container *ngTemplateOutlet="menuItem; context:{$implicit:subMenuItem}"></ng-container>
      </button>
      <mat-divider *ngIf="subMenuItem.menuDivider === MenuDivider.Below"
        class="pt-2 mt-2"></mat-divider>
    </div>
  </ng-container>
</mat-menu>

<ng-template #labelTemplate>
    <div class="d-flex align-items-baseline" [ngClass]="{'justify-content-center': size == ButtonSize.FullWidth}">
        <ng-content *ngIf="!iconOnly" select="[label]"></ng-content>
    </div>
</ng-template>

<ng-template #menuItem let-item>
  <div *ngIf="item.label" class="d-flex align-items-center h-100 my-auto" [ngClass]="{'menu-item-under-group': item.hasGroupHeader}">
    <lib-icon *ngIf="item.prefixIcon" [icon]="item.prefixIcon" [size]="IconSize.ExtraSmall" [visible]="item.prefixIconVisible" class="pe-2"></lib-icon>
    <div >
      <p class="typography-input">{{item.label}}</p>
      <caption *ngIf="item.hint">{{item.hint}}</caption>
    </div>
    <lib-icon *ngIf="item.suffixIcon" [icon]="item.suffixIcon" [size]="IconSize.ExtraSmall" [visible]="item.suffixIconVisible" class="ps-2"></lib-icon>
  </div>
  <div *ngIf="!item.label" class="d-flex align-items-center h-100 my-auto" [ngClass]="{'menu-item-under-group': item.hasGroupHeader}">
    <ng-container *ngTemplateOutlet="item.menuItemContent"></ng-container>
  </div>
</ng-template>
