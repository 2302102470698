import { FroalaOptions } from 'froala-editor';
import FroalaEditor from 'froala-editor';

export enum FroalaSize {
    Small = "scss-fr-sm",
    Medium = "scss-fr-md",
    Default = "scss-fr-default"
}

export enum FroalaGalleryMode {
    Email,
    Website,
    None
}

FroalaEditor.DefineIcon('specialCharactersIcon', { NAME: 'specialCharacters', SVG_KEY: 'smile'});
FroalaEditor.DefineIcon('placehodlersIcon', { NAME: 'percent', SVG_KEY: 'tags'});


export const froalaBaseOptions: Partial<FroalaOptions> = {
    key: '9KH3cC4D5D4F3E3C2zPAENHMf1JPQRFZBTBf1WWEPYDbB3H3E2A14A19B7C5C6D3D1==',
    toolbarInline: false,
    htmlAllowComments: false,
    enter: FroalaEditor.ENTER_P // this is to remove the tags when we retrieve only the content from Froala or else the strings will always be attached like this <p> content </p>
};

export const froalaTextButtons = ['bold', 'italic', 'underline', 'textColor', 'strikeThrough', 'fontSize', 'fontFamily', 'backgroundColor', 'clearFormatting'];
export const froalaParagraphButtons = ['paragraphFormat', 'align', 'formatOL', 'formatUL', 'indent', 'outdent', 'quote'];
export const froalaRichButtons = ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'insertHR'];
export const froalaEmailTemplateRichButtons = ['emailPlaceholderDropdown', 'insertLink', 'insertImage', 'insertTable', 'insertHR'];
export const froalaAttendanceRichButtons = ['insertLink', 'insertTable', 'insertHR'];
export const froalaAttendanceMiscButtons = ['undo', 'redo', 'selectAll'];
export const froalaMiscButtons = ['undo', 'redo', 'selectAll', 'html'];


const placeholderButtonOnly = {
    moreText: {
        buttons: ['emailPlaceholderDropdown'],
        buttonsVisible: 1
    }
}

export const froalaDrawerTextOptionsDefault: Partial<FroalaOptions> = {
    ... froalaBaseOptions,
    placeholderText: '',
    charCounterCount: false,
    theme: 'gray',
    toolbarButtons:  {
        moreText: {
            buttons: froalaTextButtons,
            buttonsVisible: 3
        },
        moreParagraph: {
            buttons: froalaParagraphButtons,
            buttonsVisible: 3,

        },
        moreRich: {
            buttons: froalaRichButtons,
            buttonsVisible: 3
        },
        moreMisc: {
            buttons: froalaMiscButtons,
            align: 'right',
            buttonsVisible: 2
        },
    },
    toolbarButtonsXS:{
        moreText: {
            buttons: froalaTextButtons,
            buttonsVisible: 0
        },
        moreParagraph: {
            buttons: froalaParagraphButtons,
            buttonsVisible: 0

        },
        moreRich: {
            buttons: froalaRichButtons,
            buttonsVisible: 0
        },
        moreMisc: {
            buttons: froalaMiscButtons,
            align: 'right',
            buttonsVisible: 2
        },

    },
    toolbarButtonsSM: {
        moreText: {
            buttons: froalaTextButtons,
            buttonsVisible: 2
        },
        moreParagraph: {
            buttons: froalaParagraphButtons,
            buttonsVisible: 2,

        },
        moreRich: {
            buttons: froalaRichButtons,
            buttonsVisible: 2
        },
        moreMisc: {
            buttons: froalaMiscButtons,
            align: 'right',
            buttonsVisible: 2
        },
    },
    toolbarButtonsMD: {
        moreText: {
            buttons: froalaTextButtons,
            buttonsVisible: 3
        },
        moreParagraph: {
            buttons: froalaParagraphButtons,
            buttonsVisible: 3,

        },
        moreRich: {
            buttons: froalaRichButtons,
            buttonsVisible: 3
        },
        moreMisc: {
            buttons: froalaMiscButtons,
            align: 'right',
            buttonsVisible: 2
        },
    
    },
    quickInsertEnabled: false,
    shortcutsEnabled: ['bold', 'italic', 'underline', 'strikeThrough'],
    useClasses: false,
    pastePlain: false,
    wordPasteKeepFormatting: true,
    attribution: false,
    zIndex: 2501,
    imageInsertButtons: ['imageByURL'],
    videoInsertButtons: ['videoByURL', 'videoEmbed']
}

export const froalaAttendanceDrawerTextOptionsDefault: Partial<FroalaOptions> = {
    ... froalaBaseOptions,
    placeholderText: '',
    charCounterCount: false,
    theme: 'gray',
    toolbarButtons:  {
        moreText: {
            buttons: froalaTextButtons,
            buttonsVisible: 3
        },
        moreParagraph: {
            buttons: froalaParagraphButtons,
            buttonsVisible: 3,

        },
        moreRich: {
            buttons: froalaAttendanceRichButtons,
            buttonsVisible: 3
        },
        moreMisc: {
            buttons: froalaAttendanceMiscButtons,
            align: 'right',
            buttonsVisible: 2
        },
    },
    toolbarButtonsXS:{
        moreText: {
            buttons: froalaTextButtons,
            buttonsVisible: 0
        },
        moreParagraph: {
            buttons: froalaParagraphButtons,
            buttonsVisible: 0

        },
        moreRich: {
            buttons: froalaAttendanceRichButtons,
            buttonsVisible: 0
        },
        moreMisc: {
            buttons: froalaAttendanceMiscButtons,
            align: 'right',
            buttonsVisible: 2
        },

    },
    toolbarButtonsSM: {
        moreText: {
            buttons: froalaTextButtons,
            buttonsVisible: 2
        },
        moreParagraph: {
            buttons: froalaParagraphButtons,
            buttonsVisible: 2,

        },
        moreRich: {
            buttons: froalaAttendanceRichButtons,
            buttonsVisible: 2
        },
        moreMisc: {
            buttons: froalaAttendanceMiscButtons,
            align: 'right',
            buttonsVisible: 2
        },
    },
    toolbarButtonsMD: {
        moreText: {
            buttons: froalaTextButtons,
            buttonsVisible: 3
        },
        moreParagraph: {
            buttons: froalaParagraphButtons,
            buttonsVisible: 3,

        },
        moreRich: {
            buttons: froalaAttendanceRichButtons,
            buttonsVisible: 3
        },
        moreMisc: {
            buttons: froalaAttendanceMiscButtons,
            align: 'right',
            buttonsVisible: 2
        },
    
    },
    quickInsertEnabled: false,
    shortcutsEnabled: ['bold', 'italic', 'underline', 'strikeThrough'],
    useClasses: false,
    pastePlain: false,
    wordPasteKeepFormatting: true,
    attribution: false,
    zIndex: 2501,

}

export const froalaTextOptionsDefault: Partial<FroalaOptions> = {
    ... froalaBaseOptions,
    placeholderText: '',
    charCounterCount: false,
    theme: 'gray',
    toolbarButtons:  {
        moreText: {
            buttons: froalaTextButtons,
            buttonsVisible: 4
        },
        moreParagraph: {
            buttons: froalaParagraphButtons,
            buttonsVisible: 4,

        },
        moreRich: {
            buttons: froalaRichButtons,
            buttonsVisible: 4
        },
        moreMisc: {
            buttons: froalaMiscButtons,
            align: 'right',
            buttonsVisible: 2
        },
    },
    toolbarButtonsXS:{
        moreText: {
            buttons: froalaTextButtons,
            buttonsVisible: 0
        },
        moreParagraph: {
            buttons: froalaParagraphButtons,
            buttonsVisible: 0

        },
        moreRich: {
            buttons: froalaRichButtons,
            buttonsVisible: 0
        },
        moreMisc: {
            buttons: froalaMiscButtons,
            align: 'right',
            buttonsVisible: 2
        },

    },
    toolbarButtonsSM: {
        moreText: {
            buttons: froalaTextButtons,
            buttonsVisible: 2
        },
        moreParagraph: {
            buttons: froalaParagraphButtons,
            buttonsVisible: 2,

        },
        moreRich: {
            buttons: froalaRichButtons,
            buttonsVisible: 2
        },
        moreMisc: {
            buttons: froalaMiscButtons,
            align: 'right',
            buttonsVisible: 2
        },
    },
    toolbarButtonsMD: {
        moreText: {
            buttons: froalaTextButtons,
            buttonsVisible: 3
        },
        moreParagraph: {
            buttons: froalaParagraphButtons,
            buttonsVisible: 3,

        },
        moreRich: {
            buttons: froalaRichButtons,
            buttonsVisible: 3
        },
        moreMisc: {
            buttons: froalaMiscButtons,
            align: 'right',
            buttonsVisible: 2
        },
    
    },
    quickInsertEnabled: false,
    shortcutsEnabled: ['bold', 'italic', 'underline', 'strikeThrough'],
    useClasses: false,
    pastePlain: false,
    wordPasteKeepFormatting: true,
    attribution: false,
    zIndex: 2501,
    imageInsertButtons: ['imageByURL'],
    videoInsertButtons: ['videoByURL', 'videoEmbed']
}

export const froalaTextOptionsNoToolBar: Partial<FroalaOptions> = {
    ... froalaTextOptionsDefault,
    toolbarButtons: [],
}

export const froalaSMSOptions: Partial<FroalaOptions> = {
    ... froalaBaseOptions,
    placeholderText: '',
    charCounterCount: true,
    theme: 'gray',
    quickInsertEnabled: false,
    pastePlain: true,
    pasteAllowLocalImages: false,
    imagePaste: false,
    linkInsertButtons: [],
    linkEditButtons: [],
    linkConvertEmailAddress: false,
    linkAlwaysNoFollow: true,
    linkText: false,
    htmlAllowedTags: [],
    htmlAllowedAttrs: [],
    htmlAllowComments: false,
    htmlAllowedStyleProps: [],
    shortcutsEnabled: [],
    toolbarButtons: placeholderButtonOnly,
    toolbarButtonsMD: placeholderButtonOnly,
    toolbarButtonsSM: placeholderButtonOnly,
    toolbarButtonsXS: placeholderButtonOnly
}

export const froalaTemplateOptions: Partial<FroalaOptions> = {
    ...froalaTextOptionsDefault,
    toolbarButtons:  {
        moreText: {
            buttons: froalaTextButtons,
            buttonsVisible: 3
        },
        moreParagraph: {
            buttons: froalaParagraphButtons,
            buttonsVisible: 3,

        },
        moreRich: {
            buttons: froalaEmailTemplateRichButtons,
            buttonsVisible: 3
        },
        moreMisc: {
            buttons: froalaMiscButtons,
            align: 'right',
            buttonsVisible: 2
        },
    },
    toolbarButtonsXS:{
        moreText: {
            buttons: froalaTextButtons,
            buttonsVisible: 0
        },
        moreParagraph: {
            buttons: froalaParagraphButtons,
            buttonsVisible: 0

        },
        moreRich: {
            buttons: froalaEmailTemplateRichButtons,
            buttonsVisible: 0
        },
        moreMisc: {
            buttons: froalaMiscButtons,
            align: 'right',
            buttonsVisible: 2
        },

    },
    toolbarButtonsSM: {
        moreText: {
            buttons: froalaTextButtons,
            buttonsVisible: 2
        },
        moreParagraph: {
            buttons: froalaParagraphButtons,
            buttonsVisible: 2,

        },
        moreRich: {
            buttons: froalaEmailTemplateRichButtons,
            buttonsVisible: 2
        },
        moreMisc: {
            buttons: froalaMiscButtons,
            align: 'right',
            buttonsVisible: 2
        },
    },
    toolbarButtonsMD: {
        moreText: {
            buttons: froalaTextButtons,
            buttonsVisible: 3
        },
        moreParagraph: {
            buttons: froalaParagraphButtons,
            buttonsVisible: 3,

        },
        moreRich: {
            buttons: froalaEmailTemplateRichButtons,
            buttonsVisible: 3
        },
        moreMisc: {
            buttons: froalaMiscButtons,
            align: 'right',
            buttonsVisible: 2
        },
    
    },
}