import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpansionCardComponent } from './expansion-card.component';
import { ButtonModule } from '@library/button';
import { MatExpansionModule } from "@angular/material/expansion";
import { ExpansionCardGroupComponent } from './group/expansion-card-group.component';

@NgModule({
    declarations: [
        ExpansionCardComponent,
        ExpansionCardGroupComponent
    ],
    imports: [
        CommonModule,
        ButtonModule,
        MatExpansionModule
    ],
    exports: [
        ExpansionCardComponent,
        ExpansionCardGroupComponent
    ]
})
export class ExpansionCardModule { }
