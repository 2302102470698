import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { ApiBaseRoutes } from '@library/api';
import { photoGalleryCarouselMaxItems, WebsiteBuildingBlockEmbed, WebsiteBuildingBlockMap, WebsiteGalleryItemAction } from '@library/base';
import { WebsiteBlockImageIndexItem } from '@library/base';
import { BaseViewComponent, ContactWidgetBlockPlaceholderText, LoginWidgetBlockPlaceholderText, SignupWidgetBlockPlaceholderText, TextMediaBlockPlaceholderText, WEBSITE_PREFIX_ID, WebsiteBlockTypeData, WebsiteBlockWidgetType, WebsiteBuildingBlock, WebsiteBuildingBlockBlog, WebsiteBuildingBlockContactWidget, WebsiteBuildingBlockHTML, WebsiteBuildingBlockKind, WebsiteBuildingBlockLoginWidget, WebsiteBuildingBlockSignupWidget, WebsiteBuildingBlockTextAndMedia, WebsiteBuildingBlockWidget, WebsiteMode, defaultContactFormThankYouMessage, defaultSignupFormThankYouMessage } from '@library/base';
import { WebsiteBlockEmbedDisplayItem } from '@library/data-models';
import { WebsiteBlockEmbedContentType } from '@library/data-models';
import { CustomFieldEmailDisplayItem, CustomFieldMultiLineDisplayItem, CustomFieldTextDisplayItem, WebsiteBlockBlogDisplayItem, WebsiteBlockContactFormDisplayItem, WebsiteBlockContactFormType, WebsiteBlockDisplayItem, WebsiteBlockLoginFormDisplayItem, WebsiteBlockLoginFormType, WebsiteBlockSignupFormDisplayItem, WebsiteBlockSignupFormType, WebsiteBlockTextAndMediaDisplayItem, WebsiteBlockTextAndMediaImageDisplayItem, WebsiteBlockTextAndMediaType, WebsiteBlockType, WebsiteCustomFieldContactFormDisplayItem, WebsiteDisplayItem, WebsiteMediaLibraryDisplayItem, WebsitePageDisplayItem, WebsiteRenderContextDisplayItem,  WebsiteTemplate } from '@library/data-models';
import { WebsiteManager } from '@library/managers';

const listAnimation = trigger('listAnimation', [
    transition('* <=> *', [
        query(':enter',
            [style({ opacity: 0 }), stagger('60ms', animate('600ms ease-out', style({ opacity: 1 })))],
            { optional: true }
        ),
        query(':leave',
            animate('200ms', style({ opacity: 0 })),
            { optional: true}
        )
    ])
]);

@Component({
    selector: 'lib-website-page',
    templateUrl: './website-page.component.html',
    styleUrls: ['./website-page.component.scss'],
    animations: [listAnimation]
})
export class WebsitePageComponent extends BaseViewComponent implements OnInit {

    @ContentChild('textMediaBlockHeader') textMediaBlockHeader!: TemplateRef<any>;
    @ContentChild('textMediaBlockBody') textMediaBlockBody!: TemplateRef<any>;
    @ContentChild('textMediaBlockImage') textMediaBlockImage!: TemplateRef<any>;
    @ContentChild('textMediaBlockImageHeader') textMediaBlockImageHeader!: TemplateRef<any>;
    @ContentChild('textMediaBlockImageBody') textMediaBlockImageBody!: TemplateRef<any>;
    @ContentChild('htmlBlock') htmlBlock!: TemplateRef<any>;

    private _blocks: WebsiteBlockDisplayItem[] | undefined = [];

    private _ID!: string;


    @Input()
    set ID (value: string) {
        this._ID = value;
        this.draw = false;
        this.UpdateBlocks();
    }

    @Input() renderContext!: WebsiteRenderContextDisplayItem;
    @Input() mode: WebsiteMode = WebsiteMode.Preview;

    private _websiteData!: WebsiteDisplayItem;
    @Input()
    set websiteData(value: WebsiteDisplayItem) {
        this._websiteData = value;
        this.UpdateBlocks();
    }

    @Output() pageChanged: EventEmitter<void> = new EventEmitter();
    @Output() openMediaDialog: EventEmitter<WebsiteBlockImageIndexItem> = new EventEmitter();
    @Output() openImageDetailsDialog: EventEmitter<WebsiteBlockImageIndexItem> = new EventEmitter();
    @Output() navigationPageClicked: EventEmitter<string> = new EventEmitter();
    @Output() editBlockClicked: EventEmitter<WebsiteBlockDisplayItem> = new EventEmitter();
    @Output() openMultiSelectMediaDialog: EventEmitter<WebsiteBlockTextAndMediaDisplayItem> = new EventEmitter();

    animate: boolean = false;
    draw: boolean = false;
    showFooter: boolean = true;

    private _headerLogoSource: string | null = null;
    private _uniqueID: number = 10000;

    private _teamSectionElementsNumber = 3;

    @Input()
    set headerLogoID(value: string) {
        this._headerLogoSource = null;
        this.GetImageURL(value);
    }

    constructor(private _WebsiteManager: WebsiteManager) {
        super();
    }

    override ngOnInit(): void {
        super.ngOnInit();
    }

    UpdateBlocks(): void {
        this._blocks = this._websiteData ? this.GetPage(this._ID, this.websiteData.WebsitePages)?.WebsiteBlocks : [];
        this.NormalizeOrderIndex();

        setTimeout(() => {
            this.animate = true;
            this.draw = true;
        });
    }

    MoveUpClicked(block: WebsiteBlockDisplayItem): void {
        this.MoveBlock(block, -1);
    }

    MoveDownClicked(block: WebsiteBlockDisplayItem): void {
        this.MoveBlock(block, 1);
    }

    DeleteClicked(block: WebsiteBlockDisplayItem): void {
        this._blocks = this._blocks!.filter(b => b.OrderIndex !== block.OrderIndex);
        this.NormalizeOrderIndex();
        this.GetPage(this._ID, this.websiteData.WebsitePages)!.WebsiteBlocks = this._blocks;
        this.pageChanged.emit();
        this.animate = true;
    }

    EditClicked(block: WebsiteBlockDisplayItem): void {
        this.editBlockClicked.emit(block);
    }

    AddItemClicked(block: WebsiteBlockDisplayItem, addAtIndex?: number): void{
        if(WebsiteBlockDisplayItem.IsTextAndMedia(block)){
            switch(block.TextAndMediaType){
                case WebsiteBlockTextAndMediaType.PhotoGalleryCarousel:
                case WebsiteBlockTextAndMediaType.PhotoGalleryCarouselOverlay:
                    block.Images.splice(addAtIndex!,0, new WebsiteBlockTextAndMediaImageDisplayItem({GUID: this.uniqueID}));
                    break;
                case WebsiteBlockTextAndMediaType.GalleryCircleAccentColumn:
                case WebsiteBlockTextAndMediaType.GalleryCircleCardColumn:
                case WebsiteBlockTextAndMediaType.GalleryCircleColumn:
                case WebsiteBlockTextAndMediaType.GallerySquareAccentColumn:
                case WebsiteBlockTextAndMediaType.GallerySquareCardColumn:
                case WebsiteBlockTextAndMediaType.GallerySquareColumn:
                    block.Images.push(new WebsiteBlockTextAndMediaImageDisplayItem({GUID: this.uniqueID, Header: $localize`:@@TPWebsiteTextMediaBlock:Heading`, Body: 'Lorem ipsum dolor sit amet consectetur.'}));
                    break;
            }
            this.pageChanged.emit();
        }
    }

    RemoveItemClicked(block: WebsiteBlockDisplayItem, removeAtIndex: number = -1): void{
        if (WebsiteBlockDisplayItem.IsTextAndMedia(block)){
            switch(block.TextAndMediaType){
                case WebsiteBlockTextAndMediaType.GalleryCircleAccentColumn:
                case WebsiteBlockTextAndMediaType.GalleryCircleCardColumn:
                case WebsiteBlockTextAndMediaType.GalleryCircleColumn:
                case WebsiteBlockTextAndMediaType.GallerySquareAccentColumn:
                case WebsiteBlockTextAndMediaType.GallerySquareCardColumn:
                case WebsiteBlockTextAndMediaType.GallerySquareColumn:
                    if (removeAtIndex >= 0) {
                        block.Images.splice(removeAtIndex, 1);
                    } else if (block.Images.length > 1){
                        block.Images.splice(block.Images.length - 1, 1);
                    }
            }
            this.pageChanged.emit();
        }
    }

    SwapItemClicked(block: WebsiteBlockDisplayItem, swapIndex: number = -1, isLeft: boolean): void{
        if (WebsiteBlockDisplayItem.IsTextAndMedia(block)){
            switch(block.TextAndMediaType){
                case WebsiteBlockTextAndMediaType.PhotoGalleryCarousel:
                case WebsiteBlockTextAndMediaType.PhotoGalleryCarouselOverlay:
                    // calculate swap destination
                    let destination = isLeft ? swapIndex - 1 : swapIndex + 1
                    if (isLeft && swapIndex == 0 || !isLeft && swapIndex == block.Images.length - 1) {
                        destination = isLeft ? block.Images.length - 1 : 0;
                    }
                    // perform swap
                    if (swapIndex >= 0 && swapIndex <= block.Images.length - 1) {
                        [block.Images[swapIndex], block.Images[destination]] = [block.Images[destination], block.Images[swapIndex]];
                    }
                    break;
                case WebsiteBlockTextAndMediaType.GalleryCircleAccentColumn:
                case WebsiteBlockTextAndMediaType.GalleryCircleCardColumn:
                case WebsiteBlockTextAndMediaType.GalleryCircleColumn:
                case WebsiteBlockTextAndMediaType.GallerySquareAccentColumn:
                case WebsiteBlockTextAndMediaType.GallerySquareCardColumn:
                case WebsiteBlockTextAndMediaType.GallerySquareColumn:
                    // perform the swap
                    if (isLeft && swapIndex == 0) {
                        const item = block.Images.shift();
                        if (item) {
                            block.Images.push(item);
                        }
                    } else if (!isLeft && swapIndex == block.Images.length - 1) {
                        const item = block.Images.pop();
                        if (item) {
                            block.Images.unshift(item);
                        }
                    } else if (swapIndex >= 0 && swapIndex <= block.Images.length - 1) {
                        const destination = isLeft ? swapIndex - 1 : swapIndex + 1;
                        [block.Images[swapIndex], block.Images[destination]] = [block.Images[destination], block.Images[swapIndex]];
                    }
            }
            this.pageChanged.emit();
        }
    }

    OpenMultiSelectMediaDialog(block: WebsiteBlockDisplayItem) {
        if (WebsiteBlockDisplayItem.IsTextAndMedia(block)) {
            this.openMultiSelectMediaDialog.emit(block);
        }
    }

    OpenMediaGalleryDialog(block: WebsiteBlockDisplayItem | WebsiteBlockContactFormDisplayItem | WebsiteBlockLoginFormDisplayItem, index: number) {
        if (WebsiteBlockDisplayItem.IsTextAndMedia(block) || WebsiteBlockDisplayItem.IsLoginForm(block) || WebsiteBlockDisplayItem.IsContactForm(block)) {
            const imageIndex: WebsiteBlockImageIndexItem = {
                ImageIndex: index,
                Block: block
            };
            this.openMediaDialog.emit(imageIndex);
        }
    }

    OpenImageDetailsDialog(block: WebsiteBlockDisplayItem | WebsiteBlockContactFormDisplayItem | WebsiteBlockLoginFormDisplayItem, index: number) {
        if (WebsiteBlockDisplayItem.IsTextAndMedia(block) || WebsiteBlockDisplayItem.IsLoginForm(block) || WebsiteBlockDisplayItem.IsContactForm(block)) {
            const imageIndex: WebsiteBlockImageIndexItem = {
                ImageIndex: index,
                Block: block
            };
            this.openImageDetailsDialog.emit(imageIndex);
        }
    }

    HandleImageClicked(block: WebsiteBlockDisplayItem | WebsiteBlockContactFormDisplayItem | WebsiteBlockLoginFormDisplayItem, index: number = -1, action: WebsiteGalleryItemAction): void {
        if (action == WebsiteGalleryItemAction.LeftSwap) {
            this.SwapItemClicked(block, index, true);
        } else if (action == WebsiteGalleryItemAction.RightSwap) {
            this.SwapItemClicked(block, index, false);
        } else if (action == WebsiteGalleryItemAction.Delete) {
            this.RemoveItemClicked(block, index)
        } else if (action == WebsiteGalleryItemAction.MediaGallery) {
            this.OpenMediaGalleryDialog(block, index);
        } else if (action == WebsiteGalleryItemAction.MediaDetails) {
            this.OpenImageDetailsDialog(block, index);
        }
    }

    TrackByID(index: number, item: WebsiteBlockDisplayItem) {
        return this.mode === WebsiteMode.Live ? item.ID : item.GUID;
    }

    TrackByImage(index: number, item: WebsiteBlockTextAndMediaImageDisplayItem) {
        return item.Url;
    }

    AnimationEnd(): void {
        this.animate = false;
        this.showFooter = true;
    }

    GoToSocialMediaSite(site: string): void {
        window.open(site,'_blank')
    }

    NavigationPageClicked(pageID: string | null): void {
        if(pageID !== this._ID){
            this.navigationPageClicked.emit(pageID!);
        }
    }

    IsPageParentOfViewableID(currentID: string, page: WebsitePageDisplayItem): boolean {
        return page.ChildPages.map((page)=> {return page.ID}).includes(currentID);
    }

    HasSocialLinks(item: WebsiteDisplayItem): boolean {
        return item.FacebookUrl !== null || item.TwitterUrl !== null || item.InstagramUrl !== null || item.LinkedInUrl !== null || item.YouTubeUrl !== null || item.TikTokUrl!== null;
    }

    Dropped(event: CdkDragDrop<WebsiteBuildingBlock, WebsiteBuildingBlock, WebsiteBuildingBlock>) {
        const droppedIndex: number = event.currentIndex;
        const buildingBlock: WebsiteBuildingBlock = event.item.data;
        let blockToAdd!: WebsiteBlockDisplayItem;
        switch(buildingBlock.kind) {
            case WebsiteBuildingBlockKind.TextAndMedia:
                const textAndMediaBuildingBlock = (buildingBlock as WebsiteBuildingBlockTextAndMedia);
                const header = textAndMediaBuildingBlock.hasHeader ? TextMediaBlockPlaceholderText[textAndMediaBuildingBlock.type as WebsiteBlockTextAndMediaType].header : null;
                const body = textAndMediaBuildingBlock.hasBody? TextMediaBlockPlaceholderText[textAndMediaBuildingBlock.type as WebsiteBlockTextAndMediaType].body: null;
                switch (textAndMediaBuildingBlock.type){
                    case WebsiteBlockTextAndMediaType.Default:
                    case WebsiteBlockTextAndMediaType.AccentBackgroundDefault:
                    case WebsiteBlockTextAndMediaType.HTML:
                    case WebsiteBlockTextAndMediaType.TwoColumn:
                    case WebsiteBlockTextAndMediaType.HeroNoImage:
                        blockToAdd = new WebsiteBlockTextAndMediaDisplayItem({
                            GUID: this.uniqueID,
                            Header: header,
                            Body: body,
                            TextAndMediaType: textAndMediaBuildingBlock.type,
                            OrderIndex: droppedIndex,
                            ID: this.uniqueID,
                            Images: []
                        });
                        break;
                    case WebsiteBlockTextAndMediaType.MediaLeft:
                    case WebsiteBlockTextAndMediaType.MediaRight:
                    case WebsiteBlockTextAndMediaType.MediaLeftClipped:
                    case WebsiteBlockTextAndMediaType.MediaRightClipped:
                    case WebsiteBlockTextAndMediaType.MediaLeftTwoColumn:
                    case WebsiteBlockTextAndMediaType.MediaRightTwoColumn:
                    case WebsiteBlockTextAndMediaType.MediaLeftShadow:
                    case WebsiteBlockTextAndMediaType.MediaRightShadow:
                    case WebsiteBlockTextAndMediaType.Testimonial:
                    case WebsiteBlockTextAndMediaType.AccentBackgroundTestimonial:
                    case WebsiteBlockTextAndMediaType.MediaRightAccentBackgroundTestimonial:
                    case WebsiteBlockTextAndMediaType.MediaRightTestimonial:
                    case WebsiteBlockTextAndMediaType.HeroBackgroundImage:
                    case WebsiteBlockTextAndMediaType.HeroSquareSideImage:
                    case WebsiteBlockTextAndMediaType.HeroClippedSideImage:
                    case WebsiteBlockTextAndMediaType.MediaLeftHeroClippedImage:
                    case WebsiteBlockTextAndMediaType.MediaLeftHeroSquareImage:
                        blockToAdd = new WebsiteBlockTextAndMediaDisplayItem({
                            GUID: this.uniqueID,
                            Header: header,
                            Body: body,
                            TextAndMediaType: textAndMediaBuildingBlock.type,
                            OrderIndex: droppedIndex,
                            ID: this.uniqueID,
                            Images: [new WebsiteBlockTextAndMediaImageDisplayItem({GUID: this.uniqueID})]
                        });
                        break;
                    case WebsiteBlockTextAndMediaType.GalleryCircleAccentColumn:
                    case WebsiteBlockTextAndMediaType.GalleryCircleCardColumn:
                    case WebsiteBlockTextAndMediaType.GalleryCircleColumn:
                    case WebsiteBlockTextAndMediaType.GallerySquareAccentColumn:
                    case WebsiteBlockTextAndMediaType.GallerySquareCardColumn:
                    case WebsiteBlockTextAndMediaType.GallerySquareColumn:
                        blockToAdd = new WebsiteBlockTextAndMediaDisplayItem({
                            GUID: this.uniqueID,
                            Header: header,
                            Body: null,
                            TextAndMediaType: textAndMediaBuildingBlock.type,
                            OrderIndex: droppedIndex,
                            ID: this.uniqueID,
                            Images: this.GetGalleryColumns(header, body),
                        });
                        break;
                    case WebsiteBlockTextAndMediaType.PhotoGalleryGrid:
                    case WebsiteBlockTextAndMediaType.PhotoGalleryCarousel:
                    case WebsiteBlockTextAndMediaType.PhotoGalleryCarouselOverlay:
                        blockToAdd = new WebsiteBlockTextAndMediaDisplayItem({
                            GUID: this.uniqueID,
                            Header: null,
                            Body: null,
                            TextAndMediaType: textAndMediaBuildingBlock.type,
                            OrderIndex: droppedIndex,
                            ID: this.uniqueID,
                            Images: this.GetPhotoGallery(textAndMediaBuildingBlock.type),
                        });
                        break;        
                }
                break;
            case WebsiteBuildingBlockKind.HTML:
                const htmlBuildingBlock = (buildingBlock as WebsiteBuildingBlockHTML);
                blockToAdd = new WebsiteBlockTextAndMediaDisplayItem({
                    GUID: this.uniqueID,
                    Header: null,
                    Body: TextMediaBlockPlaceholderText[htmlBuildingBlock.type as WebsiteBlockTextAndMediaType].body,
                    TextAndMediaType:htmlBuildingBlock.type as WebsiteBlockTextAndMediaType,
                    OrderIndex: droppedIndex,
                    ID: this.uniqueID,
                    Images: []
                });
                break;
            case WebsiteBuildingBlockKind.Hero:
                const heroBuildingBlock = (buildingBlock as WebsiteBuildingBlockTextAndMedia);
                blockToAdd = new WebsiteBlockTextAndMediaDisplayItem({
                    GUID: this.uniqueID,
                    Header: heroBuildingBlock.hasHeader ? TextMediaBlockPlaceholderText[heroBuildingBlock.type as WebsiteBlockTextAndMediaType].header : null,
                    Body: heroBuildingBlock.hasBody? TextMediaBlockPlaceholderText[heroBuildingBlock.type as WebsiteBlockTextAndMediaType].body: null,
                    TextAndMediaType: heroBuildingBlock.type as WebsiteBlockTextAndMediaType,
                    OrderIndex: droppedIndex,
                    ID: this.uniqueID,
                    Images: [new WebsiteBlockTextAndMediaImageDisplayItem({GUID: this.uniqueID})]
                });
                break;


            case WebsiteBuildingBlockKind.Widget:
                const widgetBuildingBlock = buildingBlock as WebsiteBuildingBlockWidget;
                switch(widgetBuildingBlock.type) {

                    case WebsiteBlockWidgetType.Contact:
                        const contactWidgetBuildingBlock = buildingBlock as WebsiteBuildingBlockContactWidget;
                        switch(contactWidgetBuildingBlock.contactType) {
                            case WebsiteBlockContactFormType.Default:
                            case WebsiteBlockContactFormType.AccentBackgroundDefault:
                                blockToAdd = new WebsiteBlockContactFormDisplayItem({
                                    GUID: this.uniqueID,
                                    Header: contactWidgetBuildingBlock.hasHeader ? ContactWidgetBlockPlaceholderText[contactWidgetBuildingBlock.contactType as WebsiteBlockContactFormType].header : null,
                                    Body: contactWidgetBuildingBlock.hasBody? ContactWidgetBlockPlaceholderText[contactWidgetBuildingBlock.contactType as WebsiteBlockContactFormType].body: null,
                                    ContactFormType: contactWidgetBuildingBlock.contactType,
                                    OrderIndex: droppedIndex,
                                    ID: this.uniqueID,
                                    Images: [],
                                    ThankYouMessage: defaultContactFormThankYouMessage,
                                    CustomFields: [
                                        new WebsiteCustomFieldContactFormDisplayItem({Name: $localize`:@@TPWebsiteContactWidgetDefaultFieldName:Name`, Field: new CustomFieldTextDisplayItem({Hint: '', Required: true}), OrderIndex: 0}),
                                        new WebsiteCustomFieldContactFormDisplayItem({Name: $localize`:@@TPWebsiteContactWidgetDefaultFieldEmail:Email`, Field: new CustomFieldEmailDisplayItem({Hint: '', Required: true}), OrderIndex: 1}),
                                        new WebsiteCustomFieldContactFormDisplayItem({Name: $localize`:@@TPWebsiteContactWidgetDefaultFieldMessage:Message`, Field: new CustomFieldMultiLineDisplayItem({Hint: '', Required: true}), OrderIndex: 2})
                                    ]
                                });
                                break;
                            case WebsiteBlockContactFormType.MediaLeft:
                            case WebsiteBlockContactFormType.MediaRight:
                                blockToAdd = new WebsiteBlockContactFormDisplayItem({
                                    GUID: this.uniqueID,
                                    Header: contactWidgetBuildingBlock.hasHeader ? ContactWidgetBlockPlaceholderText[contactWidgetBuildingBlock.contactType as WebsiteBlockContactFormType].header : null,
                                    Body: contactWidgetBuildingBlock.hasBody? ContactWidgetBlockPlaceholderText[contactWidgetBuildingBlock.contactType as WebsiteBlockContactFormType].body: null,
                                    ContactFormType: contactWidgetBuildingBlock.contactType,
                                    OrderIndex: droppedIndex,
                                    ID: this.uniqueID,
                                    Images: [new WebsiteBlockTextAndMediaImageDisplayItem({GUID: this.uniqueID})],
                                    ThankYouMessage: defaultContactFormThankYouMessage,
                                    CustomFields: [
                                        new WebsiteCustomFieldContactFormDisplayItem({Name: $localize`:@@TPWebsiteContactWidgetDefaultFieldName:Name`, Field: new CustomFieldTextDisplayItem({Hint: '', Required: true}), OrderIndex: 0}),
                                        new WebsiteCustomFieldContactFormDisplayItem({Name: $localize`:@@TPWebsiteContactWidgetDefaultFieldEmail:Email`, Field: new CustomFieldEmailDisplayItem({Hint: '', Required: true}), OrderIndex: 1}),
                                        new WebsiteCustomFieldContactFormDisplayItem({Name: $localize`:@@TPWebsiteContactWidgetDefaultFieldMessage:Message`, Field: new CustomFieldMultiLineDisplayItem({Hint: '', Required: true}), OrderIndex: 2})
                                    ]
                                });
                                break;
                        }
                        break;


                    case WebsiteBlockWidgetType.Signup:
                        const signupWidgetBuildingBlock = buildingBlock as WebsiteBuildingBlockSignupWidget;
                        blockToAdd = new WebsiteBlockSignupFormDisplayItem({
                            GUID: this.uniqueID,
                            Header: SignupWidgetBlockPlaceholderText[signupWidgetBuildingBlock.signupType as WebsiteBlockSignupFormType].header,
                            OrderIndex: droppedIndex,
                            SignupFormType: signupWidgetBuildingBlock.signupType,
                            ID: this.uniqueID,
                            ThankYouMessage: defaultSignupFormThankYouMessage
                        })
                        break;


                    case WebsiteBlockWidgetType.Login:
                        const loginWidgetBuildingBlock = buildingBlock as WebsiteBuildingBlockLoginWidget;
                        switch(loginWidgetBuildingBlock.loginType) {
                            case WebsiteBlockLoginFormType.Default:
                            case WebsiteBlockLoginFormType.AccentBackgroundDefault:
                                blockToAdd = new WebsiteBlockLoginFormDisplayItem({
                                    GUID: this.uniqueID,
                                    Header: loginWidgetBuildingBlock.hasHeader ? LoginWidgetBlockPlaceholderText[loginWidgetBuildingBlock.loginType as WebsiteBlockLoginFormType].header : null,
                                    Body: loginWidgetBuildingBlock.hasBody? LoginWidgetBlockPlaceholderText[loginWidgetBuildingBlock.loginType as WebsiteBlockLoginFormType].body: null,
                                    LoginFormType: loginWidgetBuildingBlock.loginType,
                                    OrderIndex: droppedIndex,
                                    ID: this.uniqueID,
                                    Images: []
                                })
                                break;
                            case WebsiteBlockLoginFormType.MediaLeft:
                            case WebsiteBlockLoginFormType.MediaRight:
                                blockToAdd = new WebsiteBlockLoginFormDisplayItem({
                                    GUID: this.uniqueID,
                                    Header: loginWidgetBuildingBlock.hasHeader ? LoginWidgetBlockPlaceholderText[loginWidgetBuildingBlock.loginType as WebsiteBlockLoginFormType].header : null,
                                    Body: loginWidgetBuildingBlock.hasBody? LoginWidgetBlockPlaceholderText[loginWidgetBuildingBlock.loginType as WebsiteBlockLoginFormType].body: null,
                                    LoginFormType: loginWidgetBuildingBlock.loginType,
                                    OrderIndex: droppedIndex,
                                    ID: this.uniqueID,
                                    Images: [new WebsiteBlockTextAndMediaImageDisplayItem({GUID: this.uniqueID})]
                                })
                                break;
                        }
                        break;
                    default:
                        throw Error(`Widget type ${widgetBuildingBlock.type} not implemented.`);
                }
                break;
            case WebsiteBuildingBlockKind.Blog:
                const blogPostsBlock = buildingBlock as WebsiteBuildingBlockBlog;
                blockToAdd = new WebsiteBlockBlogDisplayItem({
                    GUID: this.uniqueID,
                    ID: this.uniqueID
                })
                break;
            case WebsiteBuildingBlockKind.Embed:
                const embedBlock = buildingBlock as WebsiteBuildingBlockEmbed;
                switch(embedBlock.type) {
                    case WebsiteBlockEmbedContentType.Map:
                        blockToAdd = new WebsiteBlockEmbedDisplayItem({
                            EmbedType: embedBlock.embedType, 
                            OrderIndex: droppedIndex,
                            GUID: this.uniqueID, 
                            ID: this.uniqueID, 
                            Source: embedBlock.source, 
                            ContentType: WebsiteBlockEmbedContentType.Map
                        })
                        break;
                }
        };

        this._blocks!.splice(droppedIndex, 0, blockToAdd);
        this.NormalizeOrderIndex();
        this.pageChanged.emit();
    }

    GetCarouselPrevIndex(currIndex: number, arrLength: number): number {
        return (currIndex-1+arrLength) % arrLength;
    }

    GetCarouselNextIndex(currIndex: number, arrLength: number): number {
        return (currIndex+1) % arrLength;
    }

    GoToHomePage(): void {
        const homePage = this.websiteData.WebsitePages.find(page => page.IsHomepage);
        const homePageID = homePage ? homePage.ID : null;
        this.NavigationPageClicked(homePageID);
    }

    PrepareScalingTypeData(block: WebsiteBlockDisplayItem): WebsiteBlockTypeData {
        if (WebsiteBlockDisplayItem.IsTextAndMedia(block)) {
            return { BlockType: block.WebsiteBlockType, BlockSubtype: block.TextAndMediaType };
        } else if (WebsiteBlockDisplayItem.IsContactForm(block)) {
            return { BlockType: block.WebsiteBlockType, BlockSubtype: block.ContactFormType };
        } else if (WebsiteBlockDisplayItem.IsLoginForm(block)) {
            return { BlockType: block.WebsiteBlockType, BlockSubtype: block.LoginFormType };
        } else {
            throw Error(`Block type does not have image scaling information implemented.`);
        }
    }

    DisableMultiSelectUpload(images: WebsiteBlockTextAndMediaImageDisplayItem[]): boolean {
        return images.filter((image) => image.WebsiteMediaLibraryItemID !== null).length >= photoGalleryCarouselMaxItems;
    }

    private MoveBlock(block: WebsiteBlockDisplayItem, index: number) {
        const nextBlock = this._blocks![block.OrderIndex + index];
        const nextBlockIndex = {...nextBlock}.OrderIndex;
        nextBlock.OrderIndex = block.OrderIndex;
        block.OrderIndex = nextBlockIndex;
        this._blocks!.sort((a,b) => a.OrderIndex - b.OrderIndex);
        this.pageChanged.emit();
        this.animate = true;
    }

    private GetPage(ID: string, pages: WebsitePageDisplayItem[]): WebsitePageDisplayItem | null {
        for(let page of pages) {
            if(page.ID === ID) {
                return page;
            }
            const childPage = this.GetPage(ID, page.ChildPages);
            if(childPage) {
                return childPage;
            }
        }
        return null;
    }

    private GetImageURL(ID: string): void {
        if(this.mode === WebsiteMode.Live){
            this._headerLogoSource = this.websiteData.HeaderLogoUrl;
        } else {
            if(ID){
                ApiBaseRoutes.Websites.GetMediaLibraryItem.Call({Parameter: ID}).subscribe({
                next: (value: WebsiteMediaLibraryDisplayItem) => {
                    this.SetNewImage(value);
                },
                error: (_: any) => {
                    this.websiteData.HeaderLogoMediaLibraryItemID = null;
                }
                });
            } else {
                this.websiteData.HeaderLogoMediaLibraryItemID = null;
            }
        }
    }

    private SetNewImage(value: WebsiteMediaLibraryDisplayItem): void {
        this._headerLogoSource = value.URL;
    }

    private NormalizeOrderIndex(): void {
        this._blocks?.forEach((block, index) => {
            block.OrderIndex = index;
        });
    }

    private GetGalleryColumns(header: string | null, body: string | null){
        const teamMemberArray: WebsiteBlockTextAndMediaImageDisplayItem[] = [];
        for(let i = 0; i < this._teamSectionElementsNumber; i++){
            const imageDisplayItem =  new WebsiteBlockTextAndMediaImageDisplayItem({
                GUID: this.uniqueID, 
                Header: TextMediaBlockPlaceholderText[WebsiteBlockTextAndMediaType.GalleryCircleColumn].header, 
                Body: TextMediaBlockPlaceholderText[WebsiteBlockTextAndMediaType.GalleryCircleColumn].body
            });
            teamMemberArray.push(imageDisplayItem);
        }
        return teamMemberArray;
    }

    private GetPhotoGallery(type: WebsiteBlockTextAndMediaType): WebsiteBlockTextAndMediaImageDisplayItem[] {     
        const photoGalleryArray: WebsiteBlockTextAndMediaImageDisplayItem[] = [];
        const numberOfImagesInGallery: number = (type === WebsiteBlockTextAndMediaType.PhotoGalleryGrid) ? 4 : 3;
        for(let i = 0; i < numberOfImagesInGallery ; i++) {
            const imageDisplayItem =  new WebsiteBlockTextAndMediaImageDisplayItem({GUID: this.uniqueID});
            photoGalleryArray.push(imageDisplayItem);
        }
        return photoGalleryArray;
    }

    get blocks(): WebsiteBlockDisplayItem[] {
        return this._blocks!;
    }
    

    get websiteData(): WebsiteDisplayItem {
        return this._websiteData;
    }


    get WebsiteBlockType(): typeof WebsiteBlockType {
        return WebsiteBlockType;
    }

    get WebsiteBlockDisplayItem(): typeof WebsiteBlockDisplayItem {
        return WebsiteBlockDisplayItem;
    }

    get WebsiteBlockTextAndMediaType(): typeof WebsiteBlockTextAndMediaType {
        return WebsiteBlockTextAndMediaType;
    }

    get WebsiteBlockContactFormType(): typeof WebsiteBlockContactFormType {
        return WebsiteBlockContactFormType;
    }

    get WebsiteBlockLoginformType(): typeof WebsiteBlockLoginFormType {
        return WebsiteBlockLoginFormType;
    }

    get  WebsiteBlockEmbedContentType(): typeof  WebsiteBlockEmbedContentType {
        return  WebsiteBlockEmbedContentType; 
    }

    get WebsiteTemplate(): typeof WebsiteTemplate {
        return WebsiteTemplate;
    }

    get WebsiteMode(): typeof WebsiteMode {
        return WebsiteMode;
    }

    get ID(): string {
        return this._ID;
    }

    get headerLogoSource(): string | null {
        return this._headerLogoSource;
    }

    get uniqueID(): string {
        return `${WEBSITE_PREFIX_ID}${this._uniqueID++}`;
    }

    get socialLinksInHeader(): boolean {
        return this._WebsiteManager.ShowSocialLinksInHeader(this._websiteData.SocialLinksLocation);
    }

    get socialLinksInFooter(): boolean {
        return this._WebsiteManager.ShowSocialLinksInFooter(this._websiteData.SocialLinksLocation);
    }

    get photoGalleryCarouselMaxItems(): number {
        return photoGalleryCarouselMaxItems;
    }

    // private _dummySignupFormDisplayItem: WebsiteBlockSignupFormDisplayItem = new WebsiteBlockSignupFormDisplayItem();
    // public get dummySignupFormDisplayItem(): WebsiteBlockSignupFormDisplayItem {
    //     return this._dummySignupFormDisplayItem;
    // }
}
