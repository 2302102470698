<ng-container *ngIf="kind !== ButtonKind.Link && !urlLink">
    <ng-container *ngTemplateOutlet="buttonTemplate"></ng-container>
</ng-container>


<a *ngIf="kind === ButtonKind.Link && !urlLink"
    role="button"
    class="border-0"
    [ngClass]="buttonClass"
    [attr.data-sb-qa]="qaTag"
    [matMenuTriggerFor]="menuTrigger"
    #optionsMenuTrigger = "matMenuTrigger"
    (menuClosed)="MenuClosed()"
    (click)="ButtonClicked($event, optionsMenuTrigger)">
    <div [ngClass]="buttonClass">
        <ng-container *ngTemplateOutlet="labelTemplate"></ng-container>
    </div>
    
</a>

<a *ngIf="kind !== ButtonKind.Link && urlLink"
    [href]="urlLink | trustUrl"
    [attr.data-sb-qa]="qaTag"
    routerLinkActive="active"
    target="_blank">
    <ng-container *ngTemplateOutlet="buttonTemplate"></ng-container>
</a>

<a *ngIf="kind === ButtonKind.Link && urlLink"
    role="button"
    class="border-0"
    [ngClass]="buttonClass"
    [attr.data-sb-qa]="qaTag"
    (click)="ButtonClicked($event)"
    [href]="urlLink | trustUrl"
    routerLinkActive="active"
    target="_blank">
    <ng-container *ngTemplateOutlet="labelTemplate"></ng-container>
</a>

<ng-template #labelTemplate>
    <div class="d-flex justify-content-center align-items-center text-start user-select-auto">
        <lib-icon *ngIf="prefixIcon" [ngClass]="{'pe-1':!iconOnly, 'invisible': loading}"
            [icon]="prefixIcon"
            [size]="prefixIconSize">
        </lib-icon>
        <div *ngIf="!iconOnly" [ngClass]="{'invisible': loading}">
            <ng-content select="[label]"></ng-content>
        </div>
        <span *ngIf="loading" class="spinner-border spinner-border-sm position-absolute"></span>
        <lib-icon *ngIf="suffixIcon" [ngClass]="{'ps-1':!iconOnly, 'invisible': loading}"
            [icon]="suffixIcon"
            [size]="suffixIconSize">
        </lib-icon>
    </div>
</ng-template>

<ng-template #buttonTemplate>
    <div 
        [matTooltip]="toolTipText" 
        matTooltipPosition="below"
        [matBadge]="badgeText"
        [matBadgeHidden]="!badgeText"
        matBadgeColor="warn" >
        <button #button mat-button 
            [matMenuTriggerFor]="menuTrigger"
            #optionsMenuTrigger = "matMenuTrigger"
            type="button"
            [ngClass]="buttonClass"
            [class.text-nowrap]="!allowTextWrap"
            [disabled]="disabled"
            [attr.data-sb-qa]="qaTag"
            (menuClosed)="MenuClosed()"
            (click)="ButtonClicked($event, optionsMenuTrigger); $event.stopPropagation();">
                <ng-container *ngTemplateOutlet="labelTemplate"></ng-container>
        </button>
    </div>
</ng-template>
