<div class="d-inline" (mouseenter)="MouseEntered()" (mouseleave)="MouseLeave()">
    <div *ngIf="showActionMenu" [ngClass]="{'design-block-item-action-photo-gallery-middle': isMiddleImageOfView, 
                                            'design-block-item-action-photo-gallery': !isMiddleImageOfView}">
        <div class="d-flex gap-2 px-2">
            <lib-button
                [kind]="ButtonKind.Tertiary"
                [prefixIcon]="Icon.Common.ArrowBack"
                [prefixIconSize]="IconSize.ExtraSmall"
                [size]="ButtonSize.Small"
                [iconOnly]="true"
                (clicked)="ImageActionClicked(WebsiteGalleryItemAction.LeftSwap)">
            </lib-button>
            <lib-button
                [kind]="ButtonKind.Tertiary"
                [prefixIcon]="Icon.Common.ArrowForward"
                [prefixIconSize]="IconSize.ExtraSmall"
                [size]="ButtonSize.Small"
                [iconOnly]="true"
                (clicked)="ImageActionClicked(WebsiteGalleryItemAction.RightSwap)">
            </lib-button>
            <lib-button
                [kind]="ButtonKind.Tertiary"
                [prefixIcon]="Icon.Common.CloudUploadOutline"
                [prefixIconSize]="IconSize.ExtraSmall"
                [size]="ButtonSize.Small"
                [iconOnly]="true"
                (clicked)="ImageActionClicked(WebsiteGalleryItemAction.MediaGallery)">
            </lib-button>
            <lib-button *ngIf="!disableImageDetails"
                [kind]="ButtonKind.Tertiary"
                [prefixIcon]="Icon.Common.InfoOutline"
                [prefixIconSize]="IconSize.ExtraSmall"
                [size]="ButtonSize.Small"
                [iconOnly]="true"
                [disabled]="disableImageDetails"
                (clicked)="ImageActionClicked(WebsiteGalleryItemAction.MediaDetails)">
            </lib-button>
            <lib-button
                [kind]="ButtonKind.DangerSecondary"
                [prefixIcon]="Icon.Common.DeleteOutline"
                [prefixIconSize]="IconSize.ExtraSmall"
                [size]="ButtonSize.Small"
                [iconOnly]="true"
                [disabled]="disableRemoveImage"
                (clicked)="ImageActionClicked(WebsiteGalleryItemAction.Delete)">
            </lib-button>
        </div>
    </div>
    <div [ngClass]="{
            'photo-grid-image-item': kind === WebsiteBlockTextAndMediaType.PhotoGalleryGrid,
            'photo-caraousel-image-item': kind !== WebsiteBlockTextAndMediaType.PhotoGalleryGrid,
            'd-flex align-items-center justify-content-between': kind !== WebsiteBlockTextAndMediaType.PhotoGalleryGrid && isMiddleImageOfView
        }"
        libScrollToView
        [currMode]="mode"
        (mouseenter)="MouseEnterGeneralImage()"
        (mouseleave)="MouseLeaveGeneralImage()">

        <lib-image-action-menu-general *ngIf="kind === WebsiteBlockTextAndMediaType.PhotoGalleryGrid"
            [showActionMenu]="showGridImageActionMenu"
            [disableImageDetails]="disableGridImageDetails"
            (imageActionClicked)="ImageActionClicked($event)">
        </lib-image-action-menu-general>

        <ng-content select="[image]"></ng-content>
    </div>
</div>
