import { Component, ContentChild, ContentChildren, EventEmitter, Input, OnInit, Output, QueryList, TemplateRef, ViewChild } from '@angular/core';
import { MenuButtonMode } from '@library/base';
import { ButtonBaseComponent } from '../button-base.component';
import { ButtonComponent } from '../button.component';
import { MenuItemComponent } from './menu-item/menu-item.component';


@Component({
    selector: 'lib-menu-button',
    templateUrl: './menu-button.component.html',
    styleUrls: ['./menu-button.component.scss'],
})
export class MenuButtonComponent extends ButtonBaseComponent implements OnInit {
    private _subMenuItems!: QueryList<MenuItemComponent>;

    @ContentChild('menuContent', { static: false })
    menuContent!: TemplateRef<any>;

    @ContentChild('buttonContentTemplate', { static: false })
    buttonContentTemplate!: TemplateRef<any>;

    @ContentChildren(MenuItemComponent) menuItemComponents!: QueryList<MenuItemComponent>;

    @ViewChild('baseButton', {static: false}) baseButtonRef!: ButtonComponent;

    @Input() hasCaretSuffix: boolean = true;
    @Input() mode: MenuButtonMode = MenuButtonMode.Default;
    @Input() customClass: string = '';

    @Output() override menuClosed: EventEmitter<void> = new EventEmitter();

    constructor() {
        super();
    }

    override ngOnInit(): void {
        super.ngOnInit();
        if (this.hasCaretSuffix) {
            this.suffixIcon = this.Icon.Common.CaretDown;
            this.suffixIconSize = this.IconSize.Small;
        }
    }

    OpenMenu() {
        this.clicked.emit();
    }

    OpenMenuItem(menuItem: MenuItemComponent) {
      this._subMenuItems = menuItem.subMenuItems;
    }

     CloseMenu() {
        if(!this.disabled) {
            this.baseButtonRef.optionsMenuTriggerRef.closeMenu();
        }
    }

    MenuItemClicked(item: MenuItemComponent, event: MouseEvent) {
        if (!item.disabled) {
            item.clicked.emit();
        }

        if (item.stopPropagation) {
            event.stopPropagation();
        }
    }

    override MenuClosed(): void {
      this.menuClosed.emit();
    }

    get subMenuItems(): QueryList<MenuItemComponent> {
      return this._subMenuItems;
    }

    get menuButtonState(): boolean {
        return this.optionsMenuTriggerRef ? this.optionsMenuTriggerRef!.menuOpen : false;
    }
    
}
