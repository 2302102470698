import { Component, ElementRef, EventEmitter, HostListener, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { BaseViewComponent, StatusKind, IconItem } from '@library/base';

@Component({
    selector: 'lib-banner',
    templateUrl: './banner.component.html',
    styleUrls: ['./banner.component.scss']
})
export class BannerComponent extends BaseViewComponent implements OnInit {
    @Input() isDismissable: boolean = true;
    @Input() kind: StatusKind = StatusKind.Neutral;
    @Input() startExpanded: boolean = false;

    @Output() dismissButtonClicked = new EventEmitter<boolean>();
    @Output() togglePressed = new EventEmitter<boolean>();

    private _isExpandable: boolean = true;
    private _hasFooter: boolean = true;
    private _showBanner: boolean = true;
    private _showMorePosition: number = 0;
    private _resizing: boolean = false;
    private _resizeCount: number = 0;

    @ViewChild("matExpansionPanel", { static: false }) matExpansionPanel!: MatExpansionPanel;
    @ViewChild("panelDiv", { static: false }) panelDivRef!: ElementRef;
    @ViewChild("content", { static: false }) contentRef!: ElementRef;
    @ViewChild("footer", { static: false }) footerRef!: ElementRef;
    @ViewChild("footerContent", { static: false }) footerContentRef!: ElementRef;

    constructor(private _NgZone: NgZone) {
        super();
    }

    override ngOnInit() {
        super.ngOnInit();
    }

    ngAfterViewInit() {
        this.CheckIfExpandableOrHasFooter();
    }

    CheckIfExpandableOrHasFooter() {
        this._NgZone.run(() => {
            this._isExpandable = this.contentRef.nativeElement.children.length > 0;
            this._hasFooter = this.footerContentRef.nativeElement.children.length > 0;
            setTimeout(() => this.ResizeFooter());
        })
    }

    DismissBanner() {
        this.HideBanner();
        this.dismissButtonClicked.emit(true);
    }

    ShowBanner() {
        this._showBanner = true;
    }

    HideBanner() {
        this._showBanner = false;
    }

    ExpansionPressed(value: boolean) {
        if (this.contentRef) {
            this.contentRef.nativeElement.style.opacity = value ? '1' : '0';
            this.togglePressed.emit(value);
        }
    }

    TogglePanel(): void {
        this.matExpansionPanel.expanded ? this.matExpansionPanel.close() : this.matExpansionPanel.open();
    }

    IconForBannerKind(kind: StatusKind): IconItem {
        switch (kind) {
            case StatusKind.Danger: return this.Icon.Common.ErrorOutline;
            case StatusKind.Default: return this.Icon.Common.InfoOutline;
            case StatusKind.Neutral: return this.Icon.Common.LightbulbOutline;
            case StatusKind.Success: return this.Icon.Common.CheckCircleOutline;
            case StatusKind.Warning: return this.Icon.Common.WarningOutline;
            case StatusKind.Brand: return this.Icon.Common.LightbulbOutline;
            default: throw Error(`Unrecognized banner kind ${this.kind}`);
        }
    }

    private ResizeFooter() {
        if (!this._resizing && this.footerRef) {
            this._resizing = true;
            this._resizeCount = 0;
            requestAnimationFrame(() => this.ResizeStep());
        }
    }

    private ResizeStep() {
        const width = getComputedStyle(this.panelDivRef.nativeElement).width;
        if(width === "auto" || !this.matExpansionPanel?.id){  // in case the width is not finished calculating
            setTimeout(() => this.ResizeStep(), 100);
            return;
        }
        this.footerRef.nativeElement.style.width = width;
        const footerHeight = getComputedStyle(this.footerRef.nativeElement).height;
        document.getElementById(this.matExpansionPanel.id)!.style['padding-bottom' as any] = footerHeight;

        this._resizeCount++;
        if (this._resizeCount < 20) {
            requestAnimationFrame(() => this.ResizeStep());
        } else {
            this._resizing = false;
        }
    }

    get isExpanded(): boolean {
        if (this.matExpansionPanel) {
            return this.matExpansionPanel.expanded;
        } else {
            return !this._isExpandable || this.startExpanded;
        }
    }

    get showBanner(): boolean {
        return this._showBanner;
    }

    get showMorePosition(): number {
        return this._showMorePosition;
    }

    get isExpandable(): boolean {
        return this._isExpandable;
    }

    get hasFooter(): boolean {
        return this._hasFooter;
    }

    @HostListener('window:resize')
    onResize() {
        this.ResizeFooter();
        // Recompute banner size
        // window.innerWidth <= 400 ? this.width = 200 : this.width = 400;
    }
}