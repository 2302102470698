<ng-container *ngIf="width && height">
    <div *ngIf="isLoading" [ngStyle]="{'width.px': width, 'height.px': height}" class="skeleton_pulse w-100 h-100"></div>
    <img [hidden]="isLoading" [src]="src" [alt]="alt" [width]="width" [height]="height" (load)="ImageLoaded()" (error)="ImageCouldNotLoad()">
</ng-container>

<ng-container *ngIf="!width && !height">
    <div class="w-100 h-100" [ngClass]="{'skeleton_pulse': isLoading}">
        <img #imageRef [draggable]="!hasImage || viewOnly" 
        [ngClass]="{'invisible': isLoading, 'cursor-pointer': showCursorPointer}" 
        [src]="src" 
        [alt]="alt" 
        (load)="ImageLoaded()" 
        (error)="ImageCouldNotLoad()"
        (mousedown)="MouseDown($event)"
        (touchstart)="TouchStart($event)">
    </div>
</ng-container>
