<lib-form-field-container
    #anchor
    [control]="formControl"
    [isLoading]="isLoading"
    [isReadOnly]="isReadOnly"
    [isViewOnly]="isViewOnly"
    [isOptional]="isOptional"
    [isDisabled]="isDisabled"
    [prefixIcon]="prefixIcon"
    [suffixIcon]="suffixIcon"
    [errors]="errors"
    [hideErrors]="popupOpen">

    <ng-content label select="[label]"></ng-content>
    <ng-content hintMessage select="[hintMessage]"></ng-content>
    <ng-content prefixText select="[prefixText]"></ng-content>
    <ng-content suffixText select="[suffixText]"></ng-content>
    <ng-container readOnly>
        <div #providedReadOnly class="wrapper-content">
            <ng-content select="[readOnly]" ></ng-content>
        </div>
        <div class="default-content">
            <ng-container *ngIf="formControl.value">
                {{formControl.value[0].Render() + ' - ' + formControl.value[1].Render()}}
            </ng-container>
            <ng-container *ngIf="!formControl.value" i18n="@@CommonNotSpecifiedLabel">
                Not specified
            </ng-container>
        </div>
    </ng-container>

    <lib-menu-button menu *ngIf="!isReadOnly" [disabled]="isDisabled" [kind]="ButtonKind.Link">
        <ng-container label i18n="@@CommonQuickSelectLabel">
            Quick Select
        </ng-container>
        <lib-menu-item
            i18n-label="@@CommonFormFieldNextMonthLabel"
            label="Next Month"
            [stopPropagation]="true"
            (clicked)="Preset('nextmonth')">
        </lib-menu-item>
        <mat-divider class="mx-2 mt-1 pb-1"></mat-divider>
        <lib-menu-item
            i18n-label="@@CommonFormFieldThisMonthLabel"
            label="This Month"
            [stopPropagation]="true"
            (clicked)="Preset('thismonth')">
        </lib-menu-item>
        <lib-menu-item
            i18n-label="@@CommonFormFieldThisYearLabel"
            label="This Year"
            [stopPropagation]="true"
            (clicked)="Preset('thisyear')">
        </lib-menu-item>
        <lib-menu-item
            i18n-label="@@CommonFormFieldYearToDateLabel"
            label="Year to Date"
            [stopPropagation]="true"
            (clicked)="Preset('yeartodate')">
        </lib-menu-item>
        <mat-divider class="mx-2 mt-1 pb-1"></mat-divider>
        <lib-menu-item
            i18n-label="@@CommonFormFieldLast7DaysLabel"
            label="Last 7 Days"
            [stopPropagation]="true"
            (clicked)="Preset('last7days')">
        </lib-menu-item>
        <lib-menu-item
            i18n-label="@@CommonFormFieldLast30DaysLabel"
            label="Last 30 Days"
            [stopPropagation]="true"
            (clicked)="Preset('last30days')">
        </lib-menu-item>
        <lib-menu-item
            i18n-label="@@CommonFormFieldLastMonthLabel"
            label="Last Month"
            [stopPropagation]="true"
            (clicked)="Preset('lastmonth')">
        </lib-menu-item>
        <lib-menu-item
            i18n-label="@@CommonFormFieldLastYearLabel"
            label="Last Year"
            [stopPropagation]="true"
            (clicked)="Preset('lastyear')">
        </lib-menu-item>
    </lib-menu-button>
        <input #input [hidden]="isLoading || isReadOnly"
        mbsc-datepicker
        select="range"
        [formControl]="viewControl"
        [anchor]="anchor?.containerBox?.nativeElement"
        [min]="minDateJS"
        [max]="maxDateJS"
        [touchUi]="isMobile"
        [showRangeLabels]="showRangeLabels"
        [attr.placeholder]="placeholderText"
        [dayNamesShort]="localeInfo.AbbreviatedDayNames"
        [monthNames]="localeInfo.MonthNames"
        [monthNamesShort]="localeInfo.AbbreviatedMonthNames"
        [dayNamesMin]="localeInfo.AbbreviatedDayNames"
        [firstDay]="firstDayOfWeek"
        [rtl]="localeInfo.RightToLeft"
        (onChange)="ChangeEvent($event)"
        (onOpen)="PopupOpened($event)"
        (onClose)="CloseEvent($event)"
        (change)="$event.stopPropagation()"
        [dateFormat]="dateFormat | uppercase"
        themeVariant="light"
        [focusOnClose]="false"
        (blur)="OnBlur()"
        (focus)="OnFocus()"/>


</lib-form-field-container>

