<div class="scss-website-background-color" [ngSwitch]="websiteMode">
    <ng-container *ngSwitchCase="WebsiteMode.Live">
        <div class="d-flex justify-content-center position-relative design-block">
            <ng-container *ngTemplateOutlet="blockContent"></ng-container>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="WebsiteMode.ReadOnly">
        <div class="d-flex justify-content-center position-relative design-block">
            <ng-container *ngTemplateOutlet="blockContent"></ng-container>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="WebsiteMode.Preview">
        <lib-design-blocks-action-menu
                [disableMoveUp]="disableMoveUp"
                [disableMoveDown]="disableMoveDown"
                [disableRemoveItem]="disableRemoveItem"
                [disableAddItem]="disableAddItem"
                [disableMultiSelectImage]="disableMultiSelectImage"
                [showMultiSelectImage]="type !== WebsiteBlockTextAndMediaType.PhotoGalleryGrid"
                [showAdd]="type !== WebsiteBlockTextAndMediaType.PhotoGalleryGrid"
                (moveDownClicked)="MoveDownClicked()"
                (moveUpClicked)="MoveUpClicked()"
                (deleteClicked)="DeleteClicked()"
                (addItemClicked)="AddItemClicked()"
                (removeItemClicked)="RemoveItemClicked()"
                (openMultiSelectMediaGallery)="OpenMultiSelectMediaGallery()">
                <ng-container *ngTemplateOutlet="blockContent"></ng-container>
        </lib-design-blocks-action-menu>
    </ng-container>
</div>

<ng-template #blockContent>
  <div *ngIf="type !== WebsiteBlockTextAndMediaType.PhotoGalleryGrid && websiteMode != WebsiteMode.ReadOnly" class="carousel-control-prev d-none d-md-flex align-items-center">
    <lib-button [prefixIconSize]="IconSize.Large" [kind]="ButtonKind.Tertiary" [iconOnly]="true"
    [prefixIcon]="Icon.Common.ChevronLeft" (clicked)="Previous()">
    </lib-button>
  </div>
    <div class="w-100" (touchstart)="Swipe($event, true)" (touchend)="Swipe($event, false)" [ngClass]="{
        'photo-gallery-grid-block': type === WebsiteBlockTextAndMediaType.PhotoGalleryGrid,
        'photo-gallery-caraousel-block': type !== WebsiteBlockTextAndMediaType.PhotoGalleryGrid}">

        <ng-content></ng-content>

        <!-- Indicator Dots -->
        <div *ngIf="type !== WebsiteBlockTextAndMediaType.PhotoGalleryGrid"
            class="d-flex justify-content-center gap-2 w-100 carousel-indicator-container">
            <div *ngFor="let _ of images, let index = index"
                class="rounded-circle carousel-indicator"
                [ngClass]="{'indicator-active': index === middleImageIndex, 'indicator-inactive': index !== middleImageIndex }"
                (click)="middleImageIndex = index">
            </div>
        </div>

  </div>
  <div *ngIf="type !== WebsiteBlockTextAndMediaType.PhotoGalleryGrid && websiteMode != WebsiteMode.ReadOnly" class="carousel-control-next d-none d-md-flex align-items-center">
    <lib-button [prefixIconSize]="IconSize.Large" [kind]="ButtonKind.Tertiary" [iconOnly]="true"
      [prefixIcon]="Icon.Common.ChevronRight" (clicked)="Next()">
    </lib-button>
  </div>

</ng-template>
