export enum DialogType {
    Small = "Small",
    Medium = "Medium",
    Large = "Large",
    Fullscreen = "Fullscreen"
}

export enum DialogEvents {
    AfterOpened,
    BeforeClosing,
    AfterClosing,
    Dismissed,
    PrimaryAction,
    SecondaryAction,
    AfterClosingFromPrimaryAction,
    BeforeClosingFromPrimaryAction,
    ClickedOutside
}

export enum RecurringDeleteOptions {
    PrimaryOption,
    SecondaryOption
}

export class SimpleDialogData {
    constructor(initializer?: Partial<SimpleDialogData>) {
        if (initializer) {
            Object.assign(this, initializer);
        }
    }

    Title?: string = "";
    Text?: string = "";
    PrimaryButtonText?: string | null = null;
    SecondaryButtonText?: string | null = null;
    DismissPreviousDialog?: boolean = true;
    PrimaryOptionText?: string | null = null;
    SecondaryOptionText?: string | null = null;
}