<div *ngIf="context === PaymentContext.PaymentPortal || allowPartialPayment || hasZeroAmountDue" class="mb-3">
    <lib-card>
        <div>
            <ng-container *ngIf="schoolInfo">
                <div class="d-flex gap-3 flex-row flex-wrap">
                  <img id="image-resize" imageResizer [src]="schoolInfo!.InvoiceLogoURL">
                  <div class="col-sm-3">
                    <lib-label>{{schoolInfo!.Name}}</lib-label>
                    <p *ngIf="schoolInfo!.Address" class="typography-caption-large text-wrap-pre"
                      [innerHTML]="schoolInfo!.Address | trustHtml"></p>
                  </div>
                </div>
                <hr>

                <div class="d-flex gap-1">
                    <p class="typography-body-medium-strong" i18n="@@CommonAccountLabelWithColon">Account:</p>
                    <p class="typography-body-medium">{{invoiceDetails.FamilyName}}</p>
                </div>
            </ng-container>
            <div *ngIf="mode !== PaymentSourceMode.AnonymousPayment && (allowPartialPayment || hasZeroAmountDue)">
                <form [formGroup]="form">
                    <lib-input-currency
                        [formControl]="form.controls.Amount"
                        (blur)="AmountChanged()"
                        [qaTag]="'input-amount'">
                        <ng-container hintMessage>{{GetHintMessageForAmount()}}</ng-container>
                        <ng-container label i18n="@@CommonAmountLabel">Amount</ng-container>
                    </lib-input-currency>
                </form>
            </div>
            <div *ngIf="invoiceDetails && invoiceDetails.InvoiceStatus !== InvoiceStatus.Open">
                <hr>
                <ng-container [ngSwitch]="invoiceDetails.InvoiceStatus">
                    <ng-container *ngSwitchCase="InvoiceStatus.Paid">
                        <h5 i18n="@@CommonPaymentPagePaymentCompletedLabel">Payment Completed</h5>
                        <p class="pt-3" i18n="@@CommonPaymentPageInvoicePaid">This invoice has already been paid. Thank you!</p>
                        <div class="d-flex justify-content-center w-100 py-3">
                            <img class="scss-image-size" src="assets/images/Invoice-Paid.svg">
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="InvoiceStatus.Void">
                        <h5 i18n="@@CommonPaymentPageInvoiceVoidLabel">Invoice is void </h5>
                        <p class="pt-3" i18n="@@CommonPaymentPageInvoiceVoidDescription">This invoice was marked as void. Please contact {{schoolInfo!.Name}} for an updated invoice.</p>
                        <div class="d-flex justify-content-center w-100 py-3">
                            <img class="scss-image-size"  src="assets/images/Invoice-Void.svg">
                        </div>
                    </ng-container>
                </ng-container>
            </div>
            <div *ngIf="schoolInfo && schoolInfo!.Active && !paymentProcessor && invoiceDetails && invoiceDetails.InvoiceStatus === InvoiceStatus.Open">
                <hr>
                <h5 i18n="@@CommonPaymentPageNoOnlinePaymentsLabel">No Online Payments</h5>
                <p class="pt-3" i18n="@@CommonPaymentPageNoOnlinePaymentsDescription">{{schoolInfo!.Name}} is not setup to take online payments.</p>
                <div class="d-flex justify-content-center w-100 py-3">
                    <img class="scss-image-size" src="assets/images/No-Online-Payments.svg">
                </div>
            </div>
            <div *ngIf="schoolInfo && !schoolInfo!.Active  && invoiceDetails && invoiceDetails.InvoiceStatus === InvoiceStatus.Open">
                <hr>
                <h5 i18n="@@CommonPaymentPageSchoolNotActiveLabel">#Studio# is No Longer Active</h5>
                <p class="pt-3" i18n="@@CommonPaymentPageSchoolNotActiveDescription">{{schoolInfo!.Name}} is no longer active.</p>
                <div class="d-flex justify-content-center w-100 py-3">
                    <img class="scss-image-size" src="assets/images/No-Online-Payments.svg">
                </div>
            </div>
        </div>
    </lib-card>
</div>
