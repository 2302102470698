import { Component, HostListener, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ApiBaseRoutes, AuthenticationBaseService } from '@library/api';
import { boolean, DesignBlockImageBaseViewComponent, Environment, ErrorMessages, Errors, FormBase, LoginFormMode, Optional, Required, string, WebsiteMode } from '@library/base';
import { ButtonComponent } from '@library/button';
import { ErrorCode, ErrorResponse, PasswordResetInputItem, PortalType, PreauthenticationResultItem, UserAuthenticationInputItem, UserPreauthenticationInputItem, WebsiteBlockLoginFormDisplayItem, WebsiteBlockLoginFormType, WebsiteRenderContextDisplayItem, WebsiteRenderMode } from '@library/data-models';
import { SBDateTime } from '@library/localization';
import { WidgetManualDeviceTypeManager } from '@library/widget';
import { IFrameComponent, iframeResizer } from 'iframe-resizer';
import { RecaptchaComponent, RecaptchaLoaderService } from 'ng-recaptcha';
import { CookieService } from 'ngx-cookie-service';

class LoginFormForm extends FormBase {
    EmailAddress = Required(string);
    Password = Required(string);
    PublicComputer = Optional(boolean);
}

class MFAForm extends FormBase {
    Code = Required(string);
}

class RecaptchaErrorForm extends FormBase {
    Captcha = Required(string);
}

class RecaptchaNeededErrorForm extends FormBase {
    CaptchaNeeded = Required(string);
}

class ResetPasswordForm extends FormBase {
    EmailAddress = Required(string);
}

class MultipleAccountSelectionForm extends FormBase {
    AccountSelection = Required(PreauthenticationResultItem);
}

enum LoginWidgetNextStep {
    Login = 'Login',
    ResetPassword = 'ResetPassword'
}

@Component({
    selector: 'lib-login-form-design-block',
    templateUrl: './login-form-design-block.component.html',
    styleUrls: ['./login-form-design-block.component.scss']
})
export class LoginFormDesignBlockComponent extends DesignBlockImageBaseViewComponent implements OnInit, OnDestroy {
    @ViewChild(RecaptchaComponent) recaptchaRef!: RecaptchaComponent;

    @ViewChild("loginButton", {static: false}) loginRef!: ButtonComponent;
    @ViewChild("continueButton", {static: false}) continueRef!: ButtonComponent;
    @ViewChild("continueMFAButton", {static: false}) continueMfaRef!: ButtonComponent;

    @Input()
    set data(value: WebsiteBlockLoginFormDisplayItem) {
        if (value !== undefined) {
            this._data = value;
        }
    }
    @Input() renderContext: WebsiteRenderContextDisplayItem = new WebsiteRenderContextDisplayItem();
    @Input() kind!: WebsiteBlockLoginFormType;
    @Input() hasHeader!: boolean;
    @Input() hasBody!: boolean;
    @Input() isViewOnly: boolean = false;
    @Input() disableImageDetails: boolean = false;

    private _data!: WebsiteBlockLoginFormDisplayItem;
    readonly loginForm = LoginFormForm.Create(false);
    readonly mfaForm = MFAForm.Create(false);
    readonly resetPasswordForm = ResetPasswordForm.Create(false);
    readonly multipleAccountSelectionForm = MultipleAccountSelectionForm.Create(false);
    readonly recaptchaErrorForm = RecaptchaErrorForm.Create(false);
    protected captchaErrors: Errors = new Errors();

    readonly recaptchaNeededErrorForm = RecaptchaNeededErrorForm.Create(false);
    protected captchaNeededErrors: Errors = new Errors();
    protected captchaNeededResetErrors: Errors = new Errors();

    private _apiInProgress = false;
    private _availableAccounts: PreauthenticationResultItem[] = [];
    private accountForMFA!: PreauthenticationResultItem;
    
    private _loginFormMode: LoginFormMode = LoginFormMode.Normal;
    private _Authentication!:any;

    private _iframe: IFrameComponent | null = null;
    private _localDeployment: boolean = false;
    private _buttonElement: any = null;

    private _resolvedRecaptchaToken: string | null = null;
    private _dateRecaptchaToken: SBDateTime | null = null;

    private _EnableButton = this.EnableButton.bind(this);

    private _loginWidgetNextStep: LoginWidgetNextStep = LoginWidgetNextStep.Login;

    private _alreadyLoggedInFirstName!: string;
    private _alreadyLoggedInSchoolName: string = "";
    private _portalType!: PortalType;

    reCAPTCHAReady = false;
    recaptchaneededError = false;

    readonly emailError: ErrorMessages = {
        required: _ => $localize`:@@LoginFormEmailMissingError:Please enter an email address`,
    }

    readonly passwordError: ErrorMessages = {
        required: _ => $localize`:@@LoginFormPasswordMissingError:Please enter a password`,
        api: _ => $localize`:@@LoginFormPasswordAPIError:Sorry, you've entered an incorrect email address or password. Please try again.`
    }

    readonly continueError: ErrorMessages = {
        required: _ => $localize`:@@LoginFormJWTSelectionMissingError:Please select an account to use`
    }

    constructor(
        private _AuthenticationBaseService: AuthenticationBaseService,
        private _environment: Environment,
        private _CookieService: CookieService,
        private _RecaptchaLoaderService: RecaptchaLoaderService,
        private _NgZone: NgZone
    ) {
        super();

        if (_environment.Application.BaseUrls.Api!.includes('localhost')) {
            this._localDeployment = true;
        }

        this.captchaErrors.Override({
            api: _ => $localize`:@@CommonRecaptchaErrorMessage:Could not connect to the reCAPTCHA service. Please check your internet connection and reload.`
        });

        this.captchaNeededErrors.Override({
            api: _ => $localize`:@@CommonRecaptchaVerificationLoginButtonErrorMessage:An error occurred during reCAPTCHA verification. Please try again by clicking the "Log In" button.`
        });

        this.captchaNeededResetErrors.Override({
            api: _ => $localize`:@@CommonRecaptchaVerificationResetButtonErrorMessage:An error occurred during reCAPTCHA verification. Please try again by clicking the "Request reset link" button.`
        });
    }

    override ngOnInit(): void {
        super.ngOnInit();

        this.resetPasswordForm.isViewOnly = this.isViewOnly;
        this.loginForm.isViewOnly = this.isViewOnly;
        this.multipleAccountSelectionForm.isViewOnly = this.isViewOnly;

        if (!this.isViewOnly) {
            if (this.renderContext.RenderMode == WebsiteRenderMode.Widget) {
                //alert(`I am a widget, served from:\n${origin}`)
                
                window['iFrameResizer' as any] = {
                    onReady: () => {
                        // Set callback for updates on full window size
                        (window as any).parentIFrame?.sendMessage('ready');
                        (window as any).parentIFrame?.getPageInfo((obj: any) => {
                            this.UpdateBreakpoint(obj.clientWidth, obj.clientHeight);
                        });
                    }
                } as any;

                this._subscriptions.push(this.DeviceTypeManager.breakPointChange.subscribe(_ => {
                    this.DetectChanges();
                }));
            
                this.InitializeLoginWidgetState();
            }
        }
        this._RecaptchaLoaderService.ready.subscribe(_ => this.reCAPTCHAReady = true);
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();

        if (this._iframe && this._iframe.iFrameResizer) {
            this._iframe.iFrameResizer.close();
        }
    }

    Load(iframe: HTMLIFrameElement) {
        if (!this._iframe) {
            const components = iframeResizer({
                log: false,
                heightCalculationMethod: 'taggedElement',
                checkOrigin: false,
                onMessage: (signal: any) => {
                    if (signal.message === 'ready') {
                        iframe.style.minHeight = ''
                    } else if (signal.message === 'reload') {
                        window.location.reload();
                    }
                }
            } as any, iframe);

            this._iframe = components && components.length > 0 ? components[0] : null;
        }
    }

    private UpdateBreakpoint(width: number, height: number) {
        (this.DeviceTypeManager as WidgetManualDeviceTypeManager).SetPageSize(width, height);
    }

    private InitializeLoginWidgetState(): void {
        this._loginFormMode = LoginFormMode.Normal;

        //STUDENT_PORTAL_AUTH_TOKEN_NAME
        let hasStudentCookie = this._CookieService.get("StudentPortalAuth");
        let hasTeacherCookie = this._CookieService.get("TeacherPortalAuth");

        //Grab data out of jwt
        if(this.websiteMode === WebsiteMode.Live && (hasStudentCookie || hasTeacherCookie)) { 
            let decodedJwt; 

            if(hasTeacherCookie) {
                this._portalType = PortalType.TeacherPortal;
                decodedJwt = this._AuthenticationBaseService.ReturnDecodedJWT(hasTeacherCookie);
            } else if(hasStudentCookie) {
                this._portalType = PortalType.StudentPortal;
                decodedJwt = this._AuthenticationBaseService.ReturnDecodedJWT(hasStudentCookie);
            }

            this._alreadyLoggedInFirstName = decodedJwt.FirstName;
            this._alreadyLoggedInSchoolName = decodedJwt.SchoolName??"";
            this._loginFormMode = LoginFormMode.AlreadyLoggedIn;
        }
    }

    LoginButton(): void {
        if (!this.renderContext.LoginFormRenderContext?.CaptchaRequired && !this.recaptchaneededError) {
            this._resolvedRecaptchaToken = null;
            this.Login(this._resolvedRecaptchaToken);
        } else if (this._resolvedRecaptchaToken && this._dateRecaptchaToken!.Clone().AddMinutes(2).totalMilliseconds > SBDateTime.Now().totalMilliseconds) {
            this.Login(this._resolvedRecaptchaToken);
        } else {
            this._loginWidgetNextStep = LoginWidgetNextStep.Login;
            this.recaptchaRef.execute();
        }
    }

    HandleCAPTCHA(captchaResponse: string | null) {
        //On reCAPTCHA reset, it emits a null response! we need to ignore that
        if(captchaResponse === null) {
            return;
        }
        
        this._resolvedRecaptchaToken = captchaResponse;
        this._dateRecaptchaToken = SBDateTime.Now();

        switch(this._loginWidgetNextStep) {
            case LoginWidgetNextStep.Login:
                this.Login(captchaResponse);
                break;
            case LoginWidgetNextStep.ResetPassword:
                this.ResetPasswordAPI();
            break;
        }        
    }

    ResetCaptchaToken() {
        this._resolvedRecaptchaToken = null;
        this._dateRecaptchaToken = null;
    }

    HandleCAPTCHAError(error: any) {
        this.recaptchaRef.reset();
        this.EnableButton();
        this.recaptchaErrorForm.controls.Captcha.setValue("needed");
        this.recaptchaErrorForm.controls.Captcha.markAsTouched();
        this.recaptchaErrorForm.controls.Captcha.markAsDirty();
        this.recaptchaErrorForm.markAllAsTouched();
        this.recaptchaErrorForm.CheckValidity();
        this.recaptchaErrorForm.enable();        
        this.recaptchaErrorForm.controls.Captcha.SetApiError();
        this.resetPasswordForm.enable();
    }

    Login(recaptchaToken: string | null): void {
        if (this.loginForm.CheckValidity()) {
            const inputItem = new UserPreauthenticationInputItem({
                EmailAddress: this.loginForm.controls.EmailAddress.value,
                Password: this.loginForm.controls.Password.value,
                Captcha: recaptchaToken
            });

            const routeURL = this._environment.Application.BaseUrls.Api + 'preauth';

            try {
                const xhr = new XMLHttpRequest();
                xhr.open("POST", routeURL, false);    // Synchronous request
                xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");

                xhr.onreadystatechange = () => this.PreAuthRequestReadyStateChange(xhr);
                xhr.send(JSON.stringify(inputItem));
            } catch {
                const xhr = new XMLHttpRequest();

                xhr.open("POST", routeURL, true);    // Synchronous request didn't work; try async
                xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");

                xhr.onreadystatechange = () => this.PreAuthRequestReadyStateChange(xhr);
                xhr.send(JSON.stringify(inputItem));
            }
        } else {
            this.loginForm.enable();
            this.EnableButton();
        }
    }

    private PreAuthRequestReadyStateChange(xhr: XMLHttpRequest) {
        if (xhr.readyState != 4) {
            // Wait for DONE state
            return;
        }

        this._NgZone.run(() => {
            if (xhr.status == 200) {
                const matchingAccountsForSchool = (JSON.parse(xhr.responseText) as PreauthenticationResultItem[]);
                //TODO: we need login widget settings to see if we want to filter by school or not.
                //const matchingAccountsForSchool = (JSON.parse(xhr.responseText) as PreauthenticationResultItem[]).filter(e => e.SchoolID === this._data.SchoolID);

                if (matchingAccountsForSchool.length > 1) {
                    this.loginForm.enable();
                    this.EnableButton();
                    this._loginFormMode = LoginFormMode.MultipleJWTs;
                    this._availableAccounts = matchingAccountsForSchool;
                    matchingAccountsForSchool.forEach(account => account.ID = account.ProfileID!);

                    setTimeout(() => {
                        this.multipleAccountSelectionForm.controls.AccountSelection.setValue(matchingAccountsForSchool[0]); //init it to the first option, as string because 0 = false for a value.
                    });

                } else if (matchingAccountsForSchool.length === 1) {
                    this.loginForm.enable();
                    this.EnableButton();
                    this.AccountSelected(matchingAccountsForSchool[0]);

                } else {
                    this.loginForm.enable();
                    this.EnableButton();
                    this.loginForm.controls.Password.SetApiError();

                }
            } else if ((JSON.parse(xhr.response) as ErrorResponse).NewErrorCode == ErrorCode.Captcha) {
                this.recaptchaRef.reset();
                this.ResetCaptchaToken();
                this.recaptchaNeededErrorForm.controls.CaptchaNeeded.setValue("needed");
                this.recaptchaNeededErrorForm.controls.CaptchaNeeded.markAsTouched();
                this.recaptchaNeededErrorForm.controls.CaptchaNeeded.markAsDirty();
                this.recaptchaNeededErrorForm.markAllAsTouched();
                this.recaptchaNeededErrorForm.CheckValidity();
                this.recaptchaNeededErrorForm.enable();        
                this.recaptchaNeededErrorForm.controls.CaptchaNeeded.SetApiError();
                this.recaptchaneededError = true;
                this.loginForm.enable();
                this.EnableButton();
            } else {
                this.recaptchaRef.reset();
                this.ResetCaptchaToken();
                this.loginForm.enable();
                this.EnableButton();
            }
        });
    }

    Normal(): void {
        this._loginFormMode = LoginFormMode.Normal;
        
        this.loginForm.reset();
        this.loginForm.enable();
        
        this.mfaForm.reset();
        this.mfaForm.enable();

        this.recaptchaRef.reset();
        this.ResetCaptchaToken();

        this.resetPasswordForm.reset();
        this.resetPasswordForm.enable();

    }

    Forgot(): void {
        this._loginFormMode = LoginFormMode.ForgotPassword;
    }

    ResetPasswordButton(): void {
        if(this.resetPasswordForm.CheckValidity()) {
            if (this._resolvedRecaptchaToken && this._dateRecaptchaToken!.Clone().AddMinutes(2).totalMilliseconds > SBDateTime.Now().totalMilliseconds) {
                this.ResetPasswordAPI();
            } else {
                this._loginWidgetNextStep = LoginWidgetNextStep.ResetPassword;
                this.recaptchaRef.execute();
            }
        } else {
            this.resetPasswordForm.enable();
        }
    }

    ResetPasswordAPI(): void {
        ApiBaseRoutes.Authentication.ResetPassword.Call({
            Body: new PasswordResetInputItem({ 
                EmailAddress: this.resetPasswordForm.controls.EmailAddress.value,
                Captcha: this._resolvedRecaptchaToken 
            })
        }).subscribe({
            next: () => {
                this.resetPasswordForm.enable();
                this._loginFormMode = LoginFormMode.ResetPasswordThankYou;
            },
        
            error: (error: typeof ApiBaseRoutes.Authentication.ResetPassword.Error) => {
                if(error.Response.NewErrorCode == ErrorCode.Captcha) {
                    this.recaptchaRef.reset();
                    this.ResetCaptchaToken();
                    this.recaptchaNeededErrorForm.controls.CaptchaNeeded.setValue("needed");
                    this.recaptchaNeededErrorForm.controls.CaptchaNeeded.markAsTouched();
                    this.recaptchaNeededErrorForm.controls.CaptchaNeeded.markAsDirty();
                    this.recaptchaNeededErrorForm.markAllAsTouched();
                    this.recaptchaNeededErrorForm.CheckValidity();
                    this.recaptchaNeededErrorForm.enable();        
                    this.recaptchaNeededErrorForm.controls.CaptchaNeeded.SetApiError();
                    this.recaptchaneededError = true;
                    this.resetPasswordForm.enable();
                } else {
                    throw error;
                }
            }
        });
    }



    Continue(): void {
        if(this.multipleAccountSelectionForm.CheckValidity()) {
            this.AccountSelected(this.multipleAccountSelectionForm.controls.AccountSelection.value!);
        } else {
            this.multipleAccountSelectionForm.enable();
            this.EnableButton();
        }
    }

    AccountSelected(account: PreauthenticationResultItem): void {
        if(account.MfaRequired) {
            this.multipleAccountSelectionForm.enable();
            this.EnableButton();
            this.accountForMFA = account;
            this._loginFormMode = LoginFormMode.MfaRequired;
        } else {
            this.RedirectToPortal(account);
        }
    }

    ContinueMFA(): void {
        this.mfaForm.controls.Code.markAsTouched();
        this.mfaForm.controls.Code.updateValueAndValidity();
        this.mfaForm.markAllAsTouched();
        
        if(this.mfaForm.CheckValidity()) {
            this.RedirectToPortal(this.accountForMFA, this.mfaForm.controls.Code.value);
        } else {
            this.mfaForm.enable();
            this.EnableButton();
        }
    }

    Logout(): void {
        this._loginFormMode = LoginFormMode.Normal;
    }

    ReturnToPortal(): void {
        //we are already logged in! 
        this.RedirectToTargetURL(this.redirectRoute);
    }

    RedirectToPortal(jwt: PreauthenticationResultItem, mfaCode: string | null = null): void {
        this._apiInProgress = true;

        //need to get the jwt string
        const inputItem = new UserAuthenticationInputItem({
            EmailAddress: this.loginForm.controls.EmailAddress.value,
            Password: this.loginForm.controls.Password.value,
            PublicComputer: this.loginForm.controls.PublicComputer.value,
            ProfileID: jwt.ProfileID,
            MFA: mfaCode
        });

        const routeURL = this._environment.Application.BaseUrls.Api + 'auth';

        try {
            const xhr = new XMLHttpRequest();
            xhr.open("POST", routeURL, false);    // Synchronous request
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");

            xhr.onreadystatechange = () => this.AuthRequestReadyStateChange(xhr, jwt);
            xhr.send(JSON.stringify(inputItem));
        } catch {
            const xhr = new XMLHttpRequest();

            xhr.open("POST", routeURL, true);    // Synchronous request didn't work; try async
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");

            xhr.onreadystatechange = () => this.AuthRequestReadyStateChange(xhr, jwt);
            xhr.send(JSON.stringify(inputItem));
        }
    }

    private AuthRequestReadyStateChange(xhr: XMLHttpRequest, jwt: PreauthenticationResultItem) {
        if (xhr.readyState != 4) {
            // Wait for DONE state
            return;
        }

        this._NgZone.run(() => {
            //either we are logged in, or we are unauthorized.
            if (xhr.status === 200) {
                const jwtString = JSON.parse(xhr.responseText) as string;
        
                let redirectRoute = this.redirectRoute;
                if (jwt) {
                    redirectRoute += '?Jwt=' + jwtString;
                }
                
                //this.RedirectToTargetURL(redirectRoute);
        
                setTimeout(() => {
                    this._loginFormMode = LoginFormMode.ManualRedirect;
                }, 3000);
            } else {
                this.mfaForm.enable();
                this.mfaForm.controls.Code.SetApiError();
            }
        });
    }

    RedirectToTargetURL(targetURL: string) {
        //Sites that use sandboxing don't allow the browser to redirect, 
        //so we have to open in a new tab/window instead of redirecting

        if (this.renderContext.WidgetSandboxed) {
            window.open(targetURL, "_blank");
        } else {
            window.open(targetURL, "_top");
        }
    }

    DisableButton(buttonComponent: ButtonComponent) {
        this._buttonElement = buttonComponent.button!.nativeElement;

        this._buttonElement.style.transition = 'none'; 
        this._buttonElement.classList.add('disabled'); 
        this._buttonElement.style.pointerEvents = 'auto';
        
        document.addEventListener('mouseup', this._EnableButton);
    }

    EnableButton() {
        if (this._buttonElement) {
            setTimeout(() => {
                this._buttonElement?.classList?.remove('disabled');
                this._buttonElement = null;
            }, 500);
        }
        
        document.removeEventListener('mouseup', this._EnableButton);
    }

    get redirectRoute(): string {
        return this._localDeployment ? `${origin}/schoolbox2.3/LoginRedirect.ashx` : `${origin}/LoginRedirect.ashx`;
    }

    get ProfileMode(): string {
        return this._Authentication?.ProfileMode;
    }

    get PortalType(): typeof PortalType {
        return PortalType;
    }

    get WebsiteRenderMode(): typeof WebsiteRenderMode {
        return WebsiteRenderMode;
    }
    
    get data(): WebsiteBlockLoginFormDisplayItem {
        return this._data;
    }

    get apiInProgress(): boolean {
        return this._apiInProgress;
    }

    get WebsiteBlockLoginFormType(): typeof WebsiteBlockLoginFormType {
        return WebsiteBlockLoginFormType;
    }

    get LoginFormMode(): typeof LoginFormMode {
        return LoginFormMode;
    }

    get loginFormMode(): LoginFormMode {
        return this._loginFormMode;
    }

    get availableAccounts(): PreauthenticationResultItem[] {
        return this._availableAccounts;
    }

    get alreadyLoggedInFirstName(): string {
        return this._alreadyLoggedInFirstName;
    }

    get alreadyLoggedInSchoolName(): string {
        return this._alreadyLoggedInSchoolName;
    }

    get portalType(): string {
        return this._portalType;
    }

    @HostListener('keydown.enter', ['$event']) 
    onKeydownHandler(event: KeyboardEvent) {
        if(this.websiteMode === WebsiteMode.Live && !this.isViewOnly) {
            (document.activeElement as HTMLElement).blur();
            switch(this._loginFormMode) {
                case LoginFormMode.Normal:
                    //needs (mousedown)="DisableButton(loginButton)"
                    this.DisableButton(this.loginRef);
                    setTimeout(() => {this.LoginButton();});                
                    break;
                case LoginFormMode.AlreadyLoggedIn:
                    this.ReturnToPortal();
                    break;
                case LoginFormMode.ForgotPassword:
                    this.ResetPasswordButton();
                    break;
                case LoginFormMode.MultipleJWTs:
                    //needs (mousedown)="DisableButton(continueButton)"
                    this.DisableButton(this.continueRef);
                    setTimeout(() => {this.Continue();});                
                    break;
                case LoginFormMode.MfaRequired:
                    //needs (mousedown)="DisableButton(continueMFAButton)"
                    this.DisableButton(this.continueMfaRef);
                    setTimeout(() => {this.ContinueMFA();});                
                    break;
                case LoginFormMode.ResetPasswordThankYou:
                    this.Normal();
                    break;
            }
        }
    }
}
