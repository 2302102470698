<div [ngClass]="{
        'scss-website-background-color-only': kind !== WebsiteBlockSignupFormType.AccentBackgroundDefault,
        'scss-block-color-variable-font': false && kind === WebsiteBlockSignupFormType.AccentBackgroundDefault,
        'scss-block-background-color-variable': kind === WebsiteBlockSignupFormType.AccentBackgroundDefault}">

    <ng-container *ngIf="websiteMode === WebsiteMode.ReadOnly">
        <div class="d-flex position-relative design-block">
            <ng-container *ngTemplateOutlet="blockContent"></ng-container>
        </div>
    </ng-container>

    <ng-container *ngIf="websiteMode === WebsiteMode.Live && renderContext.RenderMode === WebsiteRenderMode.Website">
        <div class="widget-block-live"> 
            <ng-container *ngTemplateOutlet="blockContent"></ng-container>
        </div>
    </ng-container>

    <ng-container *ngIf="websiteMode === WebsiteMode.Live && renderContext.RenderMode === WebsiteRenderMode.Widget">
        <div #signupBlock class="d-flex flex-column w-100 h-100 justify-content-center widget-block-live">
            <ng-container *ngTemplateOutlet="signupFormContent"></ng-container>
        </div>
    </ng-container>

    <ng-container *ngIf="websiteMode === WebsiteMode.Preview">
        <lib-design-blocks-action-menu
            [disableMoveUp]="disableMoveUp"
            [disableMoveDown]="disableMoveDown"
            (moveDownClicked)="MoveDownClicked()"
            (moveUpClicked)="MoveUpClicked()"
            (deleteClicked)="DeleteClicked()"
            [showEdit]="true"
            (editClicked)="EditClicked()">
            <ng-container *ngTemplateOutlet="blockContent"></ng-container>
        </lib-design-blocks-action-menu>
    </ng-container>
</div>

<ng-template #blockContent>
    <div #signupBlock class="d-flex flex-row w-100 justify-content-center py-5 widget-block">
        <div class="scss-spacing-widget-block align-items-center"
            [ngClass]="{
            'd-flex flex-sm-row flex-column scss-widget-block-height': kind !== WebsiteBlockSignupFormType.Default,
            'scss-spacing-widget-block-no-media background-base-0': kind !== WebsiteBlockSignupFormType.MediaLeft,
            'scss-spacing-widget-block-media': kind === WebsiteBlockSignupFormType.MediaLeft,
            'scss-spacing-widget-block-accent': kind === WebsiteBlockSignupFormType.AccentBackgroundDefault,
            'p-3 border shadow-sm': kind !== WebsiteBlockSignupFormType.MediaLeft,
            'scss-sizing-widget-block-tablet-desktop-two-columns': kind !== WebsiteBlockSignupFormType.MediaLeft && !isMobile,
            'mx-3 w-100': isMobile,
            'scss-spacing-widget-block-top-border': kind === WebsiteBlockSignupFormType.Default
            }">
            
            <div *ngIf="websiteMode !== WebsiteMode.Live"
                class="d-flex flex-column w-100 h-100 justify-content-center">
                <ng-container *ngTemplateOutlet="signupFormContent"></ng-container>
            </div>

            <ng-container *ngIf="websiteMode === WebsiteMode.Live">
                <iframe #iframe style="min-height: 474px; transition: height .1s" class="w-100" frameBorder="0" scrolling="no" [src]="data.Url! | trustUrl" (load)="Load(iframe)"></iframe>
            </ng-container>
        </div>
    </div>
</ng-template>

<ng-template #signupFormContent>

    <h1 *ngIf="websiteMode === WebsiteMode.Preview"
        [ngClass]="{'align-self-center': true}" class="mb-2 text-break">
        <ng-content select="[header]"></ng-content>
    </h1>

    <h1 *ngIf="websiteMode !== WebsiteMode.Preview && data.Header"
        [ngClass]="{'align-self-center': true}" class="mb-2 text-break">
        <span [innerHTML]="data.Header | trustHtml"></span>
    </h1>

    <lib-stepper #stepperRef
        [currentStep]="currentStep"
        [hasCardContainer]="false"
        (dblclick)="EditClicked()"
        *ngIf="!showThankYouMessage">
        <lib-step>
            <ng-container label>
                <p class="typography-caption typography-semibold text-truncate brand-500" i18n="@@SUFDesignBlockAddAStudentLabel">Add a #Student#</p>
            </ng-container>
            <ng-container content>
                <lib-signup-form-design-block-step1
                    [data]="data"
                    [renderContext]="renderContext"
                    [isViewOnly]="isViewOnly"
                    (nextClicked)="Step1NextClicked($event)">
                </lib-signup-form-design-block-step1>
            </ng-container>
        </lib-step>

        <lib-step>
            <ng-container label>
                <p class="typography-caption typography-semibold text-truncate brand-500" i18n="@@SUFDesignBlockFamilyContactLabel">
                    #Family# Contact
                </p>
            </ng-container>
            <ng-container content>
                <lib-signup-form-design-block-step2 #step2Component *ngIf="!showThankYouMessage && stepperRef.stepper?.selectedIndex === 1"
                    [data]="data"
                    [adultStudents]="adultStudents"
                    [parent]="parent"
                    [supportsStoredPaymentMethods]="(renderContext | method: SupportsStoredPaymentMethods)"
                    [enableSave]="enableSave"
                    (nextClicked)="Step2NextClicked($event)"
                    (backClicked)="Step2BackClicked($event)">
                    <!-- On Recaptcha Error, display message: Reload the page, or wait 2 minutes. -->
                    <lib-error
                        [control]="recaptchaErrorForm.controls.Captcha"
                        [errors]="captchaErrors">
                    </lib-error>

                    <lib-error
                        [control]="recaptchaNeededErrorForm.controls.CaptchaNeeded"
                        [errors]="captchaNeededErrors">
                    </lib-error>
                </lib-signup-form-design-block-step2>
                
                <p class="typography-body-small pt-2">
                    This site is protected by reCAPTCHA and the Google
                    <a href="https://policies.google.com/privacy">Privacy Policy</a> and
                    <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                </p>
            </ng-container>
        </lib-step>

        <lib-step *ngIf="data.CollectCreditCard && (renderContext | method: SupportsStoredPaymentMethods)">
            <ng-container label>
                <p class="typography-caption typography-semibold d-none d-sm-block text-truncate brand-500" i18n="@@SUFDesignBlockBillingDesktopLabel">Billing Information</p>
                <p class="typography-caption typography-semibold d-block d-sm-none text-truncate brand-500" i18n="@@SUFDesignBlockBillingMobileLabel">Billing </p>
            </ng-container>
            <ng-container content>
                <lib-signup-form-design-block-step3 #step3Component *ngIf="!showThankYouMessage && stepperRef.stepper?.selectedIndex === 2"
                    [data]="data"
                    [renderContext]="renderContext"
                    [parent]="parent"
                    [numStudents]="students.length"
                    [stripeNextStepClientSecret]="stripeNextStepClientSecret"
                    [paymentProcessorErrorMessage]="paymentProcessorErrorMessage"
                    [enableSave]="enableSave"
                    (submitClicked)="Step3SubmitClicked($event)"
                    (backClicked)="Step3BackClicked()"
                    (stripeNextStepComplete)="StripeNextStepComplete($event)">
                    <!-- On Recaptcha Error, display message: Reload the page, or wait 2 minutes. -->
                    <lib-error
                        [control]="recaptchaErrorForm.controls.Captcha"
                        [errors]="captchaErrors">
                    </lib-error>

                    <lib-error
                        [control]="recaptchaNeededErrorForm.controls.CaptchaNeeded"
                        [errors]="captchaNeededErrors">
                    </lib-error>
                </lib-signup-form-design-block-step3>
                
                <p class="typography-body-small pt-2">
                    This site is protected by reCAPTCHA and the Google
                    <a href="https://policies.google.com/privacy">Privacy Policy</a> and
                    <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                </p>
            </ng-container>
        </lib-step>
    </lib-stepper>

    <!-- TODO: according to docs, we _should_ be able to use a formcontrol here but it doesnt seem to work with the invisible captcha, should spend more time -->
    <re-captcha *ngIf="websiteMode === WebsiteMode.Live"
        size="invisible"
        errorMode="handled"
        (resolved)="HandleCAPTCHA($event)" 
        (errored)="HandleCAPTCHAError($event)">
    </re-captcha>

    <lib-widget-thank-you #thankyouComponent *ngIf="showThankYouMessage" [thankYouMessage]="data.ThankYouMessage"></lib-widget-thank-you>
</ng-template>