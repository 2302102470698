import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConvertToHtmlPipe } from './conversion/convert-to-html.pipe';
import { FormatFilenamePipe } from './file/format-filename.pipe';
import { FormatFilesizePipe } from './file/format-filesize.pipe';
import { FormatDaysPipe } from './format-duration/format-days.pipe';
import { FormatHoursPipe } from './format-duration/format-hours.pipe';
import { FormatMinutesPipe } from './format-duration/format-minutes.pipe';
import { FormatSecondsPipe } from './format-duration/format-seconds.pipe';
import { LazyLoadComponent } from './lazy-load/lazy-load.component';
import { TrustHtmlPipe } from './sanitization/trust-html.pipe';
import { TrustUrlPipe } from './sanitization/trust-url.pipe';
import { RunScriptsDirective } from './sanitization/run-scripts.directive';
import { ImageResizeDirective } from './resize/resize-image.directive';
import { ConvertBRToNewLinePipe } from './conversion/convert-br-to-newline.pipe';
import { ClickOutsideDirective } from './click-outside/click-outside.directive';


@NgModule({
    declarations: [
        TrustHtmlPipe,
        TrustUrlPipe,
        LazyLoadComponent,
        FormatSecondsPipe,
        FormatMinutesPipe,
        FormatHoursPipe,
        FormatDaysPipe,
        FormatFilenamePipe,
        FormatFilesizePipe,
        ConvertToHtmlPipe,
        ConvertBRToNewLinePipe,
        RunScriptsDirective,
        ImageResizeDirective,
        ClickOutsideDirective
    ],
    imports: [
        CommonModule
    ],
    exports: [
        TrustHtmlPipe,
        TrustUrlPipe,
        LazyLoadComponent,
        FormatSecondsPipe,
        FormatMinutesPipe,
        FormatHoursPipe,
        FormatDaysPipe,
        FormatFilenamePipe,
        FormatFilesizePipe,
        ConvertToHtmlPipe,
        ConvertBRToNewLinePipe,
        RunScriptsDirective,
        ImageResizeDirective,
        ClickOutsideDirective

    ]
})
export class UtilityModule { }
