import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ApiBaseRoutes } from '@library/api';
import { ButtonSize, CancellableObservable, DesignBlockImageBaseViewComponent, DialogEvents, Environment, Errors, FormBase, Required, SimpleDialogData, string } from '@library/base';
import { ContactFormInputItem, ErrorCode, VerifyEmailInputItem, WebsiteBlockContactFormDisplayItem, WebsiteBlockContactFormType, WebsiteRenderContextDisplayItem, WebsiteRenderMode } from '@library/data-models';
import { DialogService } from '@library/dialog';
import { SBDateTime } from '@library/localization';
import { WidgetManualDeviceTypeManager } from '@library/widget';
import { IFrameComponent, iframeResizer } from 'iframe-resizer';
import { RecaptchaComponent, RecaptchaLoaderService } from 'ng-recaptcha';
import { forkJoin } from 'rxjs';
import { ContactFormCustomFieldsComponent } from './custom-fields/contact-form-custom-fields.component';

class ContactFormForm extends FormBase {
}

class RecaptchaErrorForm extends FormBase {
    Captcha = Required(string);
}

class RecaptchaNeededErrorForm extends FormBase {
    CaptchaNeeded = Required(string);
}

@Component({
    selector: 'lib-contact-form-design-block',
    templateUrl: './contact-form-design-block.component.html',
    styleUrls: ['./contact-form-design-block.component.scss']
})
export class ContactFormDesignBlockComponent extends DesignBlockImageBaseViewComponent implements OnInit, OnDestroy {
    @ViewChild(RecaptchaComponent) recaptchaRef!: RecaptchaComponent; 
    @ViewChild(ContactFormCustomFieldsComponent, {static: false}) customFields!: ContactFormCustomFieldsComponent;  

    @Input()
    set data(value: WebsiteBlockContactFormDisplayItem) {
        if (value !== undefined) {
            this._data = value;
        }
    }
    @Input() renderContext: WebsiteRenderContextDisplayItem = new WebsiteRenderContextDisplayItem();
    @Input() kind! : WebsiteBlockContactFormType;
    @Input() hasHeader! : boolean;
    @Input() hasBody! : boolean;
    @Input() isViewOnly: boolean = false;    
    @Input() disableImageDetails: boolean = false;

    
    readonly recaptchaErrorForm = RecaptchaErrorForm.Create(false);
    readonly recaptchaNeededErrorForm = RecaptchaNeededErrorForm.Create(false);
    readonly form = ContactFormForm.Create(false);

    protected captchaErrors: Errors = new Errors();
    protected captchaNeededErrors: Errors = new Errors();
    private _data!: WebsiteBlockContactFormDisplayItem;

    private _sendingMessage = false;
    private _showThankYouMessage = false;

    private _iframe: IFrameComponent | null = null;

    private _resolvedRecaptchaToken: string | null = null;
    private _dateRecaptchaToken: SBDateTime | null = null;
    
    reCAPTCHAReady = false;
    recaptchaneededError = false;

    constructor(private _DialogService: DialogService,
        private _Environment: Environment,
        private _RecaptchaLoaderService: RecaptchaLoaderService) {
        super();

        this.captchaErrors.Override({
            api: _ => $localize`:@@CommonRecaptchaErrorMessage:Could not connect to the reCAPTCHA service. Please check your internet connection and reload.`
        });

        this.captchaNeededErrors.Override({
            api: _ => $localize`:@@CommonRecaptchaVerificationSubmitButtonErrorMessage:An error occurred during reCAPTCHA verification. Please try again by clicking the "Submit" button.`
        });
    }

    override ngOnInit() {
        super.ngOnInit();

        this.form.isViewOnly = this.isViewOnly;

        if (this.renderContext.RenderMode == WebsiteRenderMode.Widget) {
            window['iFrameResizer' as any] = {
                onReady: () => {
                    // Set callback for updates on full window size
                    (window as any).parentIFrame?.sendMessage('ready');
                    (window as any).parentIFrame?.getPageInfo((obj: any) => {
                        this.UpdateBreakpoint(obj.clientWidth, obj.clientHeight);
                    });
                }
            } as any;

            this._subscriptions.push(this.DeviceTypeManager.breakPointChange.subscribe(_ => {
                this.DetectChanges();
            }));
        }
        this._RecaptchaLoaderService.ready.subscribe(_ => this.reCAPTCHAReady = true);
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();

        if (this._iframe && this._iframe.iFrameResizer) {
            this._iframe.iFrameResizer.close();
        }
    }

    Load(iframe: HTMLIFrameElement) {
        if (!this._iframe) {
            const components = iframeResizer({
                log: false,
                heightCalculationMethod: 'taggedElement',
                checkOrigin: false,
                onMessage: (signal: any) => {
                    if (signal.message === 'ready') {
                        iframe.style.minHeight = ''
                    } else if (signal.message === 'reload') {
                        window.location.reload();
                    }
                }
            } as any, iframe);

            this._iframe = components && components.length > 0 ? components[0] : null;
        }
    }

    private UpdateBreakpoint(width: number, height: number) {
        (this.DeviceTypeManager as WidgetManualDeviceTypeManager).SetPageSize(width, height);
    }

    //==== recaptcha
    SendMessageButton(): void {
        if(!this.renderContext.ContactFormRenderContext?.CaptchaRequired && !this.recaptchaneededError) {
            this._resolvedRecaptchaToken = null;
            this.SendMessage();
        } else if (this._resolvedRecaptchaToken && this._dateRecaptchaToken!.Clone().AddMinutes(2).totalMilliseconds > SBDateTime.Now().totalMilliseconds) {
            this.SendMessage();
        } else {
            this.recaptchaRef.execute();
        }   
    }

    HandleCAPTCHA(captchaResponse: string | null) {
        //On reCAPTCHA reset, it emits a null response! we need to ignore that
        if(captchaResponse === null) {
            return;
        }
        
        this._resolvedRecaptchaToken = captchaResponse;
        this._dateRecaptchaToken = SBDateTime.Now();
        this.SendMessage();
    }

    HandleCAPTCHAError(error: any) {
        this.recaptchaRef.reset();
        this.recaptchaErrorForm.controls.Captcha.setValue("needed");
        this.recaptchaErrorForm.controls.Captcha.markAsTouched();
        this.recaptchaErrorForm.controls.Captcha.markAsDirty();
        this.recaptchaErrorForm.markAllAsTouched();
        this.recaptchaErrorForm.CheckValidity();
        this.recaptchaErrorForm.enable();        
        this.recaptchaErrorForm.controls.Captcha.SetApiError();
    }

    SendMessage(): void {
        if(this.form.CheckValidity()) {
            this._sendingMessage = true;

            const emailControlsToVerify = this.customFields.GetEmailControls().filter(c => c.value?.trim() );

            if(emailControlsToVerify.length > 0){
                this.VerifyEmailsAndSubmit(emailControlsToVerify);
            } else {
                this.Submit()
            }
        }
    }

    private VerifyEmailsAndSubmit(emailControlsToVerify: FormControl[]): void {
        const calls: CancellableObservable<boolean>[] = [];

        for(let control of emailControlsToVerify){
            calls.push(ApiBaseRoutes.Email.VerifyEmail.Call({
                Body: new VerifyEmailInputItem({
                    EmailAddress: control.value
                })
            }))
        }

        forkJoin(calls).subscribe( (results: boolean[]) => {

            const isAllVerifiedEmails = results.every( result => result );

            if(isAllVerifiedEmails){
                this.Submit();
            } else {
                this.form.enable();
                this._sendingMessage = false;
                for(let i = 0; i < results.length; i++ ) {
                    if(!results[i]){
                        emailControlsToVerify[i].SetApiError();
                    }
                }
            }
        });
    }

    private Submit(): void {
        const inputItem = new ContactFormInputItem({
            SchoolID: this.data.SchoolID,
            WebsiteBlockID: this.data.ID,
            Captcha: this._resolvedRecaptchaToken
        });

        inputItem.CustomFieldValues = this.customFields.GetCustomFieldValues();

        //call API to submit form
        ApiBaseRoutes.DesignBlocks.Contact.Call({
            Body: inputItem
        }).subscribe({
            next: _ => {
                this._showThankYouMessage = true;
            },
            error: (error: typeof ApiBaseRoutes.DesignBlocks.Signup.Error) => {
                if (error.Status == 429) {
                    this.OpenDialog429ApiError();
                } else if (error.Response.NewErrorCode === ErrorCode.Captcha) {
                    this.recaptchaRef.reset();
                    this.recaptchaNeededErrorForm.controls.CaptchaNeeded.setValue("needed");
                    this.recaptchaNeededErrorForm.controls.CaptchaNeeded.markAsTouched();
                    this.recaptchaNeededErrorForm.controls.CaptchaNeeded.markAsDirty();
                    this.recaptchaNeededErrorForm.markAllAsTouched();
                    this.recaptchaNeededErrorForm.CheckValidity();
                    this.recaptchaNeededErrorForm.enable();        
                    this.recaptchaNeededErrorForm.controls.CaptchaNeeded.SetApiError();
                    this.recaptchaneededError = true;
                    this.form.enable();
                    this._sendingMessage = false;
                } else {
                    throw error;
                }
            }
        });
    }

    private OpenDialog429ApiError(): void {
        this._DialogService.CreateInfoDialog(new SimpleDialogData ({
            Title: $localize`:@@ContactFormDesignBlockTooManySubmissionsTitle:Too Many Submissions`,
            Text: $localize`:@@ContactFormDesignBlockTooManySubmissionsText:Oops! It seems like we're experiencing a lot of submissions from your location right now. Please try again in a moment or contact ${this._Environment.Help!.SupportEmail} for assistance.`
        }), false).events.subscribe(event => {
            if (event === DialogEvents.PrimaryAction) {
                this._sendingMessage = false;
            }
        });
    }

    get sendingMessage(): boolean {
        return this._sendingMessage;
    }

    get WebsiteBlockContactFormType(): typeof WebsiteBlockContactFormType {
        return WebsiteBlockContactFormType;
    }

    GetButtonSize(isMobile: boolean): ButtonSize {
        return isMobile ? this.ButtonSize.FullWidth : this.ButtonSize.Medium;
    }

    get WebsiteRenderMode(): typeof WebsiteRenderMode {
        return WebsiteRenderMode;
    }

    get data(): WebsiteBlockContactFormDisplayItem {
        return this._data;
    }

    get showThankYouMessage() {
        return this._showThankYouMessage;
    }
}
