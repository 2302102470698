import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Environment } from '@library/base';
import { AuthenticationInterceptor } from './authentication/authentication-interceptor';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule
    ],
    providers: [
        CookieService,
        { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true } 
    ]
})
export class ApiBaseModule {
    // This allows us to set the environment used in a library module from the app
    public static setEnvironment(environment: Environment): ModuleWithProviders<ApiBaseModule> {
        return {
            ngModule: ApiBaseModule,
            providers: [{ provide: Environment, useValue: environment }]
        };
    }
}
