import { ErrorHandler, Injectable, NgZone } from "@angular/core";
import { ApiRequestManager } from "@library/api";
import { ApiError, DialogEvents, SimpleDialogData } from "@library/base";
import { DialogService } from "@library/dialog";

@Injectable()
export class WidgetErrorManager implements ErrorHandler {
    constructor(
        private _NgZone: NgZone,
        private _DialogService: DialogService,
    ) {}

    handleError(error: any) {
        if (error instanceof ApiError) {
            if (!ApiRequestManager.disabled) {
                ApiRequestManager.Disable();

                console.error(error);

                this._NgZone.run(() => 
                    this._DialogService.CreateInfoDialog(new SimpleDialogData({
                        Title: $localize`:@@CommonAPIUnexpectedDialogTitle:Sorry, something unexpected occurred.`,
                        Text: $localize`:@@CommonAPIUnexpectedDialogText:Please refresh the page and try again, or contact support if the problem persists.`,
                        PrimaryButtonText: $localize`:@@CommonRefreshLabel:Refresh`,
                    }), false).events.subscribe(event => {
                        if (event === DialogEvents.PrimaryAction) {
                            window.location.reload();
                        }
                    })
                );
            }
        } else {
            console.error(error);
        }
    }
}