<div class="w-100" [ngClass]="{'border rounded-1 p-2': hasBorder}">
    <iframe #iframe
        class="w-100"
        style="transition: height .1s"
        sandbox="allow-forms allow-scripts allow-top-navigation allow-same-origin allow-popups allow-popups-to-escape-sandbox allow-presentation"
        scrolling="no"
        [attr.data-sb-qa]="qaTag"
        (load)="Load(iframe)">
    </iframe>
</div>
