<div class="v3">
    <mat-expansion-panel #panel [hideToggle]="true" class="v3 p-0 p-lg-2 mb-3">
        <mat-expansion-panel-header
            [expandedHeight]="'auto'"
            [collapsedHeight]="'auto'">
            <div class="w-100 p-3" (click)="TogglePanel(panel);$event.stopPropagation()" >
                <lib-button
                    style="height: 0"
                    class="w-100 d-flex justify-content-end"
                    [kind]="ButtonKind.Link"
                    [suffixIcon]="panel.expanded ? Icon.Common.ExpandLess : Icon.Common.ExpandMore"
                    [suffixIconSize]="IconSize.ExtraSmall"
                    (clicked)="TogglePanel(panel)">
                </lib-button>
                <div class="d-flex align-items-baseline scss-info-card-header">
                  <ng-content select="[header]">
                  </ng-content>
                  <div *ngIf="optional">
                    <caption class="ms-2" i18n="@@CommonOptional">Optional</caption>
                  </div>
                </div>
                <ng-content select="[subheader]"></ng-content>
            </div>
        </mat-expansion-panel-header>
        <div class="w-100 px-3 pb-3">
            <ng-content select="[body]"></ng-content>
        </div>
    </mat-expansion-panel>
</div>
