import { AfterViewInit, Component, DoCheck, ElementRef, EventEmitter, Input, KeyValueDiffer, KeyValueDiffers, OnDestroy, Output, ViewChild } from '@angular/core';
import { ApiBaseRoutes } from '@library/api';
import { DesignBlockBaseViewComponent, DialogEvents, Environment, Errors, FormBase, Required, SimpleDialogData, string } from '@library/base';
import { ErrorCode, ParentSignupInputItem, PaymentSourceInputItem, PaymentSourceRegistrationNextAction, SignupInputItem, StripePaymentSourceInputItem, WebsiteBlockSignupFormDisplayItem, WebsiteBlockSignupFormRenderContextDisplayItem, WebsiteBlockSignupFormType, WebsiteRenderContextDisplayItem, WebsiteRenderMode } from '@library/data-models';
import { DialogService } from '@library/dialog';
import { WidgetManualDeviceTypeManager } from '@library/widget';
import { IFrameComponent, iframeResizer } from 'iframe-resizer';
import { ParentSignupFrontendInputItem, StudentSignupFrontendInputItem } from './signup-form.definitions';
import { WidgetThankYouComponent } from '../widget-thank-you/widget-thank-you.component';
import { RecaptchaComponent, RecaptchaLoaderService } from 'ng-recaptcha';
import { SBDateTime } from '@library/localization';

class RecaptchaErrorForm extends FormBase {
    Captcha = Required(string);
}

class RecaptchaNeededErrorForm extends FormBase {
    CaptchaNeeded = Required(string);
}

enum CaptchaSubmitStep {
    Step2 = 'Step2',
    Step3 = 'Step3',
    SCA = 'SCA'
}

@Component({
    selector: 'lib-signup-form-design-block',
    templateUrl: './signup-form-design-block.component.html',
    styleUrls: ['./signup-form-design-block.component.scss']
})
export class SignupFormDesignBlockComponent extends DesignBlockBaseViewComponent implements OnDestroy {
    @ViewChild(RecaptchaComponent) recaptchaRef!: RecaptchaComponent; 
    readonly recaptchaErrorForm = RecaptchaErrorForm.Create();
    protected captchaErrors: Errors = new Errors();

    readonly recaptchaNeededErrorForm = RecaptchaNeededErrorForm.Create();
    protected captchaNeededErrors: Errors = new Errors();
    
    @Input()
    set data(value: WebsiteBlockSignupFormDisplayItem) {
        if (value !== undefined) {
            this._data = value;
        }
    }
    @Input() renderContext: WebsiteRenderContextDisplayItem = new WebsiteRenderContextDisplayItem({SignupFormRenderContext: new WebsiteBlockSignupFormRenderContextDisplayItem()});
    @Input() isViewOnly: boolean = false;    
    @Input() kind : WebsiteBlockSignupFormType = WebsiteBlockSignupFormType.Default;
    @Output() submitSignupClicked: EventEmitter<SignupInputItem> = new EventEmitter();
    private _data!: WebsiteBlockSignupFormDisplayItem;
    private _students: StudentSignupFrontendInputItem[] = [];
    private _adultStudents: StudentSignupFrontendInputItem[] = [];
    private _parent!: ParentSignupFrontendInputItem;
    private _showThankYouMessage: boolean = false;
    private _signupInputItem: SignupInputItem | null = null;
    private _stripeNextStepClientSecret: string | null = null;
    private _paymentProcessorErrorMessage: string | null = null;
    private _enableSave: boolean | null = null;

    private _currentStep: number = 0;

    private _iframe: IFrameComponent | null = null;

    private _signupBlock: ElementRef | null = null; 

    recaptchaneededError = false;

    private _resolvedRecaptchaToken: string | null = null;
    private _dateRecaptchaToken: SBDateTime | null = null;
    private _captchaSubmitStep!: CaptchaSubmitStep;

    @ViewChild("signupBlock", {read: ElementRef, static: false})
    set signupBlock(content: ElementRef) {
        if (content) {
            this._signupBlock = content; 
            this.DetectChanges();
        }
    } 


    constructor(private _DialogService: DialogService,
        private _Environment: Environment,
        private _RecaptchaLoaderService: RecaptchaLoaderService) {
        super();

        this.captchaErrors.Override({
            api: _ => $localize`:@@CommonRecaptchaErrorMessage:Could not connect to the reCAPTCHA service. Please check your internet connection and reload.`
        });

        this.captchaNeededErrors.Override({
            api: _ => $localize`:@@CommonRecaptchaVerificationSubmitButtonErrorMessage:An error occurred during reCAPTCHA verification. Please try again by clicking the "Submit" button.`
        });
    }

    override ngOnInit() {
        super.ngOnInit();

        if (this.renderContext.RenderMode == WebsiteRenderMode.Widget) {
            window['iFrameResizer' as any] = {
                onReady: () => {
                    // Set callback for updates on full window size
                    (window as any).parentIFrame?.sendMessage('ready');
                    (window as any).parentIFrame?.getPageInfo((obj: any) => {
                        this.UpdateBreakpoint(obj.clientWidth, obj.clientHeight);
                    });
                }
            } as any;

            this._subscriptions.push(this.DeviceTypeManager.breakPointChange.subscribe(_ => {
                this.DetectChanges();
            }));
        }
        this._RecaptchaLoaderService.ready.subscribe(_ => this.reCAPTCHAReady = true);
    }
    reCAPTCHAReady = false;

    override ngOnDestroy(): void {
        super.ngOnDestroy();

        if (this._iframe && this._iframe.iFrameResizer) {
            this._iframe.iFrameResizer.close();
        }
    }

    Load(iframe: HTMLIFrameElement) {
        if (!this._iframe) {
            const components = iframeResizer({
                log: false,
                heightCalculationMethod: 'taggedElement',
                checkOrigin: false,
                onMessage: (signal: any) => {
                    if (signal.message === 'ready') {
                        iframe.style.minHeight = ''
                    } else if (signal.message === 'reload') {
                        window.location.reload();
                    }
                }
            } as any, iframe);

            this._iframe = components && components.length > 0 ? components[0] : null;
        }
    
    }

    private UpdateBreakpoint(width: number, height: number) {
        (this.DeviceTypeManager as WidgetManualDeviceTypeManager).SetPageSize(width, height);
    }

    Step1NextClicked(studentInputItems: StudentSignupFrontendInputItem[]): void {
        this._students = studentInputItems;
        this._adultStudents = this.students.filter(student => student.IsAdult);
        this._currentStep++;
        this.ScrollIntoView();
    }

    Step2NextClicked(parentInputItem: ParentSignupFrontendInputItem): void {
        this._parent = parentInputItem;

        if(this.data.CollectCreditCard && this.SupportsStoredPaymentMethods()){
            this._currentStep++;
        } else {
            this.GetCaptcha(CaptchaSubmitStep.Step2);
        }

        this.ScrollIntoView();
    }

    private Step2Submit(): void {
        this._signupInputItem = new SignupInputItem({
            SchoolID: this.data.SchoolID,
            WebsiteBlockID: this.data.ID,
            Parent: new ParentSignupInputItem({
                ...this._parent
            }),
            Students: this._students,
            CustomFieldValues: this._parent.CustomFieldValues,
            Captcha: this._resolvedRecaptchaToken
        });

        //call API to submit form
        ApiBaseRoutes.DesignBlocks.Signup.Call({
            Body: this._signupInputItem
        }).subscribe({
            next: _ => this._showThankYouMessage = true,
            error: (error: typeof ApiBaseRoutes.DesignBlocks.Signup.Error) => {
                if (error.Status == 429) {
                    this.OpenDialog429ApiError();
                } else if (error.Response.NewErrorCode === ErrorCode.Captcha) {
                    this.recaptchaRef.reset();
                    this.recaptchaNeededErrorForm.controls.CaptchaNeeded.setValue("needed");
                    this.recaptchaNeededErrorForm.controls.CaptchaNeeded.markAsTouched();
                    this.recaptchaNeededErrorForm.controls.CaptchaNeeded.markAsDirty();
                    this.recaptchaNeededErrorForm.markAllAsTouched();
                    this.recaptchaNeededErrorForm.CheckValidity();
                    this.recaptchaNeededErrorForm.enable();        
                    this.recaptchaNeededErrorForm.controls.CaptchaNeeded.SetApiError();
                    this.recaptchaneededError = true;
                    this._enableSave = true;
                } else {
                    throw error;
                }
            }
        })
    }

    Step2BackClicked(parentInputItem: ParentSignupFrontendInputItem): void {
        this._parent = parentInputItem
        this._currentStep--;
    }

    Step3SubmitClicked(paymentSource: PaymentSourceInputItem | null): void {
        this._paymentProcessorErrorMessage = null;

        if (paymentSource !== null) {
            this._signupInputItem = new SignupInputItem({
                SchoolID: this.data.SchoolID,
                WebsiteBlockID: this.data.ID,
                Parent: new ParentSignupInputItem({
                    ...this._parent
                }),
                Students: this._students,
                CustomFieldValues: this._parent.CustomFieldValues,
                PaymentSource: paymentSource,
                Captcha: this._resolvedRecaptchaToken
            });

            this.GetCaptcha(CaptchaSubmitStep.Step3);
        } else {
            //SCA failure
            this.recaptchaRef.reset();
            this.ResetCaptchaToken();
        }
        this.ScrollIntoView();
    }

    Step3BackClicked(): void {
        this._currentStep--;
    }

    //In TP WB, we do not set the renderContext at all for the block level
    SupportsStoredPaymentMethods(): boolean {
        return this.renderContext && (this.renderContext.SignupFormRenderContext!.PaymentProcessor !== null && this.renderContext.SignupFormRenderContext!.PaymentProcessor.StoredPaymentMethodsSupported);
    }

    //After SCA verification, we need to resubmit, but if captcha was needed, we need a new token!
    StripeNextStepComplete(intentID: string): void {
        (this._signupInputItem!.PaymentSource as StripePaymentSourceInputItem).IntentID = intentID;
        this.recaptchaRef.reset();
        this.ResetCaptchaToken();
        this.GetCaptcha(CaptchaSubmitStep.SCA);
    }

    private Step3Submit() {
        //when original input item was made, we didnt have token yet, so we need to
        //now add it if it is available, or null otherwise.
        this._signupInputItem!.Captcha = this._resolvedRecaptchaToken;

        ApiBaseRoutes.DesignBlocks.Signup.Call({
            Body: this._signupInputItem!
        }).subscribe({
            next: result => {
                switch (result.NextAction) {
                    case PaymentSourceRegistrationNextAction.None:
                        this._showThankYouMessage = true;
                        this.ScrollIntoView();
                        break;
                    case PaymentSourceRegistrationNextAction.UseStripeSdk:
                        this._stripeNextStepClientSecret = result.StripeClientSecret;
                        break;
                }
            },
            error: (error: typeof ApiBaseRoutes.DesignBlocks.Signup.Error) => {
                if (error.Status == 429) {
                    this.OpenDialog429ApiError();
                } else if (error.Response.NewErrorCode === ErrorCode.OnlinePaymentError) {
                    this._paymentProcessorErrorMessage = error.Response.ErrorMessage;
                    this.ResetCaptchaToken();
                    this.recaptchaRef.reset();
                } else if (error.Response.NewErrorCode === ErrorCode.Captcha) {
                    this.recaptchaRef.reset();
                    this.recaptchaNeededErrorForm.controls.CaptchaNeeded.setValue("needed");
                    this.recaptchaNeededErrorForm.controls.CaptchaNeeded.markAsTouched();
                    this.recaptchaNeededErrorForm.controls.CaptchaNeeded.markAsDirty();
                    this.recaptchaNeededErrorForm.markAllAsTouched();
                    this.recaptchaNeededErrorForm.CheckValidity();
                    this.recaptchaNeededErrorForm.enable();        
                    this.recaptchaNeededErrorForm.controls.CaptchaNeeded.SetApiError();
                    this.recaptchaneededError = true;
                    this._enableSave = true;
                } else {
                    throw error;
                }
            }
        });
    }

    private OpenDialog429ApiError(): void {
        this._enableSave = null;
        this._DialogService.CreateInfoDialog(new SimpleDialogData ({
            Title: $localize`:@@SUFDesignBlockTooManySubmissionsTitle:Too Many Submissions`,
            Text: $localize`:@@SUFDesignBlockTooManySubmissionsText:Oops! It seems like we're experiencing a lot of submissions from your location right now. Please try again in a moment or contact ${this._Environment.Help!.SupportEmail} for assistance.`,
        }), false).events.subscribe((event: DialogEvents) => {
            if (event === DialogEvents.PrimaryAction) {
                this._enableSave = true;
            }
        });
    }

    //==== recaptcha
    GetCaptcha(submitStep: CaptchaSubmitStep): void {        
        this._captchaSubmitStep = submitStep;

        if(!this.renderContext.SignupFormRenderContext?.CaptchaRequired && !this.recaptchaneededError) {
            this.ResetCaptchaToken();
            this.CaptchaCallStepSpecificSubmit();
        } else if (this._resolvedRecaptchaToken && this._dateRecaptchaToken!.Clone().AddMinutes(2).totalMilliseconds > SBDateTime.Now().totalMilliseconds) {
            this.CaptchaCallStepSpecificSubmit();
        } else {
            this.recaptchaRef.execute();
        }
    }

    ResetCaptchaToken(): void {
        this._resolvedRecaptchaToken = null;
        this._dateRecaptchaToken = null;
    }
    

    HandleCAPTCHA(captchaResponse: string | null) {
        //On reCAPTCHA reset, it emits a null response! we need to ignore that
        if(captchaResponse === null) {
            return;
        }

        this._resolvedRecaptchaToken = captchaResponse;
        this._dateRecaptchaToken = SBDateTime.Now();
        this.CaptchaCallStepSpecificSubmit();
    }

    CaptchaCallStepSpecificSubmit(): void {
        switch(this._captchaSubmitStep) {
            case CaptchaSubmitStep.Step2:
                this.Step2Submit();
                break;
            case CaptchaSubmitStep.Step3:
                this.Step3Submit();
                break;
            case CaptchaSubmitStep.SCA:
                this.Step3Submit();
                break;
        }
    }

    HandleCAPTCHAError(error: any) {
        this.recaptchaRef.reset();
        this.recaptchaErrorForm.controls.Captcha.setValue("needed");
        this.recaptchaErrorForm.controls.Captcha.markAsTouched();
        this.recaptchaErrorForm.controls.Captcha.markAsDirty();
        this.recaptchaErrorForm.markAllAsTouched();
        this.recaptchaErrorForm.CheckValidity();
        this.recaptchaErrorForm.enable();        
        this.recaptchaErrorForm.controls.Captcha.SetApiError();
    }
    //==== recaptcha end

    private ScrollIntoView(scrollOptions: ScrollIntoViewOptions = { behavior: "smooth", block: "nearest" }) {
        requestAnimationFrame(() => this._signupBlock?.nativeElement.scrollIntoView(scrollOptions));
    }

    get students(): StudentSignupFrontendInputItem[] {
        return this._students;
    }

    get adultStudents(): StudentSignupFrontendInputItem[] {
        return this._adultStudents;
    }

    get showThankYouMessage(): boolean {
        return this._showThankYouMessage;
    }

    get currentStep(): number {
        return this._currentStep;
    }

    get parent(): ParentSignupFrontendInputItem {
        return this._parent;
    }

    get stripeNextStepClientSecret(): string | null {
        return this._stripeNextStepClientSecret;
    }

    get paymentProcessorErrorMessage(): string | null {
        return this._paymentProcessorErrorMessage;
    }

    public get enableSave(): boolean | null {
        return this._enableSave;
    }

    get WebsiteBlockSignupFormType(): typeof WebsiteBlockSignupFormType {
        return WebsiteBlockSignupFormType;
    }
    
    get data(): WebsiteBlockSignupFormDisplayItem {
        return this._data;
    }

    get WebsiteRenderMode(): typeof WebsiteRenderMode {
        return WebsiteRenderMode;
    }

}
