import { Pipe, PipeTransform } from "@angular/core";
import { DeviceTypeManager, WebsiteBlockTypeData } from "@library/base";
import { WebsiteBlockContactFormType, WebsiteBlockLoginFormType, WebsiteBlockTextAndMediaType, WebsiteBlockType } from "@library/data-models";

@Pipe({name: 'scaleImageUrl'})
export class ScaleImageUrlPipe implements PipeTransform {

    constructor(private _DeviceTypeManager: DeviceTypeManager) {
    }

    transform(url: string, type: WebsiteBlockTypeData ): string {

        let scaledImageURL: string  = url;

        if(type.BlockType === WebsiteBlockType.TextAndMedia){
            switch (type.BlockSubtype) {
                case WebsiteBlockTextAndMediaType.MediaLeft:
                case WebsiteBlockTextAndMediaType.MediaRight:
                case WebsiteBlockTextAndMediaType.MediaLeftTwoColumn:
                case WebsiteBlockTextAndMediaType.MediaRightTwoColumn:
                case WebsiteBlockTextAndMediaType.MediaLeftShadow:
                case WebsiteBlockTextAndMediaType.MediaRightShadow:
                case WebsiteBlockTextAndMediaType.MediaLeftClipped:
                case WebsiteBlockTextAndMediaType.MediaRightClipped:
                    scaledImageURL = `${url}?Width=250&Height=250`;
                    break;
                case WebsiteBlockTextAndMediaType.Testimonial:
                case WebsiteBlockTextAndMediaType.AccentBackgroundTestimonial:
                case WebsiteBlockTextAndMediaType.MediaRightTestimonial:
                case WebsiteBlockTextAndMediaType.MediaRightAccentBackgroundTestimonial:
                    scaledImageURL = `${url}?Width=170&Height=170`;
                    break;
                case WebsiteBlockTextAndMediaType.HeroBackgroundImage:
                    if(this._DeviceTypeManager.isMobile){
                        scaledImageURL = `${url}?Width=600&Height=375`;
                    } else if(this._DeviceTypeManager.isTablet){
                        scaledImageURL = `${url}?Width=1280&Height=560`;
                    } else {
                        scaledImageURL = `${url}?Width=1920&Height=840`;
                    }
                    break;
                case WebsiteBlockTextAndMediaType.HeroSquareSideImage:
                case WebsiteBlockTextAndMediaType.HeroClippedSideImage:
                case WebsiteBlockTextAndMediaType.MediaLeftHeroSquareImage:
                case WebsiteBlockTextAndMediaType.MediaLeftHeroClippedImage:
                    if(this._DeviceTypeManager.isMobile){
                        scaledImageURL = `${url}?Width=600&Height=450`;
                    } else if(this._DeviceTypeManager.isTablet){
                        scaledImageURL = `${url}?Width=1200&Height=900`;
                    } else {
                        scaledImageURL = `${url}?Width=960&Height=720`;
                    }
                    break;
                case WebsiteBlockTextAndMediaType.PhotoGalleryCarousel:
                case WebsiteBlockTextAndMediaType.PhotoGalleryCarouselOverlay:
                    if(this._DeviceTypeManager.isMobile){
                        scaledImageURL = `${url}?Width=540&Height=540`;
                    } else if(this._DeviceTypeManager.isTablet){
                        scaledImageURL = `${url}?Width=360&Height=360`;
                    } else {
                        scaledImageURL = `${url}?Width=450&Height=450`;
                    }
                    break;
                case WebsiteBlockTextAndMediaType.PhotoGalleryGrid:
                    if(this._DeviceTypeManager.isMobile){
                        scaledImageURL = `${url}?Width=280&Height=440`;
                    } else {
                        scaledImageURL = `${url}?Width=380&Height=590`;
                    }
                    break;
                case WebsiteBlockTextAndMediaType.GallerySquareColumn:
                case WebsiteBlockTextAndMediaType.GalleryCircleColumn:
                case WebsiteBlockTextAndMediaType.GallerySquareAccentColumn:
                case WebsiteBlockTextAndMediaType.GalleryCircleAccentColumn:
                case WebsiteBlockTextAndMediaType.GallerySquareCardColumn:
                case WebsiteBlockTextAndMediaType.GalleryCircleCardColumn:
                    scaledImageURL = `${url}?Width=120&Height=120`;
                    break;
            }
        } else if (type.BlockType === WebsiteBlockType.ContactForm) {
            switch(type.BlockSubtype){
                case WebsiteBlockContactFormType.MediaLeft:
                case WebsiteBlockContactFormType.MediaRight:
                    if(this._DeviceTypeManager.isMobile){
                        scaledImageURL = `${url}?Width=540&Height=540`;
                    } else if(this._DeviceTypeManager.isTablet) {
                        scaledImageURL = `${url}?Width=930&Height=930`;
                    } else {
                        scaledImageURL = `${url}?Width=490&Height=490`;
                    }
                    break;
            }
        } else if (type.BlockType === WebsiteBlockType.LoginForm) {
            switch (type.BlockSubtype) {
                case WebsiteBlockLoginFormType.MediaLeft:
                case WebsiteBlockLoginFormType.MediaRight:
                    if(this._DeviceTypeManager.isMobile){
                        scaledImageURL = `${url}?Width=380&Height=380`;
                    } else {
                        scaledImageURL = `${url}?Width=490&Height=490`;
                    }
                    break;
            }
        }
        return scaledImageURL;
    }

}
