<div *ngIf="showActionMenu" class="image-action-menu-general" (click)="$event.stopPropagation()">
    <div class="d-flex gap-2 px-2">
        <lib-button
            [kind]="ButtonKind.Tertiary"
            [prefixIcon]="Icon.Common.CloudUploadOutline"
            [prefixIconSize]="IconSize.ExtraSmall"
            [size]="ButtonSize.Large"
            [iconOnly]="true"
            (clicked)="ImageActionClicked(WebsiteGalleryItemAction.MediaGallery)">
        </lib-button>
        <lib-button *ngIf="!disableImageDetails"
            [kind]="ButtonKind.Tertiary"
            [prefixIcon]="Icon.Common.InfoOutline"
            [prefixIconSize]="IconSize.ExtraSmall"
            [size]="ButtonSize.Large"
            [iconOnly]="true"
            [disabled]="disableImageDetails"
            (clicked)="ImageActionClicked(WebsiteGalleryItemAction.MediaDetails)">
        </lib-button>
    </div>
</div>